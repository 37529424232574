import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyHR_HR = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>Obavijest o privatnosti tvrtke Command Alkon</h4>
      </div>
      <div id="overview_content">
        <p>
          <em>Posljednja izmjena: 26. rujna 2023.</em>
        </p>
        <h2>Pregled</h2>
        <p>
          Command Alkon Incorporated, uključujući njegove povezane tvrtke ("CAI" ili "mi"), međunarodna je komercijalna
          tvrtka organizacija koja tvrtkama pruža softver, usluge i web rješenja u gotovoj kombinaciji, agregatu i
          industriju tegljača rasutog tereta putem lokalnih metoda, softvera kao usluge ("SaaS") i drugih metoda
          temeljenih na webu. CAI poštuje privatnost korisnika svojih proizvoda i usluga te se zalaže za zaštitu njihove
          privatnosti i održavanje njihovog povjerenja. Ova obavijest o privatnosti opisuje naše postupke prikupljanja,
          korištenja, pohranjivanja, prijenos, otkrivanje i drugačija obrada osobnih podataka (drugi naziv Osobni
          Informacije) u vezi s vašim korištenjem CAI proizvoda i/ili usluga, web stranica, aplikacija i društvenih
          medija stranice, kao i vaše interakcije s CAI osobljem na sastancima i na CAI događajima, uključujući
          izvanmrežnu prodaju i marketinške aktivnosti. Pažljivo pročitajte ovu Obavijest o privatnosti kako biste
          razumjeli naša pravila i praksu u pogledu obrade osobnih podataka. Pristupom našoj web stranici i/ili
          korištenjem naših proizvoda ili usluga, vi suglasni s našim prikupljanjem, korištenjem i otkrivanjem osobnih
          podataka kako je opisano u ovoj Obavijesti o privatnosti. ako ti imate pitanje ili zabrinutost u vezi s
          privatnošću, kontaktirajte nas putem informacija "Kontaktirajte nas" na dnu ovoga Obavijest o privatnosti.
        </p>
        <h2></h2>
        <h2>Sadržaj</h2>
        <ul>
          <li>
            <a href="#summary_of_privacy_notice">Sažetak Obavijesti o privatnosti</a>
          </li>
          <li>
            <a href="#controller_processor">Upravljač/procesor</a>
          </li>
          <li>
            <a href="#to_whom_does_this_notice_apply">Na koga se ova obavijest odnosi</a>
          </li>
          <li>
            <a href="#how_we_collect_personal_data">Kako prikupljamo i koristimo osobne podatke</a>
          </li>
          <li>
            <a href="#automatically_collected_information">Automatski prikupljeni podaci</a>
          </li>
          <li>
            <a href="#how_we_use_the_information_we_collect">Kako koristimo podatke koje prikupljamo</a>
          </li>
          <li>
            <a href="#how_we_disclose_the_information_we_collect">Kako otkrivamo podatke koje prikupljamo</a>
          </li>
          <li>
            <a href="#third_party_services">Usluge trećih strana</a>
          </li>
          <li>
            <a href="#security">Sigurnost</a>
          </li>
          <li>
            <a href="#retention_of_data">Zadržavanje podataka</a>
          </li>
          <li>
            <a href="#your_rights_and_choices">Vaša prava i izbori</a>
          </li>
          <li>
            <a href="#international_data_transfers_and_the_data_privacy_framework">
              Međunarodni prijenosi podataka i okvir za zaštitu privatnosti podataka
            </a>
          </li>
          <li>
            <a href="#lawful_basis_for_processing">Pravna osnova za obradu</a>
          </li>
          <li>
            <a href="#children_policy">Privatnost djece</a>
          </li>
          <li>
            <a href="#changes_to_the_privacy_notice">Promjene Obavijesti o privatnosti</a>
          </li>
          <li>
            <a href="#contact_us">Kontaktirajte nas</a>
          </li>
        </ul>
      </div>
      <div id="summary_of_privacy_notice">
        <h1>Sažetak Obavijesti o privatnosti</h1>
        <p>
          <strong>Primjena</strong> &ndash; Naša Obavijest o privatnosti primjenjuje se na obradu dostavljenih osobnih
          podataka od strane: 1) posjetitelja i korisnika različitih CAI stranica, aplikacija, proizvoda i/ili usluga;
          2) polaznici CAI i industrijski događaji; 3) kupci i potencijalni kupci i njihovi predstavnici; 4)
          pretplatnici na naš obavijesti i/ili bilteni; 5) dobavljačima i poslovnim partnerima i njihovim zastupnicima;
          6) posjetitelji našeg uredi; i 7) bilo tko drugi tko daje osobne podatke CAI-ju u bilo koju drugu svrhu. Za
          više detalja pogledajte ispod.
        </p>
        <p>
          <strong>Vrste obrađenih podataka</strong> &ndash; CAI prikuplja osobne podatke koje nam dostavite. Na primjer,
          kada ako se prijavite za račun, možemo prikupljati podatke poput imena, adresa e-pošte, fizičke adrese,
          telefona brojevi, itd. Također možete dati osobne podatke kako biste, na primjer, saznali više o našim
          proizvodima ili se prijavili za naše obavijesti. Možemo obrađivati ​​sljedeće osobne podatke koje možemo
          (ovisno o okolnostima) prikupljati tijekom posjeta web stranici, marketinških interakcija, upotrebe aplikacije
          i upotrebe naših proizvoda i/ili usluga u tijek dogovora s Vama i/ili Vašim poslodavcem: Vaše ime, kontakt
          podaci (e-mail adresa, telefon broj itd.), podaci o poslu (poslodavac, titula, nadređeni itd.), određeni ID
          brojevi (vozačevi licenca, zaposlenik, itd.), podaci o radnom učinku i potvrde, podaci o plaćanju, IP adresa,
          geolokacija, potpis, slika kamere, korisničko ime i lozinka, informacije o sindikatu, etnička pripadnost,
          nešto vezano uz posao medicinski podaci, poruke i podaci o ponašanju te informacije o vama koje ste primili
          putem smještaja kolačiće i piksele za praćenje tijekom korištenja platforme. Za više detalja pogledajte dolje.
        </p>
        <p>
          <strong>Svrha obrade</strong> &ndash; Vaše osobne podatke obrađujemo u sljedeće svrhe: 1) izvršavanje ugovora
          s vama i/ili vašim poslodavcem; 2) praćenje, razvoj, podrška i administracija aplikacija, web stranica i
          platformi; 3) sigurnost i sprječavanje prijevara; 4) naše marketinške svrhe (ne prodajemo vaše osobne
          podatke); 5) našu poslovnu administraciju; i 6) povezana analiza ponašanja uz korištenje platforme. Za više
          detalja pogledajte dolje.
        </p>
        <p>
          <strong>Zakonska osnova za obradu</strong> &ndash; U mjeri u kojoj je to primjenjivo na vas, neke jurisdikcije
          zahtijevaju da svaka obrada osobnih podataka bude potkrijepljena zakonskom osnovom. Općenito se oslanjamo na
          sljedeće pravna opravdanja za naše aktivnosti obrade: 1) izvršenje ugovora s vama i/ili vašim poslodavac; 2)
          ostvarivanje naših legitimnih interesa sve dok oni ne nadjačavaju vaše interese, prava i slobode; 3) vaš
          pristanak; i 4) poštivanje zakonske obveze. Za više detalja pogledajte dolje.
        </p>
        <p>
          <strong>Prijenos podataka</strong> &ndash; Vaše osobne podatke možemo prenijeti drugim CAI podružnicama i/ili
          treće strane (tj. poslovni partneri, preprodavači, itd.), stjecatelji ili stečeni subjekti, pružatelji usluga,
          i, u skladu s važećim zakonom, državna tijela, sudovi, vanjski savjetnici i slično treće stranke. Neki
          prijenosi podataka mogu prelaziti državne granice. Upotrijebit ćemo sve razumne mjere kako bismo to osigurali
          vaši osobni podaci ostaju povjerljivi prilikom prijenosa. Vaše osobne podatke ne prodajemo trećima stranke.
          Osim toga, CAI neće dijeliti vaše podatke s bilo kojom trećom stranom bez potpunog otkrivanja, osim ako
          drugačije navedeno u ovoj Obavijesti o privatnosti. Za više detalja pogledajte dolje.
        </p>
        <p>
          <strong>Zadržavanje i brisanje podataka</strong> &ndash; Vaši osobni podaci bit će izbrisani kada više ne
          postoje potrebni za potrebe izvorne obrade, legitimnog interesa ili kako to zahtijeva primjenjivi zakon. Za
          više detalja, pogledajte u nastavku.
        </p>
        <p>
          <strong>Vaši izbori i prava</strong> &ndash; Ovisno o vašoj nadležnosti, možete ih imati nekoliko prava u vezi
          s vašim osobnim podacima, što može uključivati ​​pravo na pristup vašim osobnim podacima, pravo za dobivanje
          kopije Vaših osobnih podataka, pravo na prijenos Vaših osobnih podataka trećoj strani, pravo na ispravak Vaših
          osobnih podataka, pravo na ograničenje određene obrade i/ili pravo na Vašu osobni podaci izbrisani. Kako biste
          ostvarili svoja prava vezana uz svoje osobne podatke, koristite &ldquo;Kontakt Nas&rdquo; informacije na dnu
          ove Obavijesti o privatnosti. Za više detalja pogledajte dolje.
        </p>
        <p>
          <strong>Promjene ove Obavijesti o privatnosti</strong> &ndash; Zadržavamo pravo izmjene ovih uvjeta Obavijest
          o privatnosti po želji iu bilo koje vrijeme prema promjeni prakse ili zakonodavstvu o privatnosti. Struja
          verzija ove Obavijesti o privatnosti uvijek će biti dostupna putem veze s naših web stranica, platformi ili
          putem &ldquo;Kontaktirajte nas&rdquo; informacije na dnu ove Obavijesti o privatnosti. Trebali biste
          pregledati ovu Privatnost Obavijestite povremeno kako biste bili u tijeku s našim najnovijim pravilima i
          praksama.
        </p>
      </div>
      <div id="controller_processor">
        <h1>Detaljna obavijest o privatnosti</h1>
        <p>
          <strong>Voditelj/izvršitelj obrade &mdash;</strong> CAI može biti voditelj obrade podataka ili izvršitelj
          obrade podataka za svrhe obrade osobnih podataka, ovisno o okolnostima. Gdje CAI obrađuje podatke u ime našim
          klijentima i/ili njihovim podružnicama, CAI najčešće djeluje kao Izvršitelj obrade podataka. Gdje CAI obrađuje
          osobne podataka za vlastite potrebe i izvan uputa svojih kupaca i/ili njihovih podružnica, CAI može biti
          Kontrolor podataka. Ako imate bilo kakvih pitanja o ulozi CAI-ja u vezi s vašim osobnim podacima, molimo vas
          koristite &ldquo;Kontaktirajte nas&rdquo; informacije na dnu ove Obavijesti o privatnosti.
        </p>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <p>
          <strong>Na koga se ova obavijest odnosi &mdash;</strong> Naša obavijest o privatnosti odnosi se na sve osobe
          koje nisu zaposlene obrada osobnih podataka od strane CAI-a neovisno o izvoru prikupljanja. Ova Obavijest o
          privatnosti odnosi se na obrada osobnih podataka koje pružaju: 1) posjetitelji i korisnici različitih CAI
          stranica, aplikacija, proizvoda, i/ili usluge; 2) sudionici CAI i industrijskih događaja; 3) kupci i
          potencijalni kupci i njihovi predstavnici; 4) pretplatnici na naše obavijesti, e-poštu i/ili biltene; 5)
          dobavljači i poslovanje partneri i njihovi zastupnici; 6) posjetiteljima naših ureda; i 7) bilo tko drugi tko
          daje osobne podatke CAI-ju za bilo koju drugu svrhu.
        </p>
      </div>
      <div id="how_we_collect_personal_data">
        <h2>Kako prikupljamo osobne podatke &mdash;</h2>
        <h3>Osobni podaci koje dajete:</h3>
        <p>
          CAI prikuplja osobne podatke koje dostavite vi ili vaš predstavnik. To bi se moglo dogoditi na CAI događaju,
          tijekom događaj podrške, putem marketinga, tijekom interakcije licem u lice, itd. Primjeri vrsta osobnih
          podaci koje CAI može prikupiti uključuju, ali ne moraju biti ograničeni na vaše: ime, adresu, telefonski broj,
          e-mail adresa, poslodavac, titula, datum zapošljavanja, nadzornik, radni staž, broj vozačke dozvole, broj
          zaposlenika, matični broj, drugi porezni broj, podaci o radnom učinku i potvrde, plaćanje informacije, IP
          adresa i/ili identifikator uređaja, geolokacija, potpis, fotografija i/ili video kamera, korisničko ime i
          lozinka, podaci o sindikatu, etnička pripadnost, neki medicinski podaci vezani uz posao, poruke i podatke o
          ponašanju i informacije o vama primljene putem postavljanja kolačića i piksela za praćenje tijekom korištenje
          platforme. Ako imate pitanja ili nedoumica o bilo kojoj od ovih vrsta osobnih podataka, koristite
          &ldquo;Kontaktirajte nas&rdquo; informacije na dnu ove Obavijesti o privatnosti.
        </p>
        <h3>Podaci prikupljeni putem naših proizvoda i usluga:</h3>
        <p>CAI dobiva osobne podatke u vezi s pružanjem svojih proizvoda i usluga, uključujući:</p>
        <p>(i) SaaS proizvode ili usluge hostirane za CAI klijente;</p>
        <p>
          (ii) Proizvodi ili usluge temeljeni na webu za rješenja suradničke trgovine koji pokrivaju cjelokupno
          naručivanje i proces nabave u tržišnom sektoru business-to-business;
        </p>
        <p>
          (iii) On-premise softverski proizvodi licencirani kupcu za korištenje u njihovim vlastitim prostorijama putem
          pružanja stručne usluge;
        </p>
        <p>(iv) Lokalni hardverski proizvodi prodani kupcu za korištenje u njihovim vlastitim prostorijama; i</p>
        <p>
          (v) Usluge korisničke podrške koje se odnose na SaaS, softverske i hardverske proizvode koji se temelje na
          webu i na lokaciji.
        </p>
        <p>
          Na primjer, kada naši korisnici koriste gore navedene proizvode ili usluge, mogu pružiti pojedinosti o svojim
          zaposlenika, uključujući njihova imena, nazive poslova, adrese e-pošte, vjerodajnice za prijavu, telefonske
          brojeve, adrese, datume rođenja, brojeve vozačkih dozvola i druge informacije. Neki od naših web-based
          rješenja omogućuju korisnicima da, na primjer, predaju osobne podatke za stvaranje korisnika rješenja, za
          pohranu transakcijske dokumente koji mogu uključivati ​​neke osobne podatke potpisnika ili poslovnih
          kontakata, za korištenje geo-lokaciju i za pohranjivanje podataka za kontakt povezanih s trgovačkim
          partnerima. Često obrađujemo osobne podatke u ime naših kupaca prema pisanom ugovoru. Ne kontroliramo obradu
          podataka ili prakse zaštite naših kupaca (koji mogu biti vaš poslodavac, pružatelj usluga ili poslovni
          partner), tako njihovi se uvjeti mogu razlikovati od onih navedenih u ovoj Obavijesti o privatnosti. Imajte na
          umu da tamo gdje CAI prikuplja osobne podatke putem proizvoda ili usluga koje kontroliraju naši klijenti, naši
          klijenti su Voditelj obrade podataka za što podaci prikupljaju i kako se koriste i objavljuju. U tim
          slučajevima CAI djeluje samo kao izvršitelj obrade podataka. Bilo koje pitanja vezana uz način na koji naši
          korisnici obrađuju, koriste ili dijele osobne podatke koje prikupljaju putem našeg proizvode ili usluge treba
          usmjeriti na relevantnog kupca.
        </p>
      </div>
      <div id="automatically_collected_information">
        <h3>Automatski prikupljene informacije:</h3>
        <p>
          Dok se krećete i komunicirate s našim web stranicama i/ili SaaS proizvodima i aplikacijama, možemo koristiti
          tehnologije automatskog prikupljanja podataka za prikupljanje određenih informacija o vašoj opremi, radnjama i
          obrasci (“Informacije o aktivnosti korisnika”), uključujući: (i) pojedinosti o vašoj upotrebi, uključujući
          podatke o prometu, lokaciju podataka putem tehnologije geo-lociranja, zapisa i drugih komunikacijskih podataka
          i resursa kojima pristupate i koristiti; i (ii) informacije o vašem uređaju, uključujući vaš operativni
          sustav, IP adresu i druge mobilne telefone podaci za prijavu. Neke od ovih informacija mogu se smatrati
          osobnim podacima u vašoj nadležnosti.
        </p>
        <p>
          Podaci o korisničkim aktivnostima koje prikupljamo pomažu nam poboljšati naše web stranice i proizvode te
          isporučiti a bolju i personaliziraniju uslugu omogućujući nam procjenu obrazaca korištenja, prikaz informacija
          zahtjev u vašoj relevantnoj vremenskoj zoni i prepoznati vas kada se vratite na našu web stranicu ili
          proizvod.
        </p>
        <p>
          Tehnologije koje koristimo za ovo automatsko prikupljanje podataka mogu uključivati ​​web pratioce, oznake
          piksela, skripte ili kolačići. Kolačić je mala datoteka smještena na tvrdi disk vašeg uređaja. Koristimo
          kolačiće za pomoć pri analizi korištenje, prilagođavanje naših usluga, mjerenje učinkovitosti i promicanje
          povjerenja i sigurnosti. O kolačićima se raspravlja dalje ispod.
        </p>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <h2>Kako koristimo podatke koje prikupljamo &ndash;</h2>
        <p>
          CAI koristi podatke koje prikuplja u svrhu učinkovitog poslovanja, pružanja svojih proizvoda i usluge
          klijentima, olakšavanje poslovanja između stranaka u industriji te administracija i upravljanje svoje odnose s
          kupcima. Također koristimo informacije koje prikupljamo za obradu, procjenu i odgovor Vaši zahtjevi;
          odgovarati na upite i prijave; stvarati, administrirati i komunicirati s vama o vašim račun (uključujući sve
          kupnje i plaćanja); upravljati, ocjenjivati ​​i poboljšavati CAI-jevo poslovanje (uključujući razvoj novih
          proizvoda i usluga, upravljanje komunikacijama, istraživanje tržišta, analiza CAI proizvoda/usluga/web
          stranica te obavljanje računovodstva, revizije, naplate, usklađivanja i naplate aktivnosti); osigurati
          sigurnost CAI mrežnih usluga i resursa; i u skladu s primjenjivim zakonima zahtjevi.
        </p>
      </div>
      <div id="how_we_disclose_the_information_we_collect">
        <h2>Kako otkrivamo podatke koje prikupljamo </h2>
        <p>Možemo otkriti neke osobne podatke koje prikupljamo na sljedeći način:</p>
        <ul>
          <li>Našim podružnicama i/ili poslovnim partnerima s kojima imamo ugovorne odnose;</li>
          <li>
            Trećim stranama, kao što su izvođači, pružatelji usluga, konzultanti i drugi agenti („Usluga Pružatelji”),
            koji pružaju pomoć našem poslovanju. CAI surađuje s Pružateljima usluga u nekim slučajevima za niz razloga
            uključujući, na primjer, obradu plaćanja kreditnom karticom, praćenje sati rada, podatke hosting i
            računovodstvo. Pružatelji usluga s kojima dijelimo takve osobne podatke općenito su obvezni obveze
            povjerljivosti i privatnosti te popis takvih Davatelja usluga možete pronaći na našoj web stranici (
            <b>www.commandalkon.com</b>) pod karticom Pravni (Popis podprocesora);
          </li>
          <li>Kako bismo ispunili svrhe za koje ste vi ili vaš poslodavac dali takve osobne podatke;</li>
          <li>
            Kupcu ili drugom nasljedniku CAI-ja ili bilo kojeg od naših povezanih društava u slučaju akvizicije,
            spajanja, oduzimanje, restrukturiranje, reorganizacija, raspuštanje ili druga prodaja ili prijenos nekih ili
            svih CAI-jevih ili bilo koji dionički kapital ili imovinu naših podružnica, bilo kao neograničeno poslovanje
            ili kao dio stečaja, likvidacije ili slične postupke, u kojima su osobni podaci među prenesenom imovinom; i
          </li>
          <li>Za bilo koju drugu svrhu koju otkrijemo kada date osobne podatke.</li>
        </ul>
        <p>
          Bez obzira na bilo koje druge odredbe ove Obavijesti o privatnosti, također zadržavamo pravo pristupa, čuvanja
          i otkriti sve informacije koje prikupimo za koje razumno vjerujemo da su potrebne: (i) za odgovor na pravne
          zahtjeve, uključujući poštivanje svih sudskih naloga, zakona ili pravnih procesa i odgovaranje bilo kojoj
          vladi ili regulatorni zahtjev, uključujući odgovaranje na provođenje zakona ili drugim vladinim službenicima
          kao odgovor na a ovjereni zahtjev koji se odnosi na kaznenu istragu ili navodnu nezakonitu aktivnost; (ii) za
          provedbu ili primjenu naše politike i ugovore; (iii) za otkrivanje, sprječavanje ili na drugi način rješavanje
          prijevare, sigurnosti, povjerenja i sigurnosti ili tehnička pitanja; (iv) za odgovaranje na zahtjeve
          korisničke podrške; ili (v) radi zaštite prava, imovine, zdravlja ili sigurnost CAI-ja, naših korisnika,
          trećih strana ili javnosti općenito.
        </p>
        <h2>Dodatne informacije za stanovnike Kalifornije i drugih država SAD-a </h2>
        <p>
          Osobni podaci koje prikupljamo uključuju informacije unutar kategorija podataka u tablici ispod. Ove
          kategorije također predstavljaju kategorije osobnih podataka koje smo prikupili tijekom proteklih 12 mjeseci.
          Imajte na umu da su dolje navedene kategorije definirane državnim zakonom Kalifornije. Uključivanje a
          kategorija na donjem popisu označava samo to, ovisno o uslugama i proizvodima koje vam pružamo i/ili vašeg
          poslodavca i poslovnog partnera, možemo prikupljati ili raspolagati nekim podacima unutar te kategorije. To ne
          znači nužno da prikupljamo ili otkrivamo sve informacije navedene u određenoj kategoriji, niti to činimo
          prikupljamo sve kategorije informacija za sve pojedince. Osim toga, dok uključujemo podatke prikupljene u
          Business-to-Business kontekstu koji se smatra osobnim podacima prema određenim zakonima o privatnosti kao što
          su EU i GDPR UK-a i Kalifornijskog zakona o pravima na privatnost, ne mogu se smatrati osobnim podacima u
          drugim jurisdikcijama, uključujući unutar drugih američkih država. Objavili smo informacije u svakoj od dolje
          navedenih kategorija s našim podružnice i pružatelje usluga za naše poslovne svrhe u proteklih 12 mjeseci.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Kategorija</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Izvori</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Svrha obrade</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Identifikatori kao što su pravo ime, alias, poštanska adresa, jedinstveni osobni identifikator, mrežni
                  identifikator, Adresa internetskog protokola, adresa e-pošte, naziv računa, broj socijalnog
                  osiguranja, vozačka dozvola broj ili druge slične identifikatore.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ovi podaci, kao i informacije o vašim sklonostima kupnje dobivene od našeg poslovnih
                  partnera, također se koristi u marketinške svrhe, uključujući ponudu proizvoda i usluga koji bi vas
                  mogli zainteresirati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Informacije koje identificiraju, odnose se na, opisuju ili se mogu povezati s, a određenu osobu,
                  uključujući, ali ne ograničavajući se na, vaše ime, potpis, broj socijalnog osiguranja, fizičke
                  karakteristike ili opis, adresu, broj telefona, vozačku dozvolu ili državu broj osobne iskaznice,
                  zdravstveni podaci.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ti se podaci također koriste u marketinške svrhe, uključujući ponudu proizvoda i usluge
                  koje bi vas mogle zanimati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Komercijalne informacije, uključujući evidenciju o osobnoj imovini, kupljenim proizvodima ili
                  uslugama, dobiveni, ili razmatrani, ili druge povijesti ili tendencije kupovine ili potrošnje.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružateljima usluga, vašoj interakciji s našim uslugama i proizvodima ili zapisima koje imamo o vama
                  tijek pružanja usluga ili proizvoda.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ovi podaci, kao i informacije o vašim sklonostima kupnje dobivene od našeg poslovnih
                  partnera, također se koristi u marketinške svrhe, uključujući ponudu proizvoda i usluga koji bi vas
                  mogli zainteresirati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Podaci o internetskoj ili drugoj elektroničkoj mrežnoj aktivnosti, uključujući, ali ne ograničavajući
                  se na, pregledavanje povijest, povijest pretraživanja i informacije o interakciji potrošača s
                  internetskom web stranicom, prijava, odnosno oglas.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružateljima usluga, vašoj interakciji s našim uslugama i proizvodima ili korištenjem kolačića i
                  slične tehnologije.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju kako bi pomogli u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ti se podaci također koriste u marketinške svrhe, uključujući ponudu proizvoda i usluge
                  koje bi vas mogle zanimati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Geolokacijski podaci.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ovi podaci, kao i informacije o vašim sklonostima kupnje dobivene od našeg poslovnih
                  partnera, također se koristi u marketinške svrhe, uključujući ponudu i pružanje proizvode i usluge
                  koji bi vas mogli zanimati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Audio, elektronički, vizualni, toplinski, mirisni ili slični podaci.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
                <p>
                  Za posjetitelje se ove informacije prikupljaju preko naših CCTV kamera, a za pozivatelje se
                  prikupljaju putem audio zapisi.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvode i
                  usluge, uključujući snimanje prodajnih poziva i drugih poziva. Za posjetitelje, vizualni podaci mogu
                  biti obrađen kako bi se održala sigurnost i odgovornost, te u skladu s lokalnim zakonima, uključujući
                  one koji se odnose na zdravlje i sigurnost.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Profesionalne informacije ili informacije vezane uz zaposlenje.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružateljima usluga, vašoj interakciji s našim uslugama i proizvodima ili zapisima koje imamo o vama
                  tijek pružanja usluga ili proizvoda.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti i za upravljanje sukladnošću.
                </p>
                <p>
                  Osim toga, ti se podaci također koriste u marketinške svrhe, uključujući ponudu proizvoda i usluge
                  koje bi vas mogle zanimati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Zaključci izvedeni iz bilo koje od gore navedenih kategorija informacija za izradu profila potrošača
                  odražava potrošačeve sklonosti, karakteristike, psihološke trendove, predispozicije, ponašanje,
                  stavove, inteligenciju, sposobnosti i sklonosti.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
                <p>
                  Osim toga, ti se podaci također koriste u marketinške svrhe, uključujući ponudu proizvoda i usluge
                  koje bi vas mogle zanimati kroz izravno i partnersko oglašavanje.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Osjetljivi osobni podaci, uključujući: sve osobne podatke koji otkrivaju podatke pojedinca socijalnog
                  osiguranja ili drugi državni matični broj; prijava na račun, geolokacija; etničko porijeklo, članstvo
                  u sindikatu; sadržaj pošte, e-pošte ili tekstualnih poruka, osim ako je posao namijenjen primatelj
                  komunikacije; i genetski podaci.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci prikupljaju izravno od vas i/ili vašeg poslodavca, naših poslovnih partnera, naših
                  pružatelji usluga, vaša interakcija s našim uslugama i proizvodima, zapisi koje imamo o vama u tijekom
                  pružanja usluga ili proizvoda ili korištenjem kolačića i sličnih tehnologija.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ovi se podaci obrađuju u vezi s nizom naših operativnih funkcija koje vam možemo pružiti proizvoda i
                  usluga, uključujući olakšavanje poslovanja između strana u industriji i upravljanje odnosima s
                  kupcima.
                </p>
                <p>
                  Također se obrađuje kako bi se pomoglo u upravljanju i administriranju vašeg računa, kao i za
                  otkrivanje sigurnosnih incidenata, zaštite od zlonamjernih, prijevarnih, prijevarnih ili nezakonitih
                  aktivnosti, za upravljanje sukladnošću, analitiku podataka i tehnološki razvoj naših sustava.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          CAI ne prodaje vaše podatke drugima, uključujući u svrhe Kalifornijskog zakona o zaštiti privatnosti potrošača
          2018. ("CCPA") i Kalifornijski zakon o pravima na privatnost iz 2020. ("CPRA").
        </p>
      </div>
      <div id="third_party_services">
        <h2>Usluge trećih strana &ndash;</h2>
        <p>
          Možda ćete dobiti priliku odabrati pristup i korištenje usluga ili aplikacija trećih strana (&ldquo;Usluge
          trećih strana&rdquo;) putem nekih naših web stranica i/ili proizvoda. Ova Obavijest o privatnosti bavi se samo
          CAI-jevim prikupljanjem, korištenjem i otkrivanjem informacija koje prikuplja CAI. Ne primjenjuje se na prakse
          trećih strana koje ne posjedujemo, ne kontroliramo, zapošljavamo ili njima ne upravljamo, uključujući, ali ne
          ograničavajući se na sve usluge trećih strana. Pristupom ili korištenjem usluga ili aplikacija trećih strana
          putem naših web stranica i/ili proizvoda, upućujete nas da u vaše ime otkrijemo vaše podatke Usluzi treće
          strane na zahtjev te treće strane i slažete se da treća strana koristi ili otkriva vaše informacije će biti
          regulirane politikom privatnosti trećih strana i mogu se razlikovati od načina na koji ih mi koristimo i
          otkriti svoje podatke.
        </p>
      </div>
      <div id="security">
        <h2>Sigurnost &ndash;</h2>
        <p>
          CAI koristi razumne i primjerene administrativne, tehničke i fizičke sigurnosne kontrole s vrstama podataka
          koje obrađuje radi zaštite osobnih podataka od gubitka, zlouporabe i neovlaštenog pristupa, otkrivanje,
          izmjena i/ili uništenje. Iako naporno radimo na zaštiti vaše privatnosti, nema sigurnosnih kontrola su 100%
          učinkoviti i ne možemo jamčiti sigurnost informacija ili da su vaši osobni podaci ili privatni komunikacija će
          uvijek ostati privatna. Neovlašteni ulazak ili uporaba, kvar hardvera ili softvera i drugo čimbenici mogu
          ugroziti sigurnost korisničkih podataka.
        </p>
        <p>
          Sigurnost i sigurnost vaših podataka također ovisi o vama. Gdje smo vam dali (ili gdje ste odabrane)
          korisničke vjerodajnice za prijavu (tj. korisnički ID i lozinka) za pristup određenim dijelovima naših usluga
          ili aplikacije, odgovorni ste za čuvanje tajnosti vjerodajnica korisničkog računa. Molimo vas da ne dijelite
          svoje vjerodajnice s bilo kim. Vi ste isključivo odgovorni za zaštitu vjerodajnica korisničkog računa i za svu
          upotrebu vjerodajnica vašeg računa koja je pod vašom kontrolom.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Zadržavanje informacija &ndash;</h2>
        <p>
          Općenito ćemo zadržati osobne podatke onoliko dugo koliko je potrebno za ispunjenje svrha navedenih u ovome
          Obavijest o privatnosti. Ovo zadržavanje općenito traje tijekom trajanja veze plus kratko vremensko razdoblje
          navedeno u našem rasporedu čuvanja podataka. Međutim, određene podatke možemo zadržati dulje prema zahtjevu
          primjenjivog zakona (kao što su porezni, računovodstveni ili drugi pravni zahtjevi) ili u legitimne poslovne
          svrhe. Jednom više ne trebamo koristiti vaše osobne podatke kako bismo ispunili svoje obveze, uklonit ćemo ih
          iz naših sustava i zapise i/ili poduzeti korake da ga pravilno anonimizirate tako da vas se iz njega više ne
          može identificirati. Svaki korištenja navedenih u ovoj Obavijesti o privatnosti predstavlja legitimni interes
          CAI-ja za obradu ili korištenje prikupljeni osobni podaci ili su potrebni za ispunjenje ugovora. Ako se ne
          slažete s ovim pristupom, možete prigovoriti CAI-jevoj obradi ili korištenju Vaših osobnih podataka
          kontaktiranjem CAI-ja pisanim putem putem metoda navedene u nastavku u &ldquo;Kontaktirajte nas&rdquo;
          odjeljak na dnu ove Obavijesti o privatnosti.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Vaša prava i izbori </h2>
        <h3>Informacije o računu &amp; Zahtjevi:</h3>
        <p>
          U skladu s primjenjivim zakonom ovisno o nadležnosti (kao što je, na primjer, EEA od 25. svibnja 2018. nadalje
          ili država Kalifornija od 1. siječnja 2020. nadalje), možete imati sljedeća navedena prava u nastavku u vezi s
          vašim osobnim podacima:
        </p>
        <p>
          1) pravo na pristup (to znači da možete zatražiti da vam dostavimo kopiju vaših osobnih podataka koje mi
          prikupili o vama, kategorije izvora iz kojih su podaci prikupljeni, svrhe prikupljanje podataka, kategorije
          trećih strana s kojima smo podijelili podatke i kategorije osobni podaci koji su podijeljeni s trećim stranama
          u poslovne svrhe);
        </p>
        <p>
          2) pravo na ispravak (to znači da nas možete obavijestiti putem odjeljka "Kontaktirajte nas" na dnu ovog
          Obavijest o privatnosti kako biste ispravili sve pogreške u svojim osobnim podacima ili ažurirali svoje
          postavke; možda i nismo moći udovoljiti vašem zahtjevu ako vjerujemo da bi prekršio bilo koji zakon ili pravni
          zahtjev ili uzrokovao informacije da su netočne; podaci koji se čuvaju isključivo u svrhu sigurnosne kopije
          podataka općenito su isključeni);
        </p>
        <p>
          3) pravo na prijenos (to znači da možete zatražiti da damo kopiju vaših osobnih podataka trećoj osobi zabava
          po vašem izboru);
        </p>
        <p>
          4) pravo na ograničavanje (gdje je naša obrada vaših osobnih podataka nužna za naše legitimne interese, ovisno
          o primjenjivom zakonu o zaštiti podataka/privatnosti, možete uložiti prigovor na to obrada u bilo kojem
          trenutku, uz određena ograničenja i iznimke; možda također možete ograničiti određene vrste obrade Vaših
          osobnih podataka pod određenim okolnostima; posebno, možete zatražiti od nas ograničiti našu upotrebu Vaših
          osobnih podataka ako osporavate njihovu točnost, ako je obrada Vaših osobnih podataka utvrđeno da je
          nezakonito ili ako više ne trebamo vaše osobne podatke za obradu, ali smo ih zadržali kako je dopušteno
          zakonom);
        </p>
        <p>
          5) pravo na povlačenje privole (ovo znači da u onoj mjeri u kojoj naša obrada Vaših osobnih podataka na
          temelju vašeg pristanka, možete povući svoj pristanak u bilo kojem trenutku; povlačenje vašeg pristanka neće,
          međutim, utjecati na zakonitost obrade na temelju vašeg pristanka prije njegovog povlačenja, a neće utjecati
          na zakonitosti naše kontinuirane obrade koja se temelji na bilo kojoj drugoj zakonskoj osnovi za obradu vaših
          osobnih podataka podaci);
        </p>
        <p>
          6) pravo na brisanje (to znači da možete od nas zatražiti brisanje vaših osobnih podataka iz naših sustava i
          mi ćemo to učiniti dakle, osim ako smo dužni zadržati takve podatke kako bismo vam pružili usluge ili mi to
          zahtijevamo osobne podatke u skladu s našim pravnim ili poslovnim obvezama prema mjerodavnom zakonu); ili
        </p>
        <p>7) pravo reći “ne” prodaji Vaših osobnih podataka (Napomena: CAI ne prodaje osobne podatke).</p>
        <p>
          Možda ćete moći ostvariti neka ili sva ova prava prijavom na svoj račun unutar proizvoda koji koristite. U
          suprotnom, da biste izravno zatražili takve informacije, podnesite pisani zahtjev koristeći pojedinosti
          navedeno u &#8220;Kontaktirajte nas&#8221; odjeljak na dnu ove Obavijesti o privatnosti.
        </p>
        <p>
          Imajte na umu da smo prema kalifornijskom zakonu dužni odgovoriti samo na pristup osobnim podacima zahtjeva od
          istog potrošača do dva puta u razdoblju od 12 mjeseci. Prema zakonima EU-a i Kalifornije, ako je pojedinac
          postavlja neutemeljene, ponavljajuće ili pretjerane zahtjeve (prema našem razumnom nahođenju) pristupiti
          Osobnim podacima, možemo naplatiti naknadu podložnu maksimumu određenom zakonom.
        </p>
        <p>
          Ne zaboravite da u određenim regijama također imate pravo žalbe tijelu za zaštitu podataka ("DPA") o našem
          prikupljanju i korištenju Vaših osobnih podataka. Za više informacija obratite se lokalnom DPA-u.
        </p>
        <p>
          U slučaju da CAI obrađuje osobne podatke o vama u ime kupca, obratite pozornost na svoju privatnost upite i
          zahtjeve za pristup, ispravak ili brisanje osobnih podataka takvom kupcu.
        </p>
        <p>
          Prije pružanja informacija koje tražite u skladu s određenim od ovih prava, moramo biti u mogućnosti
          provjeriti vaš identitet. Kako biste potvrdili svoj identitet, možda ćete morati poslati podatke o sebi,
          uključujući, u mjeri u kojoj je to primjenjivo, pružanje odgovora na sigurnosna pitanja, vaše ime, državnu
          identifikaciju broj, datum rođenja, podatke za kontakt ili druge osobne podatke. Poklopit ćemo ovo informacije
          protiv informacija koje smo prethodno prikupili o vama kako bismo potvrdili vaš identitet i vaše zahtjev. Ako
          ne možemo potvrditi vaš identitet kao dio vašeg zahtjeva, nećemo moći udovoljiti vašem zahtjev. Nismo obavezni
          prikupljati dodatne podatke kako bismo vam omogućili da potvrdite svoje identitet. Za zahtjeve za brisanje
          morat ćete podnijeti zahtjev za brisanje koji se može provjeriti.
        </p>
        <p>
          Ako želite imenovati ovlaštenog agenta da podnese zahtjev u vaše ime, morate ga navesti s pisanim, potpisanim
          dopuštenjem za podnošenje zahtjeva za pravo na privatnost u vaše ime ili dostaviti pismo od odvjetnik. Agent
          ili odvjetnik mora dati ovo ovlaštenje u trenutku podnošenja zahtjeva. Možemo od vas zahtijevati potvrdite
          svoj identitet izravno s nama prije nego što damo tražene podatke vašem odobrenom agentu.
        </p>
        <p>Informacije prikupljene u svrhu provjere vašeg zahtjeva koristit će se samo za potvrdu.</p>
        <p>
          Ako odlučite iskoristiti bilo koje od ovih prava, u mjeri u kojoj se primjenjuju, državni zakon SAD-a
          zabranjuje nam diskriminirajući vas na temelju izbora korištenja vaših prava na privatnost. Međutim, možemo
          naplatiti a drugačiju cijenu ili pružanje drugačije razine usluge u mjeri dopuštenoj zakonom.
        </p>
        <h3>Nemoj pratiti signale:</h3>
        <p>
          Neki web-preglednici vam dopuštaju emitiranje signala web-mjestima i mrežnim uslugama koji pokazuju
          preferencije da "ne prate" vaše online aktivnosti. U ovom trenutku ne poštujemo takve signale i ne poštujemo
          ih promijeniti koje informacije prikupljamo ili kako koristimo te informacije na temelju toga postoji li takav
          signal emitirani ili primljeni od nas.
        </p>
        <h3>Promotivna komunikacija:</h3>
        <p>
          S vremena na vrijeme, možemo vam slati marketinške komunikacije o našim proizvodima, u skladu s vašim
          preferencije. U bilo kojem trenutku možete odustati od primanja promotivnih poruka od nas slijedeći upute u
          tim porukama (često će to biti obavijest ili poveznica na dnu poruke). Ako se odlučite i dalje vam možemo
          slati nepromidžbene komunikacije, poput onih o vašem računu ili našim tekućim poslovni odnosi. Zahtjevi za
          isključivanje promotivne komunikacije također se mogu poslati CAI-u u pisanom obliku putem metode navedene u
          nastavku u odjeljku "Kontaktirajte nas" na dnu ove Obavijesti o privatnosti.
        </p>
        <h3>Kolačići:</h3>
        <p>
          Možete upravljati našom upotrebom kolačića putem natpisa kolačića koji se pojavljuje kada pristupite našim
          stranicama. ako ti radije, obično možete odabrati da svoj preglednik postavite tako da uklanja ili odbija
          kolačiće preglednika ili da briše lokalne skladištenje. Također možete podnijeti zahtjeve za upravljanje
          kolačićima tako da nas kontaktirate pisanim putem putem navedenih metoda ispod u odjeljku "Kontaktirajte nas"
          na dnu ove Obavijesti o privatnosti. Imajte na umu da ako to odlučite uklonite ili odbijete neke kolačiće, to
          bi moglo utjecati na funkcionalnost naše web stranice i usluga. Imamo neovisna Pravila o kolačićima dostupna
          na našoj web stranici (<b>www.commandalkon.com</b>) pod karticom Pravno daje više detalja.
        </p>
        <h3>Informacije o lokaciji:</h3>
        <p>
          Usluge temeljene na lokaciji možete uključiti i isključiti podešavanjem postavki svog mobilnog uređaja. Imajte
          na umu da bi, ako odlučite isključiti usluge temeljene na lokaciji, to moglo utjecati na punu funkcionalnost
          naših proizvoda i usluga.
        </p>
        <h3>Prava na privatnost u Kaliforniji:</h3>
        <p>
          Kalifornijski zakon dopušta korisnicima koji su stanovnici Kalifornije da zatraže i dobiju od nas jednom
          godišnje, besplatno naplatu, popis trećih strana kojima smo otkrili njihove osobne podatke (ako ih ima) za
          njihovo izravno marketinške svrhe u prethodnoj kalendarskoj godini, kao i vrstu osobnih podataka koji su im
          otkriveni stranke. Osim ako nije drugačije navedeno u ovoj Obavijesti o privatnosti ili prema ugovoru s
          subjektom podataka, CAI to čini neće prodavati osobne podatke trećim stranama u vlastite marketinške svrhe.
        </p>
      </div>
      <div id="international_data_transfers_and_the_data_privacy_framework">
        <h2>Međunarodni prijenos podataka i okvir za privatnost podataka </h2>
        <p>
          CAI ima sjedište u Sjedinjenim Državama i ima urede diljem svijeta. Kako bismo olakšali naše operacije i
          pomogli u isporuci naše proizvode i usluge, možemo prenijeti osobne podatke u SAD i bilo koju drugu zemlju u
          kojoj su naši djeluju podružnice, dobavljači, konzultanti i pružatelji usluga. Takve zemlje mogu imati zakone
          koji su drugačiji i potencijalno ne tako zaštitni kao zakoni vaše zemlje prebivališta. Ako ste Data Kontrolor
          koji koristi CAI kao procesor podataka i ako sadržaj ili podaci koje pohranjujete na ili putem CAI softvera,
          aplikacije ili web stranice sadrže osobne podatke pojedinaca iz EGP-a, suglasni ste da imate zakonski ovlasti
          za prijenos osobnih podataka CAI-ju, uključujući prijenos u zemlje poput SAD-a zaštita privatnosti i prava
          nadležnih tijela na pristup osobnim podacima možda nisu jednaki onima u EGP.
        </p>
        <p>
          Kada prenosimo osobne podatke u inozemstvo, poduzet ćemo odgovarajuće zaštitne mjere kako bismo zaštitili
          informacije u u skladu s ovom Obavijesti o privatnosti i nastojimo osigurati da se mi, zajedno sa svim
          inozemnim primateljima, pridržavamo s primjenjivim zakonima o privatnosti. CAI se oslanja na EU standardne
          ugovorne klauzule ("SCC") za pravni prijenos osobne podatke između svojih subjekata iz jurisdikcija koje
          podliježu Općoj uredbi EU o zaštiti podataka ("GDPR") američkom CAI-ju oslanja se na Ugovor o prijenosu
          podataka UK-a za pravni prijenos osobnih podataka između svojih subjekata iz jurisdikcija koje podliježu Općoj
          uredbi o zaštiti podataka Ujedinjenog Kraljevstva („UK GDPR“) do SAD
        </p>
        <p>
          Kao dodatak SCC-ovima, ako CAI postane svjestan da bilo koje državno tijelo (uključujući tijelo za provođenje
          zakona) želi dobiti pristup ili kopiju nekih ili svih osobnih podataka koje obrađuje CAI, bilo na a
          dobrovoljna ili obvezna osnova, u svrhe povezane s obavještajnim podacima o nacionalnoj sigurnosti, osim ako
          je zakonski zabranjeno ili pod obveznom zakonskom prisilom koja zahtijeva drugačije, CAI će: 1) odmah
          obavijestiti strana na koju se osobni podaci odnose (tj. kupac ili prodavatelj); 2) obavijestiti nadležno
          tijelo državne uprave da nije ovlašten za otkrivanje osobnih podataka te će, osim ako nije zakonom zabranjeno,
          morati odmah obavijestiti stranku na koju se osobni podaci odnose; 3) obavijestiti organ državne uprave da je
          treba sve zahtjeve ili zahtjeve uputiti izravno strani na koju se osobni podaci odnose; i 4) ne omogućiti
          pristup osobnim podacima do pisanog odobrenja strane na koju se osobni podaci odnose ili dok na to ne budu
          zakonski prisiljeni. Ako je zakonski prisiljen to učiniti, CAI će poduzeti razumne i zakonite napore osporiti
          takvu zabranu ili prisilu. Ako je CAI primoran dati osobne podatke, CAI će samo to učiniti otkriti osobne
          podatke u mjeri u kojoj je to zakonski potrebno učiniti u skladu s primjenjivim zakonskim postupkom.
        </p>
        <p>
          CAI primjenjuje Dodatke o zaštiti podataka ("DPA") s podizvršiteljima obrade koji obrađuju osobne podatke u
          ime CAI-ja gdje to zahtijevaju primjenjivi zakoni o privatnosti. Kada prenosimo osobne podatke iz Europskog
          gospodarskog prostora ("EEA"), Ujedinjenog Kraljevstva ili Švicarske u Sjedinjene Države, CAI je u skladu s
          EU-SAD. Privatnost podataka Okvir (EU-SAD DPF), UK proširenje na EU-SAD. DPF, a švicarski &#8211; Privatnost
          podataka u SAD-u Okvir (Švicarsko-američki DPF) kako je odredilo Ministarstvo trgovine SAD-a. CAI ima
          certifikat za SAD Ministarstvo trgovine da se pridržava EU-SAD-a. Načela okvira za zaštitu privatnosti
          podataka (EU-SAD DPF Načela) u vezi s obradom osobnih podataka primljenih iz Europske unije na temelju EU-SAD
          DPF i iz Ujedinjenog Kraljevstva (i Gibraltara) u ovisnosti o proširenju UK-a na EU-SAD. DPF. CAI je potvrdio
          Ministarstvu trgovine SAD-a da se pridržava Swiss-U.S. Okvir privatnosti podataka Načela (Švicarsko-američka
          DPF načela) u vezi s obradom osobnih podataka primljenih od Švicarska u osloncu na švicarsko-američku. DPF.
          Ako postoji bilo kakav sukob između uvjeta u ovoj Privatnosti Obavijest i EU-SAD DPF načela i/ili
          švicarsko-američkog Načela DPF-a, načela će biti mjerodavna. Do saznajte više o programu Data Privacy
          Framework (DPF) i pogledajte našu certifikaciju, posjetite
          <b>https://www.dataprivacyframework.gov/</b>.
        </p>
        <p>
          U skladu s EU-SAD. DPF, proširenje UK-a na EU-SAD. DPF, i švicarsko-američki. DPF, CAI obvezuje za rješavanje
          pritužbi povezanih s DPF načelima o našem prikupljanju i korištenju vaših osobnih podataka. EU, Pojedinci iz
          UK-a i Švicarske s upitima ili pritužbama u vezi s našim rukovanjem osobnim podacima primljenim u oslanjanje
          na EU-SAD. DPF, proširenje UK-a na EU-SAD. DPF i/ili švicarsko-američki Prvo treba DPF kontaktirajte CAI kako
          je navedeno u odjeljku "Kontaktirajte nas" na dnu ove Obavijesti o privatnosti.
        </p>
        <p>
          Pojedinac ima mogućnost, pod određenim uvjetima, pozvati se na obvezujuću arbitražu za pritužbe u vezi
          usklađenosti s DPF-om nije riješen niti jednim drugim DPF mehanizmom. Za dodatne informacije pogledajte
          <b>https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2</b>. U skladu sa
          EU-SAD. DPF, proširenje UK-a na EU-SAD. DPF, i švicarsko-američki. DPF, CAI obvezuje se uputiti neriješene
          pritužbe koje se odnose na naše postupanje s osobnim podacima primljenim oslanjajući se na EU-SAD. DPF, UK
          Proširenje na EU-SAD DPF i/ili švicarsko-američki DPF prema JAMS-u, alternativnom pružatelju usluga rješavanja
          sporova sa sjedištem u Sjedinjenim Državama. Ako ne dobijete pravovremenu potvrdu o svojim DPF načelima
          pritužbu od nas, ili ako nismo riješili vašu pritužbu u vezi s DPF načelima na vaše zadovoljstvo, posjetite{' '}
          <b>https://www.jamsadr.com/file-a-dpf-claim</b>
          za više informacija ili podnošenje prigovora. Usluge JAMS-a pružaju vam se besplatno.
        </p>
        <p>
          Savezna komisija za trgovinu Sjedinjenih Država ima nadležnost nad usklađenošću CAI-ja s EU-SAD. DPF,
          Proširenje UK-a na EU-SAD DPF, i švicarsko-američki. DPF. Ako CAI dijeli osobne podatke prikupljene u EGP-u,
          Ujedinjenom Kraljevstvu ili Švicarskoj s pružateljem usluga treće strane koji obrađuje podatke isključivo na
          CAI-jevim u ime, tada će CAI biti odgovoran za obradu takvih podataka od strane te treće strane kršenjem
          Načela, osim ako CAI može dokazati da nije odgovoran za događaj koji je uzrokovao štetu.
        </p>
      </div>
      <div id="lawful_basis_for_processing">
        <h2>Zakonska osnova za obradu osobnih podataka pojedinaca (EEA/UK) &ndash;</h2>
        <p>
          Ako ste pojedinac iz EGP-a ili UK-a, naša zakonska osnova za prikupljanje i korištenje osobnih podataka će
          ovise o dotičnim osobnim podacima i specifičnom kontekstu u kojem ih prikupljamo. Koristit ćemo samo vaše
          Osobni podaci u svrhe za koje prikupljamo takve osobne podatke kao što je navedeno u nastavku, osim ako to ne
          moramo koristiti ga kasnije za drugu svrhu koja je kompatibilna s izvornom svrhom. Ako treba dalje obrađujemo
          vaše osobne podatke u svrhu koja nije kompatibilna s izvornom svrhom prikupljanja, mi obavijestit ćemo vas i
          dati objašnjenje pravne osnove koja nam to omogućuje. Međutim, mi općenito oslanjati se na sljedeća zakonska
          opravdanja za naše aktivnosti obrade: 1) izvršenje ugovora s vama i/ili vašim poslodavcem; 2) ostvarivanje
          naših legitimnih interesa sve dok oni ne nadjačavaju vaše interesi, prava i slobode; 3) vaš pristanak; i 4)
          poštivanje zakonske obveze. U nekim slučajevima, mi također se mogu osloniti na odstupanja od članka 49.
          GDPR-a, imaju zakonsku obvezu prikupljanja osobnih podataka ili mogu inače trebate osobne podatke kako biste
          zaštitili svoje vitalne interese ili interese druge osobe. Više detalja u vezi s pravnom osnovom nalazi se u
          nastavku.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Svrha(e) obrade </strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Pravna osnova za obradu</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Za obradu prijava i drugih ugovora za naše proizvode i usluge.</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada Vaših osobnih podataka nužna je za izvršenje ugovora ili sklapanje ugovora s vama i/ili
                      vašim poslodavcem
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Za upravljanje i administriranje ugovora uključujući ugovore o uslugama s vama i vašim poslodavcem.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada vaših osobnih podataka nužna nam je kako bismo bili u skladu sa zakonima i propisima obveze
                    </p>
                  </li>
                  <li>
                    <p>
                      Obrada je neophodna za podršku našim legitimnim interesima u upravljanju našim poslovanjem (ili
                      onima treće strane) pod uvjetom da takvi interesi nisu nadjačani vašim interesima, pravima i
                      slobode
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Za poboljšanje naših proizvoda i usluga, za provođenje istraživanja tržišta, za analizu podataka, za
                  opće svrhe modeliranja rizika i za statističke analize
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada je neophodna za podršku našim legitimnim interesima u upravljanju našim poslovanjem (ili
                      onima treće strane) pod uvjetom da takvi interesi nisu nadjačani vašim interesima, pravima i
                      slobode
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Marketing</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Tražit ćemo vaš pristanak za obradu vaših osobnih podataka za marketing &ndash; koji ti može
                      povući bilo kada
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Za sprječavanje i otkrivanje prijevare, pranja novca ili drugih zločina</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada vaših osobnih podataka nužna nam je kako bismo bili u skladu sa zakonima i propisima
                      obvezama ili prema važećim zakonima
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Za upravljanje našim odnosom s vama i između vas i vašeg poslodavca</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada Vaših osobnih podataka nužna je za izvršenje ugovora ili sklapanje ugovora s vama i/ili
                      vašim poslodavcem
                    </p>
                  </li>
                  <li>
                    <p>
                      Obrada je neophodna za podršku našim legitimnim interesima u upravljanju našim poslovanjem (ili
                      onima treće strane) pod uvjetom da takvi interesi nisu nadjačani vašim interesima, pravima i
                      slobode
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Za pružanje prednosti naših proizvoda i usluga</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Obrada Vaših osobnih podataka nužna je za izvršenje ugovora ili sklapanje ugovora s vama i/ili
                      vašim poslodavcem
                    </p>
                  </li>
                  <li>
                    <p>
                      Obrada je neophodna za podršku našim legitimnim interesima u upravljanju našim poslovanjem (ili
                      onima treće strane) pod uvjetom da takvi interesi nisu nadjačani vašim interesima, pravima i
                      slobode
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="children_policy">
        <h2>Privatnost djece &ndash;</h2>
        <p>
          Poslovanje CAI-ja nije usmjereno prema djeci i CAI svjesno ne prikuplja niti traži informacije od djece ili
          bilo koga mlađeg od 13 godina. CAI ne dopušta svjesno takvim osobama korištenje naše proizvode ili usluge. U
          slučaju da saznamo da smo nenamjerno prikupili osobne podatke od a djeteta ili osobe mlađe od 13 godina, te
          podatke ćemo izbrisati što je prije moguće. Ako vjerujete da bismo mogli imati bilo kakve podatke o djetetu
          ili o djetetu, obratite nam se putem dolje navedenih metoda u &#8220;Kontaktirajte nas&#8221; odjeljak na dnu
          ove Obavijesti o privatnosti.
        </p>
      </div>
      <div id="changes_to_the_privacy_notice">
        <h2>Promjene Obavijesti o privatnosti &ndash;</h2>
        <p>
          Ovu Obavijest o privatnosti možemo izmijeniti u bilo kojem trenutku objavljivanjem izmijenjene Obavijesti o
          privatnosti na našim web stranicama. Datum Obavijest o privatnosti je zadnji put revidirana navedena je na
          vrhu ove Obavijesti o privatnosti. Svi izmijenjeni uvjeti automatski stupaju na snagu nakon što su prvobitno
          objavljeni na našoj web stranici, osim ako nije došlo do promjene u bilo kojem primjenjivom zakon zahtijeva
          hitnu izmjenu. Vi ste odgovorni za povremenu provjeru ove Obavijesti o privatnosti promjene.
        </p>
      </div>
      <div>
        <h2>Pritužbe &ndash;</h2>
        <p>
          Ne dovodeći u pitanje bilo koji drugi upravni ili sudski pravni lijek koji možda imate, možda imate pravo na
          to podnijeti pritužbu lokalnim državnim regulatorima/tijelima za zaštitu podataka ako vjerujete da jesmo
          prekršio primjenjive zahtjeve privatnosti ili zaštite podataka prilikom obrade osobnih podataka o vama.
        </p>
        <h3>U Ujedinjenom Kraljevstvu možete kontaktirati:</h3>
        <p>Ured povjerenice za informiranje</p>
        <p>
          Water Lane, Wycliffe House
          <br />
          Wilmslow &#8211; Cheshire SK9 5AF
        </p>
        <p>Tel. +44 1625 545 700</p>
        <p>
          E-pošta: <b>casework@ico.org.uk</b>
        </p>
        <p>
          Web stranica: <b>https://ico.org.uk</b>
        </p>
        <h3>Za EU pogledajte:</h3>
        <p>
          <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
        </p>
        <h2>Promjene Obavijesti o privatnosti &ndash;</h2>
        <p>
          Ovu Obavijest o privatnosti možemo izmijeniti u bilo kojem trenutku objavljivanjem izmijenjene Obavijesti o
          privatnosti na našim web stranicama. Datum Obavijest o privatnosti je zadnji put revidirana navedena je na
          vrhu ove Obavijesti o privatnosti. Svi izmijenjeni uvjeti automatski stupaju na snagu nakon što su prvobitno
          objavljeni na našoj web stranici, osim ako nije došlo do promjene u bilo kojem primjenjivom zakon zahtijeva
          hitnu izmjenu. Vi ste odgovorni za povremenu provjeru ove Obavijesti o privatnosti promjene.
        </p>
      </div>
      <div id="contact_us">
        <h2>Kontaktirajte nas </h2>
        <p>
          Za kontaktiranje CAI-ja s pitanjima ili nedoumicama o ovoj Obavijesti o privatnosti ili CAI-jevim praksama
          koje se tiču ​​osobnih podataka podataka, molimo upotrijebite kontakt podatke u nastavku. Ako ste stanovnik
          EGP-a, imajte na umu da gdje CAI djeluje kao voditelj obrade odgovoran za vaše osobne podatke, pravna osoba je
          Command Alkon Incorporated u Sjedinjenim Državama. CAI će odgovoriti na sva pitanja i pritužbe u vezi s ovom
          Obavijesti o privatnosti unutar a razumno razdoblje.
        </p>
        <p>
          <strong>E-pošta:</strong>
        </p>
        <p>
          <b>privacy@commandalkon.com</b>
        </p>
        <p>
          <strong>Ili pišite na:</strong>
        </p>
        <p>Command Alkon Incorporated</p>
        <p>Glavni službenik za privatnost preko Pravnog odjela</p>
        <p>
          6750 Crosby Court
          <br />
          Dublin, Ohio 43016
        </p>
        <p>
          <strong>Ili nazovite:</strong>
        </p>
        <p>1-800-624-1872 (besplatno za SAD)</p>
        <p>0-800-022-9682 (besplatno za međunarodni poziv)</p>
        <p>
          <strong>Web stranica:</strong>
        </p>
        <p>
          <b>www.commandalkon.com</b>
        </p>
      </div>
    </Styled>
  );
};
