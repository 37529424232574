import common from '../common';
import errors from './errors';
import themes from './theme';
import features from '../en-US/features';

export default {
  ...common,
  mobileTicket: 'Bon de livraison en ligne',
  ...features,
  save: 'Sauvegarder',
  username: "Nom d'utilisateur",
  password: 'Mot de passe',
  login: 'Se connecter',
  required: 'Obligatoire',
  light: 'Clair',
  dark: 'Sombre',
  rememberMe: 'Se souvenir de moi',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Politique de confidentialité',
  customer: 'Client',
  project: 'Projet',
  isBillable: 'Facturable',
  dispatchDateTime: 'Date',
  orderId: 'Commande',
  ticketId: 'Livraison',
  location: 'Localisation',
  vehicleTypeName: 'Véhicule',
  product: 'Produit',
  products: 'Produit',
  salesTax: 'Taxe de vente',
  totalPrice: 'Total',
  destination: 'Destination',
  productId: 'ID du produit',
  statusCode: 'Statut',
  vehicleTypeId: 'ID du type de véhicule',
  locationId: 'ID du lieu',
  customerId: 'ID du client',
  projectId: 'ID du projet',
  dispatchTime: 'Heure',
  showAll: 'Afficher tout',
  hideAll: 'Cacher tout',
  undoChanges: 'Annuler les modifications',
  resetColumns: 'Réinitialiser les colonnes',
  displayRowCount: `Afficher {count, plural, 
    =0 {0 rows} 
    =1 {# row} 
    other {# rows} 
  }`,
  columnFilters: 'Filtres de colonnes',
  visible: 'Visible',
  hidden: 'Cachés',
  itemDetail: "Détail de l'article",

  quantity: 'Quantité',
  quantityMaxWaterToAdd: "Quantité (Max d'eau à ajouter: {maxWaterQuantity})",
  unloadingMethod: 'Méthode de déchargement',
  time: 'Heure',
  eventStatus: 'Statut',
  MTQ_text: 'Mètres cube',
  M3_text: 'Mètres cube',
  EA_text: 'Quantité',
  LTR_text: 'Litres',
  YDQ_text: 'Cubic Yards',
  GLL_text: 'Gallons',
  ACR_text: 'Acres',
  HUR_text: 'Hour',
  INH_text: 'Inches',
  FOT_text: 'Foot',
  YRD_text: 'Yard',
  MMT_text: 'Millimetre',
  CMT_text: 'Centimetre',
  MTR_text: 'Metre',
  SMI_text: 'Mile (Statute Mile)',
  KMT_text: 'Kilometre',
  INK_text: 'Square Inch',
  FTK_text: 'Square Foot',
  YDK_text: 'Square Yard',
  MIK_text: 'Square Mile',
  CMK_text: 'Square Centimetre',
  MTK_text: 'Cubic Metre',
  KMK_text: 'Square Kilometre',
  INQ_text: 'Cubic Inch',
  MMQ_text: 'Cubic Millimetre',
  FTQ_text: 'Cubic Foot',
  CMQ_text: 'Cubic Centimetre',
  OZA_text: 'Fluid Ounce',
  PT_text: 'Pint',
  QT_text: 'Quart',
  MLT_text: 'Millilitre',
  ONZ_text: 'Ounce',
  LBR_text: 'Pound',
  STN_text: 'Ton',
  GRM_text: 'Gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tonne',
  PS_text: 'PSI',
  MPA_text: 'Megapascal',
  C56_text: 'Newton Per Square Millimetre',
  C62_text: 'One',
  NL_text: 'Load',
  MT_text: 'Mat',
  P1_text: 'Percent',
  CEL_text: 'Degree Celsius',
  FAH_text: 'Degree Fahrenheit',
  BLL_text: 'Barrel',
  CLT_text: 'Centilitre',
  DLT_text: 'Decilitre',
  GLI_text: 'Gallon',
  '2U_text': 'Megagram',
  SA_text: 'Sack',
  '60_text': 'Percent Weight',
  BG_text: 'Bag',
  SEC_text: 'Second',
  MIN_text: 'Minute',
  '64_text': 'Pound per square inch - Gauge',
  SP_text: 'Shelf Package',
  MMK_text: 'Millimètre carré',
  MTN_text: 'tonne métrique',
  GT_text: 'Gigatonne',
  '/NL_text': 'Par chargement',
  '/EA_text': 'Par quantité',
  '/H/ + h_text': 'Par heure',
  '/YQ/ + YDQ_text': 'Par mètre cube',
  '/MQ/ + MTQ_text': 'Par mètre cube',
  '/MN/ + MIN_text': 'Par Minute',
  '/MT_text': 'Par mètre',
  DSG_text: 'Posologie',
  '/C_text': 'Par 100 poids',
  '64_2_text': 'Spécifique',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    =1 {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Litres}
    =1 {1 Litre}
    other {# Litres}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    =1 {1 Item}
    other {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    =1 {1 CY}
    other {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    =1 {1 Cubic Yard}
    other {# Cubic Yards}
  }`,
  GLL: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
  }`,
  EA: `{value, plural,
    =0 {0 Quantité}
    =1 {1 Quantité}
    other {# Quantité}
  }`,
  MIN: `{value, plural,
    =0 {0 Minutes}
    =1 {1 Minute}
    other {# Minutes}
  }`,
  ACR: `{value, plural,
    =0 {0 Acres}
    =1 {1 Acre}
    other {# Acres}
  }`,
  HUR: `{value, plural,
    =0 {0 Hours}
    =1 {1 Hour}
    other {# Hours}
  }`,
  INH: `{value, plural,
    =0 {0 Inches}
    =1 {1 Inch}
    other {# Inches}
  }`,
  FOT: `{value, plural,
    =0 {0 Feet}
    =1 {1 Foot}
    other {# Feet}
  }`,
  YRD: `{value, plural,
    =0 {0 Yards}
    =1 {1 Yard}
    other {# Yards}
  }`,
  MMT: `{value, plural,
    =0 {0 Millimetres}
    =1 {1 Millimetre}
    other {# Millimetres}
  }`,
  CMT: `{value, plural,
    =0 {0 Centimetres}
    =1 {1 Centimetre}
    other {# Centimetres}
  }`,
  MTR: `{value, plural,
    =0 {0 Metres}
    =1 {1 Metre}
    other {# Metres}
  }`,
  SMI: `{value, plural,
    =0 {0 Miles (Statute Miles)}
    =1 {1 Mile (Statute Mile)}
    other {# Miles (Statute Miles)}
  }`,
  KMT: `{value, plural,
    =0 {0 Kilometres}
    =1 {1 Kilometre}
    other {# Kilometres}
  }`,
  INK: `{value, plural,
    =0 {0 Square Inches}
    =1 {1 Square Inch}
    other {# Square Inches}
  }`,
  FTK: `{value, plural,
    =0 {0 Square Feet}
    =1 {1 Square Foot}
    other {# Square Feet}
  }`,
  YDK: `{value, plural,
    =0 {0 Square Yards}
    =1 {1 Square Yard}
    other {# Square Yards}
  }`,
  MIK: `{value, plural,
    =0 {0 Square Miles}
    =1 {1 Square Mile}
    other {# Square Miles}
  }`,
  CMK: `{value, plural,
    =0 {0 Square Centimetres}
    =1 {1 Square Centimetre}
    other {# Square Centimetres}
  }`,
  MTK: `{value, plural,
    =0 {0 Cubic Metres}
    =1 {1 Cubic Metre}
    other {# Cubic Metres}
  }`,
  KMK: `{value, plural,
    =0 {0 Square Kilometres}
    =1 {1 Square Kilometre}
    other {# Square Kilometres}
  }`,
  INQ: `{value, plural,
    =0 {0 Cubic Inches}
    =1 {1 Cubic Inch}
    other {# Cubic Inches}
  }`,
  MMQ: `{value, plural,
    =0 {0 Cubic Millimetres}
    =1 {1 Cubic Millimetre}
    other {# Cubic Millimetres}
  }`,
  FTQ: `{value, plural,
    =0 {0 Cubic Feet}
    =1 {1 Cubic Foot}
    other {# Cubic Feet}
  }`,
  CMQ: `{value, plural,
    =0 {0 Cubic Centimetres}
    =1 {1 Cubic Centimetre}
    other {# Cubic Centimetres}
  }`,
  OZA: `{value, plural,
    =0 {0 Fluid Ounces}
    =1 {1 Fluid Ounce}
    other {# Fluid Ounces}
  }`,
  PT: `{value, plural,
    =0 {0 Pints}
    =1 {1 Pint}
    other {# Pints}
  }`,
  QT: `{value, plural,
    =0 {0 Quarts}
    =1 {1 Quart}
    other {# Quarts}
  }`,
  MLT: `{value, plural,
    =0 {0 Millilitres}
    =1 {1 Millilitre}
    other {# Millilitres}
  }`,
  ONZ: `{value, plural,
    =0 {0 Ounces}
    =1 {1 Ounce}
    other {# Ounces}
  }`,
  LBR: `{value, plural,
    =0 {0 Pounds}
    =1 {1 Pound}
    other {# Pounds}
  }`,
  STN: `{value, plural,
    =0 {0 Tons}
    =1 {1 Ton}
    other {# Tons}
  }`,
  GRM: `{value, plural,
    =0 {0 Grams}
    =1 {1 Gram}
    other {# Grams}
  }`,
  KGM: `{value, plural,
    =0 {0 Kilograms}
    =1 {1 Kilogram}
    other {# Kilograms}
  }`,
  TNE: `{value, plural,
    =0 {0 Tonnes}
    =1 {1 Tonne}
    other {# Tonnes}
  }`,
  PS: `{value, plural,
    =0 {0 PSI}
    =1 {1 PSI}
    other {# PSI}
  }`,
  MPA: `{value, plural,
    =0 {0 Megapascals}
    =1 {1 Megapascal}
    other {# Megapascals}
  }`,
  C56: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    =1 {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
  }`,
  C62: `{value, plural,
    =0 {0 One}
    =1 {1 One}
    other {# One}
  }`,
  NL: `{value, plural,
    =0 {0 Loads}
    =1 {1 Load}
    other {# Loads}
  }`,
  MT: `{value, plural,
    =0 {0 Mats}
    =1 {1 Mat}
    other {# Mats}
  }`,
  P1: `{value, plural,
    =0 {0 Percent}
    =1 {1 Percent}
    other {# Percent}
}`,
  CEL: `{value, plural,
    =0 {0 Degrees Celsius}
    =1 {1 Degree Celsius}
    other {# Degrees Celsius}
}`,
  FAH: `{value, plural,
    =0 {0 Degrees Fahrenheit}
    =1 {1 Degree Fahrenheit}
    other {# Degrees Fahrenheit}
}`,
  BLL: `{value, plural,
    =0 {0 Barrels}
    =1 {1 Barrel}
    other {# Barrels}
}`,
  CLT: `{value, plural,
    =0 {0 Centilitres}
    =1 {1 Centilitre}
    other {# Centilitres}
}`,
  DLT: `{value, plural,
    =0 {0 Decilitres}
    =1 {1 Decilitre}
    other {# Decilitres}
}`,
  GLI: `{value, plural,
    =0 {0 Gallons}
    =1 {1 Gallon}
    other {# Gallons}
}`,
  '2U': `{value, plural,
    =0 {0 Megagrams}
    =1 {1 Megagram}
    other {# Megagrams}
}`,
  SA: `{value, plural,
    =0 {0 Sacks}
    =1 {1 Sack}
    other {# Sacks}
}`,
  60: `{value, plural,
    =0 {0 Percent Weight}
    =1 {1 Percent Weight}
    other {# Percent Weight}
}`,
  BG: `{value, plural,
    =0 {0 Bags}
    =1 {1 Bag}
    other {# Bags}
}`,
  SEC: `{value, plural,
    =0 {0 Seconds}
  =1 {1 Second}
  other {# Seconds}
}`,
  64: `{value, plural,
    =0 {0 Pounds per square inch - Gauge}
    =1 {1 Pound per square inch - Gauge}
    other {# Pounds per square inch - Gauge}
}`,
  SP: `{value, plural,
    =0 {0 Shelf Packages}
    =1 {1 Shelf Package}
    other {# Shelf Packages}
}`,
  MMK: `{value, plural,
    =0 {0 millimètres carrés}
    one {1 millimètre carré}
    other {# millimètres carrés}
  }`,
  MTN: `{value, plural,
    =0 {0 tonne métrique}
    one {1 tonne métrique}
    other {# tonnes métriques}
  }`,
  GT: `{value, plural,
    =0 {0 gigatonne}
    one {1 gigatonne}
    other {# gigatonnes}
  }`,
  '/NL': `{value, plural,
    =0 {0 par charge}
    one {1 par charge}
    other {# par charge}
  }`,
  '/EA': `{value, plural,
    =0 {0 par quantité}
    one {1 par quantité}
    other {# par quantité}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 par heure}
    one {1 par heure}
    other {# par heure}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 par verge cube}
    one {1 par mètre cube}
    other {# par mètre cube}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 par mètre cube}
    one {1 par mètre cube}
    other {# par mètre cube}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 par minute}
    one {1 par minute}
    other {# par minute}
  }`,
  '/MT': `{value, plural,
    =0 {0 par mètre}
    one {1 par mètre}
    other {# par mètre}
  }`,
  DSG: `{value, plural,
    =0 {0 Dosage}
    one {1 Dosage}
    other {# Dosage}
  }`,
  '/C': `{value, plural,
    =0 {0 pour 100 poids}
    one {1 pour 100 poids}
    other {# pour 100 poids}
  }`,
  '64_2': `{value, plural,
    =0 {0 Spécifique}
    one {1 Spécifique}
    other {# Spécifique}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
}`,
  TO_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# Tn}
}`,
  ITEM_short: `{value, plural,
  =0 {0 Items}
  one {1 Item}
  other {# Items}
}`,
  GLL_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  EA_short: `{value, plural,
  =0 {0 EA}
  one {1 EA}
  other {# EA}
}`,
  MIN_short: `{value, plural,
  =0 {0 MIN}
  one {1 MIN}
  other {# MIN}
}`,
  ACR_short: `{value, plural,
  =0 {0 AC}
  one {1 AC}
  other {# AC}
}`,
  HUR_short: `{value, plural,
  =0 {0 HR}
  one {1 HR}
  other {# HR}
}`,
  INH_short: `{value, plural,
  =0 {0 IN}
  one {1 IN}
  other {# IN}
}`,
  FOT_short: `{value, plural,
  =0 {0 FT}
  one {1 FT}
  other {# FT}
}`,
  YRD_short: `{value, plural,
  =0 {0 YD}
  one {1 YD}
  other {# YD}
}`,
  MMT_short: `{value, plural,
  =0 {0 MM}
  one {1 MM}
  other {# MM}
}`,
  CMT_short: `{value, plural,
  =0 {0 CM}
  one {1 CM}
  other {# CM}
}`,
  MTR_short: `{value, plural,
  =0 {0 M}
  one {1 M}
  other {# M}
}`,
  SMI_short: `{value, plural,
  =0 {0 Miles (ST MI)}
  one {1 Mile ((ST MI)}
  other {# Miles ((ST MI)}
}`,
  KMT_short: `{value, plural,
  =0 {0 KM}
  one {1 KM}
  other {# KM}
}`,
  INK_short: `{value, plural,
  =0 {0 SQ IN}
  one {1 SQ IN}
  other {# SQ IN}
}`,
  FTK_short: `{value, plural,
  =0 {0 FT2}
  one {1 FT2}
  other {# FT2}
}`,
  YDK_short: `{value, plural,
  =0 {0 SQ YD}
  one {1 SQ YD}
  other {# SQ YD}
}`,
  MIK_short: `{value, plural,
  =0 {0 SQ MI}
  one {1 SQ MI}
  other {# SQ MI}
}`,
  CMK_short: `{value, plural,
  =0 {0 CM2}
  one {1 CM2}
  other {# CM2}
}`,
  MTK_short: `{value, plural,
  =0 {0 M3}
  one {1 M3}
  other {# M3}
}`,
  KMK_short: `{value, plural,
  =0 {0 KM2}
  one {1 KM2}
  other {# KM2}
}`,
  INQ_short: `{value, plural,
  =0 {0 CU IN}
  one {1 CU IN}
  other {# CU IN}
}`,
  MMQ_short: `{value, plural,
  =0 {0 MM3}
  one {1 MM3}
  other {# MM3}
}`,
  FTQ_short: `{value, plural,
  =0 {0 FT3}
  one {1 FT3}
  other {# FT3}
}`,
  CMQ_short: `{value, plural,
  =0 {0 CM3}
  one {1 CM3}
  other {# CM3}
}`,
  OZA_short: `{value, plural,
  =0 {0 FL OZ}
  one {1 FL OZ}
  other {# FL OZ}
}`,
  PT_short: `{value, plural,
  =0 {0 PT}
  one {1 PT}
  other {# PT}
}`,
  QT_short: `{value, plural,
  =0 {0 QT}
  one {1 QT}
  other {# QT}
}`,
  MLT_short: `{value, plural,
  =0 {0 mL}
  one {1 mL}
  other {# mL}
}`,
  ONZ_short: `{value, plural,
  =0 {0 OZ}
  one {1 OZ}
  other {# OZ}
}`,
  LBR_short: `{value, plural,
  =0 {0 LB}
  one {1 LB}
  other {# LB}
}`,
  STN_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  GRM_short: `{value, plural,
  =0 {0 G}
  one {1 G}
  other {# G}
}`,
  KGM_short: `{value, plural,
  =0 {0 KG}
  one {1 KG}
  other {# KG}
}`,
  TNE_short: `{value, plural,
  =0 {0 TN}
  one {1 TN}
  other {# TN}
}`,
  PS_short: `{value, plural,
  =0 {0 PSI}
  one {1 PSI}
  other {# PSI}
}`,
  MPA_short: `{value, plural,
  =0 {0 MPa}
  one {1 MPa}
  other {# MPa}
}`,
  C56_short: `{value, plural,
    =0 {0 Newtons Per Square Millimetre}
    =1 {1 Newton Per Square Millimetre}
    other {# Newtons Per Square Millimetre}
}`,
  C62_short: `{value, plural,
  =0 {0 One}
  one {1 One}
  other {# One}
}`,
  NL_short: `{value, plural,
  =0 {0 Loads}
  one {1 Load}
  other {# Loads}
}`,
  MT_short: `{value, plural,
  =0 {0 Mats}
  one {1 Mat}
  other {# Mats}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 C}
  one {1 C}
  other {# C}
}`,
  FAH_short: `{value, plural,
  =0 {0 F}
  one {1 F}
  other {# F}
}`,
  BLL_short: `{value, plural,
  =0 {0 BBL}
  one {1 BBL}
  other {# BBL}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 dL}
  one {1 dL}
  other {# dL}
}`,
  GLI_short: `{value, plural,
  =0 {0 GAL}
  one {1 GAL}
  other {# GAL}
}`,
  '2U_short': `{value, plural,
  =0 {0 Megagrams}
  one {1 Megagram}
  other {# Megagrams}
}`,
  SA_short: `{value, plural,
  =0 {0 Sacks}
  one {1 Sack}
  other {# Sacks}
}`,
  '60_short': `{value, plural,
  =0 {0 Percent Weight}
  one {1 Percent Weight}
  other {# Percent Weight}
}`,
  BG_short: `{value, plural,
  =0 {0 Bags}
  one {1 Bag}
  other {# Bags}
}`,
  SEC_short: `{value, plural,
  =0 {0 SEC}
  one {1 SEC}
  other {# SEC}
}`,
  '64_short': `{value, plural,
  =0 {0 Pounds per square inch - Gauge}
  one {1 Pound per square inch - Gauge}
  other {# Pounds per square inch - Gauge}
}`,
  SP_short: `{value, plural,
  =0 {0 Shelf Packages}
  one {1 Shelf Package}
  other {# Shelf Packages}
}`,
  MMK_short: `{value, plural,
    =0 {0 millimètres carrés}
    one {1 millimètre carré}
    other {# millimètres carrés}
}`,
  MTN_short: `{value, plural,
  =0 {0 MT}
  one {1 MT}
  other {# MT}
}`,
  GT_short: `{value, plural,
  =0 {0 GT}
  one {1 GT}
  other {# GT}
}`,
  '/NL_short': `{value, plural,
  =0 {0 /Charge}
  one {1 /Charge}
  other {# /Charge}
}`,
  '/EA_short': `{value, plural,
  =0 {0 /EA}
  one {1 /EA}
  other {# /EA}
}`,
  '/H/ + h_short': `{value, plural,
  =0 {0 /Heure}
  one {1 /Heure}
  other {# /Heure}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
  =0 {0 /CY}
  one {1 /CY}
  other {# /CY}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
  =0 {0 /M3}
  one {1 /M3}
  other {# /M3}
}`,
  '/MN/ + MIN_short': `{value, plural,
  =0 {0 /MIN}
  one {1 /MIN}
  other {# /MIN}
}`,
  '/MT_short': `{value, plural,
  =0 {0 /M}
  one {1 /M}
  other {# /M}
}`,
  DSG_short: `{value, plural,
  =0 {0 Dosage}
  one {1 Dosage}
  other {# Dosage}
}`,
  '/C_short': `{value, plural,
  =0 {0 /C}
  one {1 /C}
  other {# /C}
}`,
  '64_2_short': `{value, plural,
  =0 {0 Spécifique}
  one {1 Spécifique}
  other {# Spécifique}
}`,
  mixIdLabel: 'ID/Description du mélange',
  productIdLabel: 'ID du produit/Description',
  orderedQtyLabel: 'Qté commandée',
  loadedQtyLabel: 'Qté chargée',
  addedByCustomer: 'Ajouté à la demande du client',
  PRINTED: 'Assigné',
  LOADING_STARTED: 'Début du chargement',
  LOADING_COMPLETE: 'Fin du chargement',
  TO_JOB: 'Départ centrale',
  ARRIVE_JOB: 'Arrivée sur le chantier',
  UNLOADING: 'Début du déchargement',
  END_UNLOADING: 'Fin du déchargement',
  LEAVE_JOB: 'Quitter le chantier',
  IN_YARD: 'Sur Centrale',
  PUMPING_STARTED: 'Commencez à pomper',
  PUMPING_COMPLETE: 'Fin du pompage',
  READY_TO_PUMP: 'Prêt à pomper',
  FIRST_WCCONTACT: 'Contact eau-ciment',
  plantName: 'Nom de la centrale',
  plantAddress: 'Adresse de la centrale',
  loadingScheduleStart: 'Heure planifiée de chargement',
  deliveryAddress: 'Adresse de livraison',
  phoneNumber: 'Numéro de téléphone',
  unloadingScheduleStart: 'Heure de livraison conventionnelle (HLC)',
  agreedUnloading: 'Temps de déchargement ordonné',
  deliveryInformation: 'Informations sur la livraison',
  next: 'Suivant',
  material: 'Produit',
  loadingPoint: 'Point de chargement',
  unloadingPoint: 'Point de déchargement',
  comment: 'Commentaires',
  sapDeliveryNumber: 'Numéro de livraison SAP (numéro BCC)',
  salesOrderNumber: "Numéro de commande et ligne d'article",
  wcDateTime: 'Date et heure du contact W/C',
  po: 'Numéro de PO',
  orderedByName: 'Commande passée par nom',
  orderedByPhone: 'Commande passée par numéro de téléphone',
  resourceId: 'ID du véhicule',
  totalLoadedQty: 'Quantité totale chargée, y compris ce chargement',
  spacing: 'Intervalle de chargement',
  deliveryFlow: 'Cadence de livraison',
  timeOnSite: 'Temps total sur site/Heure planifiée de chargement (HLC)',
  salesText: "Texte de vente (ID de l'article)",
  strengthClass: 'Classe de résistance',
  environmentExposure: "Exposition à l'environnement",
  metExposureClasses: "Classe d'exposition atteinte",
  dmax: 'Dmax',
  standardCert: 'Norme (certification)',
  workabilityTarget: 'Objectif de maniabilité du client',
  stoneQuality: 'Qualité de la pierre',
  sandQuality: 'Qualité du sable',
  targetWC: 'Rapport W/C cible de la recette',
  controlClass: 'Classe de contrôle',
  chlorideClass: 'Classe de chlorure',
  typeAndStrength1: 'Type de ciment et classe de résistance 1',
  typeAndStrength2: 'Type de ciment et classe de résistance 2',
  typeOfAddition: "Type d'adjuvant",
  typeOfAdmixture: "Type d'adjuvant",
  orderedTemp: 'Température commandée',
  fibers1: 'Type et contenu des fibres 1',
  fibers2: 'Type et contenu des fibres 2',
  calculatedMaturityTime: 'Temps de maturité calculé à partir du temps de contact W/C',
  mixIdentity: 'Mix Identity',
  grade: 'Grade',
  mixType: 'Mix Type',
  maxAggSize: 'Max Agg Size',
  maxAggType: 'Max Agg Type',
  cementType: 'Cement Type',
  admixtures: 'Admixtures',
  addmixDescription: 'Addmix Description',
  slump: 'Slump',
  minCementContent: 'Min Cement Content',
  maxWatCemRatio: 'Max Water/Cement Ratio',
  maxWaterToAdd: 'Max Water To Add',
  mortarMinWorkingLife: 'Mortar Min Working Life',
  code: 'Code',
  dcClass: 'dcClass',
  chloride: 'Chloride',
  signedByHanson: 'Signed By Hanson',
  warmStamp: 'Warm Stamp',
  coldStamp: 'Cold Stamp',
  containsSikatard: 'Contains Sikatard',
  containsDelvo: 'Contains Delvo',
  generalDelivery: 'Informations générales sur la livraison',
  materialInfo: 'Informations sur les matériaux',
  water: 'Eau',
  materialService: 'Produit / Service',
  returnedMaterial: 'Produit retourné',
  returnedConcrete: 'Béton retourné',
  waterAdded: 'Eau ajoutée',
  concreteOnTruck: 'Quantité béton restante dans le camion',
  concreteOnTruckField: 'Activer le béton sur le terrain des camions',
  materialOnTruckField: 'Activer le matériau sur le terrain des camions',
  concreteOnTruckUom: 'Béton par défaut sur camion UOM',
  reason: 'Raison',
  customerAddedWater: "Le client a ajouté de l'eau",
  addWater: "Ajout d'eau",
  addedAt: 'Ajouté à',
  addMaterialService: 'Ajout de produit/service',
  customerAddedProduct: 'Produit ajouté par le client',
  addReturnedMaterial: 'Ajout de produit retourné',
  leftover: 'Restant',
  submitAcceptance: "Soumettre l'acceptation",
  accepted: 'Accepté',
  rejected: 'Rejeté',
  customerAcceptance: 'Acceptation du client',
  finalize: 'Finaliser',
  materialSummary: 'Résumé du produit',
  mix: 'Mélange',
  ordered: 'Commandé',
  loaded: 'Quantité chargée',
  totalOrdered: 'Quantité totale de la commande',
  loadedOrderQuantity: 'Quantité totale chargée',
  loadedOrder: 'Déjà chargé',
  driverComment: 'Commentaires du chauffeur',
  customerComment: 'Commentaires du client',
  editComment: 'Modifier le commentaire',
  addComment: 'Ajouter un commentaire',
  additionalInfo: 'Informations supplémentaires',
  onBoard: 'A bord',
  driver: 'Chauffeur',
  commentInstruction: 'Commentaire Instruction',
  signatureInstruction: 'Signature du client',
  customerSignature: 'Signature du client',
  general: 'Général',
  status: 'Statut',
  updateStatusTime: 'Mise à jour du statut Heure',
  delete: 'Supprimer',
  cancel: 'Annuler',
  back: 'Retour',
  add: 'Ajouter',
  clear: 'Effacer',
  edit: 'Modifier',
  done: 'Valider',
  Submit: 'Soumettre',
  ok: 'OK',
  decline: 'Déclin',
  submit: 'Soumettre',
  lastRefresh: 'Dernier rafraîchissement',
  refreshEnabled: 'Activation du rafraîchissement',
  id: 'ID',
  name: 'Nom',
  invoiceable: 'Facturable',
  provideSignature: 'Fournir une signature',
  ticket: 'Bon de livraison',
  details: 'Détails',
  qrCode: 'QR Code',
  driverAcceptanceReason: 'Raison',
  driverAcceptance: 'Acceptation du chauffeur',
  'payment-method': 'Méthodes de paiement',
  'add-payment-method': 'Ajouter une méthode de paiement',
  'reason-code': 'Code de raison',
  'add-reason-code': 'Ajouter un code de raison',
  'qr-code': 'Code QR',
  'unloading-method': 'Méthodes de déchargement',
  'add-unloading-method': 'Ajouter une méthode de déchargement',
  'driver-comment': 'Commentaires du chauffeur',
  'add-driver-comment': 'Ajouter un commentaire un chauffeur ',
  'driver-sellable': 'Chauffeur vendable',
  vehicle: 'Véhicules',
  'add-vehicle': 'Ajouter un véhicule',
  'vehicle-type': 'Types de véhicules',
  vehicleTypes: 'Types de véhicules',
  vehicleType: 'Type de véhicule',
  'add-vehicle-type': 'Ajouter le type de véhicule',
  countries: 'Pays',
  country: 'Pays',
  type: 'Type',
  uom: 'UOM',
  mandatory: 'Obligatoire',
  qrTemplate: 'Modèle de code QR',
  includeMixInfo: 'Inclure les informations sur le mélange dans le code QR',
  includeTicketEvents: 'Inclure les événements liés aux livraisons dans un code QR',
  quantityField: 'Activation du champ Quantité',
  timeField: 'Activation du champ Heure',
  reasonField: 'Activation du champ Raison',
  driverInstructions: 'Instructions pour le chauffeur',
  waitingForTicket: 'En attente de billet...',
  createTestTicketForTruck: 'Créer un ticket de test pour un camion',
  exceedsLoadedQuantity: 'La valeur ne peut pas dépasser la quantité chargée de {loadedQuantity}.',
  maxQuantity: 'Quantité maximale',
  maxQuantityError: 'La quantité ne peut pas dépasser la quantité maximale définie de {maxQty}',
  maxValueError: 'Value cannot exceed defined maximum of {maxQty}',
  minValueError: 'Value cannot be less than defined minimum of {minQty}',
  notAuthorized: 'Non autorisé',
  enterTruckNumber: 'Entrer le numéro du camion',
  setTruckNumber: 'Définir le numéro du camion',
  commentList: 'Liste des commentaires',
  list: 'Liste',
  gridPreferences: 'Liste',
  loadingUnloadingPoints: 'Points de chargement/déchargement',
  activity: 'Activité',
  crn: 'Crn',
  update: 'Actualisation',
  CONCRETE: 'Béton',
  WATER: 'Eau',
  ADDITIVE: 'Additif',
  'submit-signature-button': 'Accepter',
  'cancel-signature-button': 'Annuler',
  uomCode: 'Default UOM',
  'product-type': 'Types de produits',
  'add-product-type': 'Ajouter un type de produit',
  'add-product': 'Ajouter un produit',
  'setup_driver-comment': 'Ajouter un produit',
  setup_product: 'Produits',
  'setup_product-type': 'Types de produits',
  'setup_qr-code': 'QR Code',
  'setup_reason-code': 'Codes de raison',
  'setup_unloading-method': 'Méthodes de déchargement',
  'setup_entity-mt': 'Société',
  setup_vehicle: 'Véhicules',
  'setup_vehicle-type': 'Types de véhicules',
  'setup_data-retentions': 'La conservation des données',
  'add-data-retentions': 'Ajouter la rétention des données',
  'data-retentions': 'La conservation des données',
  setup_carriers: 'Transporteurs',
  'add-carriers': 'Ajouter les transporteurs',
  carriers: 'Transporteurs',
  'setup_concrete-classes': 'Conformité matérielle',
  'add-concrete-classes': 'Ajouter la conformité du matériel',
  'concrete-classes': 'Conformité matérielle',
  'setup_forms-and-waivers': 'Formulaires et dérogations',
  'add-forms-and-waivers': 'Ajouter des formulaires et des dérogations',
  'forms-and-waivers': 'Formulaires et dérogations',
  companyFleet: "Flotte de l'entreprise",
  category: 'Catégorie',
  interval: 'Intervalle',
  orders: 'Commandes et billets',
  '30days': '30 Days',
  '60days': '60 Days',
  '90days': '90 Days',
  '1year': '1 Year',
  '3years': '3 Years',
  waterMandatory: 'Types de véhicules',
  qrCodeTemplate: 'Modèle de code QR',
  waterConfig: 'Optional Fields',
  waterProduct: 'Eau',
  waterProductType: 'Type de produit eau',
  defaultWaterProduct: "Produit d'eau par défaut",

  invalidTemplate: 'Modèle non valide',
  siteAddress: 'Adresse du site',
  siteContactName: 'Nom du contact sur le site',
  siteContactPhoneNumber: 'Numéro de téléphone du contact sur le site',
  'clear-signature-button': 'Supprimer la signature',
  customerCommentEnabled: 'Activer le commentaire client',
  driverCommentEnabled: 'Activer le commentaire Chauffeur',
  driverCommentMaxCharacters: 'Longueur maximale',
  customerCommentMaxCharacters: 'Longueur maximale',
  timeZone: 'Fuseau horaire',
  driverDidNotAdd: "N'a pas été ajouté",
  maxLineItems: "Nombre maximal d'éléments de ligne",
  oneLineItem: 'Doit avoir au moins 1 ligne',
  signatureSiteContact: 'Nom du client',
  ticketEvents: 'Événements liés aux billets',
  hideEvent: "Cacher l'événement",
  editable: 'Modifiable',
  ticketDetail: `Billet #{value}`,
  statusTimes: 'Temps de statut',
  materialAndDeliveryInfo: 'Heure planifiée de chargement',
  customerRejection: 'Rejet du client',
  'submit-rejection-button': 'Soumettre le rejet',
  accept: 'Accepter',
  reject: 'Rejeter la charge',
  signature: 'Signature',
  'no-signature': 'Pas de signature',
  unFinalizePassword: 'Un-Finalize mots de passe',
  waterInWorkflow: "L'ajout d'eau est obligatoire",
  multicountry: 'Multicountry',
  timestampAfterSequence: 'AVERTISSEMENT : Le temps ne doit pas tomber avant le temps précédent',
  timestampBeforeSequence: 'AVERTISSEMENT : Le temps ne doit pas tomber après le temps suivant',
  noRestQuantity: 'Pas de matériel retourné',
  returnedMaterialMandatory: 'Quantité retournée obligatoire',
  driverCommentMaxChars: 'Length cannot exceed max of {maxLength} characters.',
  QrCodeEnabled: 'Activer le code QR',
  QrMethod_linkBased: 'Basé sur un lien',
  QrMethod_contentBased:
    'Basé sur le contenu, REMARQUE: Les codes QR basés sur le contenu ont des limitations de caractères qui peuvent entraîner des problèmes au-delà de 1024 caractères.',
  QrMethod_contentBasedWarning:
    'REMARQUE: Ce code QR comporte {amount} caractères qui peuvent ne pas être lisibles par certains téléphones.',
  returnedMaterialInWorkflow: "Activer l'étape de retour de matériel dans le flux de travail",
  materialServiceInWorkflow: "Activer l'étape de produit/service dans le flux de travail",
  totalPumpedQuantity: 'Quantité totale pompée',
  concrete: 'Béton',
  materialComplianceEnabled: 'Activer la conformité des matériaux',
  pumpStatusTimes: 'Temps de statut de la pompe',
  pollingInterval: 'Intervalle de sondage (secondes)',
  tenSecs: "L'intervalle doit être d'au moins 10 secondes",
  materialCompliance: 'Conformité des matériaux',
  materialCompliance_notFound: 'Conformité du matériel non trouvée',
  consistenceValue: 'Cohérence - effondrement',
  consistenceIsCompliant: 'La cohérence est conforme à la norme BS8500-2',
  airContentValue: 'Contenu aérien',
  airContentIsCompliant: 'Le contenu aérien est conforme à la norme BS8500-2',
  consistenceIsCompliantShort: 'La cohérence est conforme',
  airContentIsCompliantShort: 'Le contenu aérien est conforme',
  compliance: 'Conformité matérielle avec BS8500-2',
  termsAndConditionsEnabled: 'Activer le formulaire des termes et conditions',
  termsAndConditions: 'Termes et conditions',
  damageWaiverEnabled: 'Activer le formulaire de renonciation aux dommages',
  damageWaiver: 'Renonciation aux dommages',
  damageWaiverAcknowledged: 'Exonération de dommages reconnue',
  dynamicWaiver: 'Dispense dynamique',
  dynamicWaiverEnabled: 'Activer le formulaire de renonciation dynamique',
  ticketListEnabled: 'Activer la liste des billets',
  clearTicketAfterSubmission: 'Effacer le billet après soumission',
  keepTicketOpenAfterSubmission: 'Garder le billet ouvert après soumission',
  displayTicketList: "Liste des billets d'affichage",
  supportedLanguages: 'Langues prises en charge',
  allow: 'Permettre',
  default: 'Défaut',
  translations: 'Tradons',
  family_name: 'Nom de famille',
  given_name: 'Prénom',
  email: 'E-mail',
  role: 'Rôle',
  inviteUser: "Inviter l'utilisateur",
  carrier: 'Transporteur',
  emailOrPhoneNumber: 'E-mail',
  usersEditor: 'Rédacteur en chef',
  verifyPassword: 'vérifier le mot de passe',
  verificationCode: 'Le code de vérification',
  register: 'Registre',
  registerInstructions: 'Veuillez remplir ce formulaire pour finaliser votre inscription.',
  'password-requirements':
    'Votre mot de passe doit comporter au moins 8 caractères, contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
  passwordMinCharacters: 'Le mot de passe doit contenir 8 caractères ou plus.',
  passwordMaxCharacters: 'Le mot de passe ne peut pas contenir plus de 99 caractères.',
  passwordLowerCase: 'Le mot de passe doit inclure 1 ou plusieurs lettres en minuscules.',
  passwordUpperCase: 'Le mot de passe doit inclure une lettre supérieure ou plus.',
  passwordNumber: 'Le mot de passe doit inclure 1 ou plusieurs caractères numériques.',
  passwordSpecialCharacter: 'Le mot de passe doit inclure 1 ou plusieurs caractères spéciaux.',
  passwordsDontMatch: "Passwords don't match!",
  success: 'Succès!',
  'registration-success': 'Votre inscription a réussi.',
  'post-registration-instructions':
    "Vous avez terminé le processus d'inscription. Vous pouvez maintenant vous connecter.",
  createDate: 'Create Date',
  customerPo: 'Numéro de commande du client',
  specification: 'Spécification',
  description: 'Description',
  classType: 'Type de classe',
  minimumValue: 'Valeur minimum',
  maximumValue: 'Valeur maximum',
  TrackIt: 'Trackit',
  trackIt3PContext: 'Contexte Trackit 3P',
  inviteLanguage: 'Inviter la langue',
  truckNumber: 'Numéro de camion',
  adverseTemperature:
    'La température ambiante au moment du malaxage était telle que nous ne pouvons garantir que le béton sera dans les plages de température de 5 à 35 °C comme spécifié dans la norme EN 206.',
  environmentalNote:
    "Cette charge contient un agent environnemental qui n'excède pas 0,03% du poids du béton. L'eau contenant ce matériau pour la fabrication du béton sera conforme à la norme BS EN 1008.",
  unloadingMethodEnabled: 'Activer la méthode de déchargement',
  content: 'Contenu',
  WARNINGS: 'Avertissements',
  TERMS: 'Termes',
  DAMAGE: 'Dommage',
  ADDITIONALWAIVER: 'Dérogation supplémentaire',
  DYNAMICWAIVER: 'Dispense dynamique',
  warrantyVoided: 'Garantie matérielle annulée',
  isCertificationLossTriggerForReason: 'Provoque la perte de certification',
  isCertificationLossTriggerForProduct: 'Provoque la perte de certification',
  materialWarrantyVoidedEnabled:
    'Lorsque le produit de perte de certification ou le code produit & raison est utilisé, affichez une note de perte de certification au moment de la signature.',
  'setup_templates-mt': 'Modèles',
  copyFrom: 'Copier de...',
  ticketList: 'Liste des billets',
  source: 'Source',
  sensorUnable: 'Capteur incapable de déterminer la quantité restante.',
  PROBE: 'Sonde',
  DRIVER: 'Chauffeur',
  DRS: 'Drs',
  waterReleaseFormEnabled: "Activer le formulaire de libération de l'eau",
  waterRelease: "Libération de l'eau",
  waterReleaseAcknowledged: `Rejet d'eau reconnu`,
  'assessment-risk': "L'évaluation des risques",
  'setup_assessment-risk': "L'évaluation des risques",
  'add-assessment-risk': 'Ajouter une évaluation des risques',
  enterRecipientsButton: 'Entrez le(s) destinataire(s)',
  recipientsInstructions: `Entrez l'e-mail, séparé par une virgule`,
  enter: 'Entrer',
  invalidEmailAddress: 'Adresse e-mail invalide',
  personalDataPolicy:
    'En saisissant des données personnelles dans ce formulaire, vous affirmez que vous disposez des autorisations nécessaires pour partager ces données personnelles.',
  subcategory: 'Sous-catégorie',
  isRequired: 'Est requis',
  riskAssessmentEnabled: "Activer l'évaluation des risques",
  riskAssessment: "L'évaluation des risques",
  yes: 'Oui',
  no: 'Non',
  sourceOptionsSensors: 'Options de source (capteurs)',
  sourceDcm: 'Dcm',
  sourceProbe: 'Sonde',
  availableCustomValues: 'Valeurs personnalisées disponibles',
  ERR_CODE__DUPLICATE: 'Dupliquer',
  ERR_CODE__DEFAULT_VALUE: 'Il existe déjà une valeur par défaut',
  ERR_CODE__EXISTING_FORM: 'Il existe déjà un formulaire actif pour ce véhicule et ce type',
  availableValues: 'Valeurs disponibles',
  pump: 'Pompe',
  DanskName: 'Nom dans le pavage',
  GermanName: 'Nom allemand',
  EnglishName: 'Nom anglais',
  BritainName: 'Nom de Grande-Bretagne',
  SpanishName: 'Nom espagnol',
  FrenchName: 'Nom français',
  NederlandsName: 'Nom de Nederlands',
  NorskName: 'Nom de Norsk',
  SvenskaName: 'Nom de Svenska',
  ItalianName: 'Nom de italien',
  PolishName: 'Nom polonais',
  DanskNameDefault: 'Nom dans le dansk (par défaut)',
  GermanNameDefault: 'Nom allemand (par défaut)',
  EnglishNameDefault: 'Nom anglais (par défaut)',
  BritainNameDefault: 'Nom britannique (par défaut)',
  SpanishNameDefault: 'Nom espagnol (par défaut)',
  FrenchNameDefault: 'Nom français (par défaut)',
  NederlandsNameDefault: 'Nom de Nederlands (par défaut)',
  NorskNameDefault: 'Nom de Norsk (par défaut)',
  SvenskaNameDefault: 'Nom de Svenska (par défaut)',
  ItalianNameDefault: 'Nom de italien (par défaut)',
  PolishNameDefault: 'Nom polonais (par défaut)',
  DanskDescription: 'Description du dansk',
  GermanDescription: 'Description allemande',
  EnglishDescription: 'Description anglaise',
  BritainDescription: 'Description de la Grande-Bretagne',
  SpanishDescription: 'Description espagnole',
  FrenchDescription: 'Description française',
  NederlandsDescription: 'Description de Nederlands',
  NorskDescription: 'Description de Norsk',
  SvenskaDescription: 'Description de Svenska',
  ItalianDescription: 'Description de italien',
  PolishDescription: 'Description du polonais',
  DanskDescriptionDefault: 'Description dans le dansk (par défaut)',
  GermanDescriptionDefault: 'Description allemande (par défaut)',
  EnglishDescriptionDefault: 'Description anglaise (par défaut)',
  BritainDescriptionDefault: 'Description britannique (par défaut)',
  SpanishDescriptionDefault: 'Description espagnole (par défaut)',
  FrenchDescriptionDefault: 'Description française (par défaut)',
  NederlandsDescriptionDefault: 'Description de Nederlands (par défaut)',
  NorskDescriptionDefault: 'Description de Norsk (par défaut)',
  SvenskaDescriptionDefault: 'Description de Svenska (par défaut)',
  ItalianDescriptionDefault: 'Description de italien (par défaut)',
  PolishDescriptionDefault: 'Description du polonais (par défaut)',
  DanskContent: 'Pavé',
  GermanContent: 'Contenu allemand',
  EnglishContent: 'Contenu anglais',
  BritainContent: 'Contenu en Grande-Bretagne',
  SpanishContent: 'Contenu espagnol',
  FrenchContent: 'Contenu français',
  NederlandsContent: 'Contenu de Nederlands',
  NorskContent: 'Contenu de Norsk',
  SvenskaContent: 'Contenu Svenska',
  ItalianContent: 'Contenu italien',
  PolishContent: 'Contenu polonais',
  DanskContentDefault: 'Dansk Contenu (par défaut)',
  GermanContentDefault: 'Contenu allemand (par défaut)',
  EnglishContentDefault: 'Contenu anglais (par défaut)',
  BritainContentDefault: 'Contenu britannique (par défaut)',
  SpanishContentDefault: 'Contenu espagnol (par défaut)',
  FrenchContentDefault: 'Contenu français (par défaut)',
  NederlandsContentDefault: 'Contenu de Nederlands (par défaut)',
  NorskContentDefault: 'Contenu Norsk (par défaut)',
  SvenskaContentDefault: 'Contenu Svenska (par défaut)',
  ItalianContentDefault: 'Contenu italien (par défaut)',
  PolishContentDefault: 'Contenu polonais (par défaut)',
  DanskCategory: 'Catégorie de dansk',
  GermanCategory: 'Catégorie allemande',
  EnglishCategory: 'Catégorie anglaise',
  BritainCategory: 'Catégorie de la Grande-Bretagne',
  SpanishCategory: 'Catégorie espagnole',
  FrenchCategory: 'Catégorie française',
  NederlandsCategory: 'Catégorie de Nederlands',
  NorskCategory: 'Catégorie Norsk',
  SvenskaCategory: 'Catégorie Svenska',
  ItalianCategory: 'Catégorie italien',
  PolishCategory: 'Catégorie polonaise',
  DanskCategoryDefault: 'Catégorie de dansk (par défaut)',
  GermanCategoryDefault: 'Catégorie allemande (par défaut)',
  EnglishCategoryDefault: "Catégorie d'anglais (par défaut)",
  BritainCategoryDefault: 'Catégorie britannique (par défaut)',
  SpanishCategoryDefault: 'Catégorie espagnole (par défaut)',
  FrenchCategoryDefault: 'Catégorie française (par défaut)',
  NederlandsCategoryDefault: 'Catégorie de Nederlands (par défaut)',
  NorskCategoryDefault: 'Catégorie Norsk (par défaut)',
  SvenskaCategoryDefault: 'Catégorie Svenska (par défaut)',
  ItalianCategoryDefault: 'Catégorie italien (par défaut)',
  PolishCategoryDefault: 'Catégorie polonaise (par défaut)',
  DanskSubcategory: 'Sous-catégorie de dansk',
  GermanSubcategory: 'Sous-catégorie allemande',
  EnglishSubcategory: 'Sous-catégorie anglaise',
  BritainSubcategory: 'Sous-catégorie britannique',
  SpanishSubcategory: 'Sous-catégorie espagnole',
  FrenchSubcategory: 'Sous-catégorie française',
  NederlandsSubcategory: 'Sous-catégorie de Nederlands',
  NorskSubcategory: 'Sous-catégorie Norsk',
  SvenskaSubcategory: 'Sous-catégorie Svenska',
  ItalianSubcategory: 'Sous-catégorie italien',
  PolishSubcategory: 'Sous-catégorie polonaise',
  DanskSubcategoryDefault: 'Sous-catégorie dans le Dansk (par défaut)',
  GermanSubcategoryDefault: 'Sous-catégorie allemande (par défaut)',
  EnglishSubcategoryDefault: 'Sous-catégorie anglaise (par défaut)',
  BritainSubcategoryDefault: 'Sous-catégorie britannique (par défaut)',
  SpanishSubcategoryDefault: 'Sous-catégorie espagnole (par défaut)',
  FrenchSubcategoryDefault: 'Sous-catégorie française (par défaut)',
  NederlandsSubcategoryDefault: 'Sous-catégorie de Nederlands (par défaut)',
  NorskSubcategoryDefault: 'Sous-catégorie Norsk (par défaut)',
  SvenskaSubcategoryDefault: 'Sous-catégorie Svenska (par défaut)',
  ItalianSubcategoryDefault: 'Sous-catégorie italien (par défaut)',
  PolishSubcategoryDefault: 'Sous-catégorie polonaise (par défaut)',
  priorSignatures: 'Signatures préalables',
  damageWaiverSignature: 'Signature de renonciation aux dommages',
  waterReleaseSignature: "Signature de libération d'eau",
  errors,
  themes,
  lastUpdate: 'Dernière mise à jour',
  isPDFRecipientListEnable: "Liste des destinataires PDF activée sur l'écran de signature du client",
  isRejectLoadsEnable: `Activer le rejet des chargements après l'arrivée sur le site`,
  rejectLoad_returnedMaterial: 'Bouton de flux de travail pour les matériaux retournés',
  rejectLoad_returnedAndFinalize: 'Boutons de flux de travail pour le matériel retourné et la finalisation',
  driverRejection: 'Rejeter la charge',
  submitRejection: 'Soumettre Rejeter',

  costAndPaymentsSummary: 'Résumé des coûts et des paiements',
  subtotal: 'Total',
  tax: 'Impôt',
  remainingBalance: 'Solde restant',
  paymentAmount: 'Montant du paiement',
  total: 'Total',
  paymentMethod: 'Mode de paiement',
  paymentProducts: 'Des produits',
  unitPrice: 'Prix ​​unitaire',
  amount: 'Montant',
  otherPaymentMethod: 'Autre méthode de paiement',
  method: 'Méthode',
  paymentOptions: 'Options de paiement',
  typeAdditionalInfo: 'Tapez des informations supplémentaires ici',
  COD: 'Paiement à la livraison',
  AMEX: 'American Express',
  CASH: 'Espèces',
  CHECK: 'Vérifier',
  'CREDIT CARD': 'Carte de crédit',
  ACCOUNT: 'Compte ouvert',
  UNKNOWN: 'Inconnu',
  remainingMaterial: 'Matériel restant',
  splitLoad: 'Chargement partagé',
  isEnablePaymentAndCostWorkflows: 'Activer les flux de travail de paiement et de coût',

  // DOT Workflow
  dotWorkflow: 'Détails du lot',
  inspectorSignature: "Signature de l'inspecteur",
  producerSignature: 'Signature du producteur',
  addedItems: 'Articles ajoutés',
  producerSignatureContact: 'Nom du producteur',
  inspectorSignatureContact: "Nom de l'inspecteur",

  airTempValue: "Température de l'Air",
  concreteTempValue: 'Température du Béton',
  cylinderNumber: 'Numéro de Cylindre',
  materialOnTruck: 'Matériel sur le camion',
  waterLocation: "Emplacement de l'eau",
  ingredient: 'Ingrédient',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Humidité Libre',
  cydsTarg: 'CYDS Cible',
  target: 'Cible',
  actual: 'Réel',
  freWat: 'Eau Libre',
  trim: 'Couper',
  batchInformation: 'Informations du Lot',
  numberOfBatches: 'Nombre de Lots',
  totalWater: 'Eau Totale',
  designWater: 'Eau de Conception',
  toAdd: 'À Ajouter',
  designWC: 'Conception W/C',
  waterCement: 'Eau / Ciment',
  waterInTruck: 'Eau dans le Camion',
  adjustWater: "Ajuster l'Eau",
  trimWater: "Couper l'Eau",
  plantWeather: "Temps à l'Usine",
  rh: 'RH',
  observationTime: "Temps d'Observation",
  address: 'Adresse',
  plant: 'Usine',
  sp: 'S.P. #',
  beginTime: 'Heure de Début',
  mixDesc: 'Description du Mélange',
  qtyOder: 'Quantité Commandée',
  poTag: 'PO #',
  bridge: 'Pont #',
  volume: 'Volume',
  truckTag: 'Camion #',
  qtyDelivered: 'Quantité Livrée',
  qtyOrder: 'Quantité Commandée',
  job: 'Travail #',
  batchRecord: 'Enregistrement du Lot',
  mixID: 'ID de Mélange',
  loads: 'Chargements',
  isDot: 'Est DOT',
  tolStatus: 'Statut TOL',
  driverClickedFinalize: 'Le chauffeur a cliqué sur Finaliser',
  autoSubmit: 'Soumettre automatiquement',
  noTaxInformationIsAvailable: 'Aucune information fiscale disponible',
  retryPullTaxInformation: 'Actualiser les données fiscales',
  var: '% Var',
  designQTY: 'Quantité de conception',
  required: 'Requis',
  batched: 'Par lots',
  moisture: 'Humidité',
  actualWater: 'Eau réelle',
  moisturePercent: '% Humidité',
  freeWater: 'Eau gratuite',
  reset: 'Réinitialiser',
  'at-plant': "À l'usine",
  'at-job': 'Au travail',
};
