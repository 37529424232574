import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Мобільний квиток',
  ...features,
  save: 'Зберегти',
  username: "Ім'я користувача",
  password: 'Пароль',
  login: 'Вхід',
  required: 'Потрібно',
  light: 'Світло',
  dark: 'Темний',
  rememberMe: "Запам'ятати мене",
  eula: 'EULA',
  copyright: '© {рік} - команда Alkon, Inc.',
  privacyPolicy: 'Політика конфіденційності',
  customer: 'Клієнт',
  project: 'Демонструвати',
  isBillable: 'Оплачуваний',
  dispatchDateTime: 'Дата',
  orderId: 'Замовлення',
  ticketId: 'Квиток',
  location: 'Розташування',
  vehicleTypeName: 'Транспортний засіб',
  product: 'Продукт',
  products: 'Продукт',
  salesTax: 'Податок з продажу',
  totalPrice: 'Усього',
  extendedPrice: 'Ext',
  destination: 'Пункт призначення',
  productId: 'Ідентифікатор продукту',
  statusCode: 'Статус',
  vehicleTypeId: 'Ідентифікатор типу автомобіля',
  locationId: 'Ідентифікатор місцезнаходження',
  customerId: 'Ідентифікатор клієнта',
  projectId: 'Ідентифікатор проекту',
  dispatchTime: 'Час',
  showAll: 'Показати все',
  hideAll: 'Приховати все',
  undoChanges: 'Скасувати зміни',
  resetColumns: 'Скинути стовпці',
  displayRowCount: `Displaying {count, plural, 
    =0 {0 рядків}
    one {# ряд}
    other {# рядків}
  }`,
  columnFilters: 'Фільтри стовпців',
  visible: 'Видимо',
  hidden: 'Прихований',
  itemDetail: 'Детальна інформація',
  quantity: 'Кількість',
  quantityMaxWaterToAdd: 'Кількість (макс. вода для додавання: {maxWaterQuantity})',
  unloadingMethod: 'Метод розвантаження',
  time: 'Час',
  eventStatus: 'Статус',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'ea',
  LTR_abbr: 'L',
  YDQ_abbr: 'y³',
  GLL_abbr: 'gal',
  MTQ_text: 'Кубічні метри',
  M3_text: 'Кубічні метри',
  EA_text: 'Кожен',
  LTR_text: 'Літри',
  YDQ_text: 'Кубічні ярди',
  GLL_text: 'Галони',
  ACR_text: 'Акри',
  HUR_text: 'Година',
  INH_text: 'Дюйми',
  FOT_text: 'Нога',
  YRD_text: 'Ярд',
  MMT_text: 'Міліметр',
  CMT_text: 'Сантиметр',
  MTR_text: 'Лічильник',
  SMI_text: 'Миля (статутна миля)',
  KMT_text: 'Кілометр',
  INK_text: 'Квадратний дюйм',
  FTK_text: 'Квадратний фут',
  YDK_text: 'Квадратний двір',
  MIK_text: 'Квадратна миля',
  CMK_text: 'Квадратний сантиметр',
  MTK_text: 'Квадратний метр',
  KMK_text: 'Квадратний кілометр',
  INQ_text: 'Кубічний дюйм',
  MMQ_text: 'Кубічний міліметр',
  FTQ_text: 'Кубічний фут',
  CMQ_text: 'Кубічний сантиметр',
  OZA_text: 'рідка унція',
  PT_text: 'Пінта',
  QT_text: 'кварта',
  MLT_text: 'Мілілітр',
  ONZ_text: 'Унція',
  LBR_text: 'Фунт',
  STN_text: 'Тонна',
  GRM_text: 'Грам',
  KGM_text: 'Кілограм',
  TNE_text: 'Тонна',
  PS_text: 'Фунтів на квадратний дюйм',
  MPA_text: 'Мегапаскаль',
  C56_text: 'Ньютон на квадратний міліметр',
  C62_text: 'Один',
  NL_text: 'Завантажити',
  MT_text: 'Килимок',
  P1_text: 'Відсоток',
  CEL_text: 'градус Цельсія',
  FAH_text: 'градус Фаренгейта',
  BLL_text: 'Бочка',
  CLT_text: 'Сантилітр',
  DLT_text: 'Децилітр',
  GLI_text: 'імперський галон',
  '2U_text': 'Мегаграм',
  SA_text: 'Мішок',
  '60_text': 'Вага у відсотках',
  BG_text: 'Сумка',
  SEC_text: 'Другий',
  MIN_text: 'Хвилина',
  '64_text': 'Фунт на квадратний дюйм - Калібр',
  SP_text: 'Пакет на полиці',
  MMK_text: 'Квадратний міліметр',
  MTN_text: 'метрична тонна',
  GT_text: 'Гігатон',
  '/NL_text': 'За завантаження',
  '/EA_text': 'За кількість',
  '/H/ + h_text': 'За годину',
  '/YQ/ + YDQ_text': 'За кубічний ярд',
  '/MQ/ + MTQ_text': 'За кубічний метр',
  '/MN/ + MIN_text': 'За хвилину',
  '/MT_text': 'На метр',
  DSG_text: 'Дозування',
  '/C_text': 'На 100 ваги',
  '64_2_text': 'Конкретний',
  MTQ: `{value, plural,
    =0 {0 м\u00B3}
    one {1 м\u00B3}
    other {# м\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 м\u00B3}
    one {1 м\u00B3}
    other {# м\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 літрів}
    one {1 літр}
    other {# літрів}
  }`,
  TO: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
  }`,
  ITEM: `{value, plural,
    =0 {0 елементів}
    one {1 товар}
    other {# предметів}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 р³}
    one {1 рік³}
    other {# рік³}
  }`,
  YDQ: `{value, plural,
    =0 {0 кубічних ярдів}
    one {1 кубічний ярд}
    other {# кубічних ярдів}
  }`,
  GLL: `{value, plural,
    =0 {0 галонів}
    one {1 галон}
    other {# галонів}
  }`,
  EA: `{value, plural,
    =0 {0 кожен}
    one {1 кожен}
    other {# Кожен}
  }`,
  MIN: `{value, plural,
    =0 {0 хвилин}
    one {1 хвилина}
    other {# хвилин}
  }`,
  ACR: `{value, plural,
    =0 {0 акрів}
    one {1 акр}
    other {# акрів}
  }`,
  HUR: `{value, plural,
    =0 {0 років}
    one {1 годину}
    other {# років}
  }`,
  INH: `{value, plural,
    =0 {0 дюймів}
    one {1 дюйм}
    other {# дюймів}
  }`,
  FOT: `{value, plural,
    =0 {0 футів}
    one {1 фут}
    other {# футів}
  }`,
  YRD: `{value, plural,
    =0 {0 ярдів}
    one {1 ярд}
    other {# ярдів}
  }`,
  MMT: `{value, plural,
    =0 {0 міліметрів}
    one {1 міліметр}
    other {# міліметрів}
  }`,
  CMT: `{value, plural,
    =0 {0 сантиметрів}
    one {1 сантиметр}
    other {# сантиметрів}
  }`,
  MTR: `{value, plural,
    =0 {0 метрів}
    one {1 метр}
    other {# метрів}
  }`,
  SMI: `{value, plural,
    =0 {0 миль (статутних миль)}
    one {1 миля (статутна миля)}
    other {# миль (статутних миль)}
  }`,
  KMT: `{value, plural,
    =0 {0 кілометрів}
    one {1 кілометр}
    other {# кілометрів}
  }`,
  INK: `{value, plural,
    =0 {0 квадратних дюймів}
    one {1 квадратний дюйм}
    other {# квадратних дюймів}
  }`,
  FTK: `{value, plural,
    =0 {0 квадратних футів}
    one {1 квадратний фут}
    other {# квадратних футів}
  }`,
  YDK: `{value, plural,
    =0 {0 квадратних ярдів}
    one {1 квадратний ярд}
    other {# квадратних ярдів}
  }`,
  MIK: `{value, plural,
    =0 {0 квадратних миль}
    one {1 квадратна миля}
    other {# квадратних миль}
  }`,
  CMK: `{value, plural,
    =0 {0 квадратних сантиметрів}
    one {1 квадратний сантиметр}
    other {# квадратних сантиметрів}
  }`,
  MTK: `{value, plural,
    =0 {0 квадратних метрів}
    one {1 квадратний метр}
    other {# квадратних метрів}
  }`,
  KMK: `{value, plural,
    =0 {0 квадратних кілометрів}
    one {1 квадратний кілометр}
    other {# квадратних кілометрів}
  }`,
  INQ: `{value, plural,
    =0 {0 кубічних дюймів}
    one {1 кубічний дюйм}
    other {# кубічних дюймів}
  }`,
  MMQ: `{value, plural,
    =0 {0 кубічних міліметрів}
    one {1 кубічний міліметр}
    other {# кубічних міліметрів}
  }`,
  FTQ: `{value, plural,
    =0 {0 кубічних футів}
    one {1 кубічний фут}
    other {# кубічних футів}
  }`,
  CMQ: `{value, plural,
    =0 {0 кубічних сантиметрів}
    one {1 кубічний сантиметр}
    other {# кубічних сантиметрів}
  }`,
  OZA: `{value, plural,
    =0 {0 рідких унцій}
    one {1 рідка унція}
    other {# рідких унцій}
  }`,
  PT: `{value, plural,
    =0 0 пінт}
    one {1 пінта}
    other {# пінти}
  }`,
  QT: `{value, plural,
    =0 {0 кварт}
    one {1 кварта}
    other {# кварти}
  }`,
  MLT: `{value, plural,
    =0 {0 мілілітрів}
    one {1 мілілітр}
    other {# мілілітрів}
  }`,
  ONZ: `{value, plural,
    =0 {0 унцій}
    one {1 унція}
    other {# унцій}
  }`,
  LBR: `{value, plural,
    =0 {0 фунтів}
    one {1 фунт}
    other {# фунтів}
  }`,
  STN: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
  }`,
  GRM: `{value, plural,
    =0 {0 грам}
    one {1 грам}
    other {# грам}
  }`,
  KGM: `{value, plural,
    =0 {0 кілограмів}
    one {1 кілограм}
    other {# кілограм}
  }`,
  TNE: `{value, plural,
    =0 {0 тонн}
    one {1 тонна}
    other {# тонн}
  }`,
  PS: `{value, plural,
    =0 {0 psi}
    one {1 psi}
    other {# psi}
  }`,
  MPA: `{value, plural,
    =0 {0 мегапаскалей}
    one {1 мегапаскаль}
    other {# мегапаскаль}
  }`,
  C56: `{value, plural,
    =0 {0 Ньютони на квадратний міліметр}
    one {1 Ньютон на квадратний міліметр}
    other {# Ньютони на квадратний міліметр}
  }`,
  C62: `{value, plural,
    =0 {0 один}
    one {1 Один}
    other {# Один}
  }`,
  NL: `{value, plural,
    =0 {0 завантажень}
    one {1 Завантажити}
    other {# завантажень}
  }`,
  MT: `{value, plural,
    =0 {0 Матс}
    one {1 Мат}
    other {# Матс}
  }`,
  P1: `{value, plural,
    =0 {0%}
    one {1 відсоток}
    other {# відсотків}
  }`,
  CEL: `{value, plural,
    =0 {0 градусів Цельсія}
    one {1 градус Цельсія}
    other {# градусів Цельсія}
  }`,
  FAH: `{value, plural,
    =0 {0 градусів за Фаренгейтом}
    one {1 градус Фаренгейта}
    other {# градусів за Фаренгейтом}
  }`,
  BLL: `{value, plural,
    =0 {0 барелів}
    one {1 барель}
    other {# барелів}
  }`,
  CLT: `{value, plural,
    =0 {0 сантилітрів}
    one {1 сантилітр}
    other {# сантилітри}
  }`,
  DLT: `{value, plural,
    =0 {0 децилітрів}
    one {1 децилітр}
    other {# децилітрів}
  }`,
  GLI: `{value, plural,
    =0 {0 галонів}
    one {1 галон}
    other {# галонів}
  }`,
  '2U': `{value, plural,
    =0 {0 мегаграм}
    one {1 мегаграм}
    other {# мегаграм}
  }`,
  SA: `{value, plural,
    =0 {0 мішків}
    one {1 мішок}
    other {# мішків}
  }`,
  60: `{value, plural,
    =0 {0 Відсоток ваги}
    one {1 Відсоток ваги}
    other {# Відсоток ваги}
  }`,
  BG: `{value, plural,
    =0 {0 Сумки}
    one {1 Сумка}
    other {# Сумки}
  }`,
  SEC: `{value, plural,
    =0 {0 секунд}
    one {1 секунда}
    other {# секунд}
  }`,
  64: `{value, plural,
    =0 {0 Фунти на квадратний дюйм - Калібр}
    one {1 Фунт на квадратний дюйм - Калібр}
    other {# Фунти на квадратний дюйм - Калібр}
  }`,
  SP: `{value, plural,
    =0 {0 полічні пакети}
    one {1 Поличний пакет}
    other {# полічні пакети}
  }`,
  MMK: `{value, plural,
    =0 {0 квадратних міліметрів}
    one {1 квадратний міліметр}
    other {# квадратних міліметрів}
  }`,
  MTN: `{value, plural,
    =0 {0 метричних тонн}
    one {1 метрична тонна}
    other {# метричних тонн}
  }`,
  GT: `{value, plural,
    =0 {0 Гігатонн}
    one {1 гігатонна}
    other {# Гігатонн}
  }`,
  '/NL': `{value, plural,
    =0 {0 за завантаження}
    one {1 за завантаження}
    other {# на завантаження}
  }`,
  '/EA': `{value, plural,
    =0 {0 за кількість}
    one {1 за кількість}
    other {# на кількість}
  }`,
  '/H/ + h': `{value, plural,
    =0 {0 за годину}
    one {1 за годину}
    other {# за годину}
  }`,
  '/YQ/ + YDQ': `{value, plural,
    =0 {0 за кубічний ярд}
    one {1 за кубічний ярд}
    other {# на кубічний ярд}
  }`,
  '/MQ/ + MTQ': `{value, plural,
    =0 {0 за кубічний метр}
    one {1 за кубічний метр}
    other {# на кубічний метр}
  }`,
  '/MN/ + MIN': `{value, plural,
    =0 {0 за хвилину}
    one {1 за хвилину}
    other {# за хвилину}
  }`,
  '/MT': `{value, plural,
    =0 {0 за метр}
    one {1 за метр}
    other {# на метр}
  }`,
  DSG: `{value, plural,
    =0 {0 дозування}
    one {1 Дозування}
    other {# Дозування}
  }`,
  '/C': `{value, plural,
    =0 {0 на 100 ваги}
    one {1 на 100 ваги}
    other {# на 100 ваги}
  }`,
  '64_2': `{value, plural,
    =0 {0 конкретний}
    one {1 конкретний}
    other {# конкретний}
  }`,
  LTR_short: `{value, plural,
  =0 {0 л}
  one {1 л}
  other {# л}
  }`,
  TO_short: `{value, plural,
  =0 {0 тонн}
  one {1 тонна}
  other {# тонна}
}`,
  ITEM_short: `{value, plural,
  =0 {0 елементів}
  one {1 товар}
  other {# предметів}
}`,
  GLL_short: `{value, plural,
  =0 {0 галон}
  one {1 галон}
  other {# галон}
}`,
  EA_short: `{value, plural,
  =0 {0 шт.}
  one {1 шт.}
  other {# шт.}
}`,
  MIN_short: `{value, plural,
  =0 {0 хв}
  one {1 хв}
  other {# хв}
}`,
  ACR_short: `{value, plural,
  =0 {0 ac}
  one {1 ac}
  other {# ac}
}`,
  HUR_short: `{value, plural,
  =0 {0 год}
  one {1 година}
  other {# год}
}`,
  INH_short: `{value, plural,
  =0 {0 дюймів}
  one {1 дюйм}
  other {# дюйм}
}`,
  FOT_short: `{value, plural,
  =0 {0 футів}
  one {1 фут}
  other {# футів}
}`,
  YRD_short: `{value, plural,
  =0 {0 ярдів}
  one {1 ярд}
  other {# ярд}
}`,
  MMT_short: `{value, plural,
  =0 {0 мм}
  one {1 мм}
  other {# мм}
}`,
  CMT_short: `{value, plural,
  =0 {0 см}
  one {1 см}
  other {# см}
}`,
  MTR_short: `{value, plural,
  =0 {0 хв}
  one {1 м}
  other {# хв}
}`,
  SMI_short: `{value, plural,
  =0 {0 миль (ст миль)}
  one {1 миля ((ст милі)}
  other {# милі ((ст милі)}
}`,
  KMT_short: `{value, plural,
  =0 {0 км}
  one {1 км}
  other {# км}
}`,
  INK_short: `{value, plural,
  =0 {0 дюймів²}
  one {1 дюйм²}
  other {# дюйм²}
}`,
  FTK_short: `{value, plural,
  =0 {0 футів²}
  one {1 фут²}
  other {# фут²}
}`,
  YDK_short: `{value, plural,
  =0 {0 ярдів²}
  one {1 ярд²}
  other {# ярд²}
}`,
  MIK_short: `{value, plural,
  =0 {0 ми²}
  one {1 миль²}
  other {# миль²}
}`,
  CMK_short: `{value, plural,
  =0 {0 см²}
  one {1 см²}
  other {# см²}
}`,
  MTK_short: `{value, plural,
  =0 {0 м²}
  one {1 м²}
  other {# м²}
}`,
  KMK_short: `{value, plural,
  =0 {0 км²}
  one {1 км²}
  other {# км²}
}`,
  INQ_short: `{value, plural,
  =0 {0 дюймів³}
  one {1 дюйм³}
  other {# дюйм³}
}`,
  MMQ_short: `{value, plural,
  =0 0 мм³}
  one {1 мм³}
  other {# мм³}
}`,
  FTQ_short: `{value, plural,
  =0 {0 футів³}
  one {1 фут³}
  other {# футів³}
}`,
  CMQ_short: `{value, plural,
  =0 {0 см³}
  one {1 см³}
  other {# см³}
}`,
  OZA_short: `{value, plural,
  =0 {0 рідких унцій}
  one {1 рідка унція}
  other {# рідких унцій}
}`,
  PT_short: `{value, plural,
  =0 {0 пт}
  one {1 пт}
  other {# пт}
}`,
  QT_short: `{value, plural,
  =0 {0 кварт}
  one {1 кварта}
  other {# кварта}
}`,
  MLT_short: `{value, plural,
  =0 {0 мл}
  one {1 мл}
  other {# мл}
}`,
  ONZ_short: `{value, plural,
  =0 {0 унцій}
  one {1 унція}
  other {# унцій}
}`,
  LBR_short: `{value, plural,
  =0 {0 фунтів}
  one {1 фунт}
  other {# фунт}
}`,
  STN_short: `{value, plural,
  =0 {0 тонн}
  one {1 тонна}
  other {# тонна}
}`,
  GRM_short: `{value, plural,
  =0 {0 г}
  one {1 г}
  other {# г}
}`,
  KGM_short: `{value, plural,
  =0 {0 кг}
  one {1 кг}
  other {# кг}
}`,
  TNE_short: `{value, plural,
  =0 {0 т}
  one {1 т}
  other {# т}
}`,
  PS_short: `{value, plural,
  =0 {0 psi}
  one {1 psi}
  other {# psi}
}`,
  MPA_short: `{value, plural,
  =0 {0 МПа}
  one {1 МПа}
  other {# МПа}
}`,
  C56_short: `{value, plural,
  =0 {0 Ньютони на квадратний міліметр}
  one {1 Ньютон на квадратний міліметр}
  other {# Ньютони на квадратний міліметр}
}`,
  C62_short: `{value, plural,
  =0 {0 один}
  one {1 Один}
  other {# Один}
}`,
  NL_short: `{value, plural,
  =0 {0 завантажень}
  one {1 Завантажити}
  other {# завантажень}
}`,
  MT_short: `{value, plural,
  =0 {0 Матс}
  one {1 Мат}
  other {# Матс}
}`,
  P1_short: `{value, plural,
  =0 {0 %}
  one {1 %}
  other {# %}
}`,
  CEL_short: `{value, plural,
  =0 {0 c}
  one {1 c}
  other {# c}
}`,
  FAH_short: `{value, plural,
  =0 {0 f}
  one {1 f}
  other {# f}
}`,
  BLL_short: `{value, plural,
  =0 0 барелів}
  one {1 барель}
  other {# барель}
}`,
  CLT_short: `{value, plural,
  =0 {0 cL}
  one {1 cL}
  other {# cL}
}`,
  DLT_short: `{value, plural,
  =0 {0 дл}
  one {1 дл}
  other {# дл}
}`,
  GLI_short: `{value, plural,
  =0 {0 галон}
  one {1 галон}
  other {# галон}
}`,
  '2U_short': `{value, plural,
  =0 {0 мегаграм}
  one {1 мегаграм}
  other {# мегаграм}
}`,
  SA_short: `{value, plural,
  =0 {0 мішків}
  one {1 мішок}
  other {# мішків}
}`,
  '60_short': `{value, plural,
  =0 {0% ваги}
  one {1% ваги}
  other {# % ваги}
}`,
  BG_short: `{value, plural,
  =0 {0 Сумки}
  one {1 Сумка}
  other {# Сумки}
}`,
  SEC_short: `{value, plural,
  =0 {0 сек}
  one {1 секунда}
  other {# сек}
}`,
  '64_short': `{value, plural,
  =0 {0 Фунти на квадратний дюйм - Калібр}
  one {1 Фунт на квадратний дюйм - Калібр}
  other {# Фунти на квадратний дюйм - Калібр}
}`,
  SP_short: `{value, plural,
  =0 {0 полічні пакети}
  one {1 Поличний пакет}
  other {# полічні пакети}
}`,
  MMK_short: `{value, plural,
  =0 {0 Квадратні міліметри}
  one {1 Квадратний міліметр}
  other {# Квадратні міліметри}
}`,
  MTN_short: `{value, plural,
  =0 {0 тонн}
  one {1 тонн}
  other {# тонн}
}`,
  GT_short: `{value, plural,
  =0 {0 gt}
  one {1 gt}
  other {# gt}
}`,
  '/NL_short': `{value, plural,
  =0 {0 /навантаження}
  one {1 /завантаження}
  other {# /навантаження}
}`,
  '/EA_short': `{value, plural,
  =0 {0 / шт.}
  one {1 / шт.}
  other {# /шт}
}`,
  '/H/ + h_short': `{value, plural,
  =0 {0 /годину}
  one {1 /годину}
  other {# /годину}
}`,
  '/YQ/ + YDQ_short': `{value, plural,
  =0 {0/рік³}
  one {1 /рік³}
  other {# /y³}
}`,
  '/MQ/ + MTQ_short': `{value, plural,
  =0 0 /м³}
  one {1/м³}
  other {# /м³}
}`,
  '/MN/ + MIN_short': `{value, plural,
  =0 {0/хв}
  one {1/хв}
  other {# /хв}
}`,
  '/MT_short': `{value, plural,
  =0 {0/м}
  one {1/м}
  other {# /м}
}`,
  DSG_short: `{value, plural,
  =0 {0 дозування}
  one {1 Дозування}
  other {# Дозування}
}`,
  '/C_short': `{value, plural,
  =0 {0/c}
  one {1/c}
  other {# /c}
}`,
  '64_2_short': `{value, plural,
  =0 {0 Специфічний}
  one {1 Специфічний}
  other {# Специфічний}
}`,
  mixIdLabel: 'Ідентифікатор суміші/Опис',
  productIdLabel: 'Ідентифікатор продукту/опис',
  orderedQtyLabel: 'Замовлена ​​кількість',
  loadedQtyLabel: 'Завантажена кількість',
  addedByCustomer: 'Додано клієнтом',
  PRINTED: 'Квиток',
  LOADING_STARTED: 'Почати завантаження',
  LOADING_COMPLETE: 'Завершити завантаження',
  TO_JOB: 'Лівий завод',
  ARRIVE_JOB: 'Сайт прибуття',
  UNLOADING: 'Почати вивантаження',
  END_UNLOADING: 'Завершити вивантаження',
  LEAVE_JOB: 'Залишити роботу',
  IN_YARD: 'У дворі',
  PUMPING_STARTED: 'Запустити насос',
  PUMPING_COMPLETE: 'Фінішний насос',
  READY_TO_PUMP: 'Готовий до закачування',
  FIRST_WCCONTACT: 'Перший водоцементний контакт',
  plantName: 'Назва рослини',
  plantAddress: 'Адреса підприємства',
  loadingScheduleStart: 'Запланований час завантаження',
  deliveryAddress: 'Адреса доставки',
  phoneNumber: 'Номер телефону',
  unloadingScheduleStart: 'Запланований час розвантаження (ETA)',
  agreedUnloading: 'Замовлений час розвантаження',
  deliveryInformation: 'Інформація про доставку',
  next: 'Далі',
  material: 'Матеріал',
  loadingPoint: 'Точка завантаження',
  unloadingPoint: 'Точка розвантаження',
  comment: 'Коментар',
  sapDeliveryNumber: 'Номер доставки SAP (номер BCC)',
  salesOrderNumber: 'Номер замовлення на продаж і рядок позиції',
  wcDateTime: 'Дата й час W/C контакт',
  po: 'Номер замовлення на замовлення',
  orderedByName: 'Замовлення розміщено за назвою',
  orderedByPhone: 'Замовлення за номером телефону',
  resourceId: 'Ідентифікатор ресурсу',
  totalLoadedQty: 'Загальна кількість завантажень, включаючи це завантаження',
  spacing: 'Простір для завантаження',
  deliveryFlow: 'Потік доставки',
  timeOnSite: 'Загальний час на сайті/час розвантаження замовлення',
  salesText: 'Продажний текст (ідентифікатор матеріалу)',
  strengthClass: 'Клас міцності',
  environmentExposure: 'Вплив навколишнього середовища',
  metExposureClasses: 'Мет класи впливу',
  dmax: 'Dmax',
  standardCert: 'Стандарт (сертифікація)',
  workabilityTarget: 'Мета працездатності клієнта',
  stoneQuality: 'Якість каменю',
  sandQuality: 'Якість піску',
  targetWC: 'Цільове співвідношення W/C з рецепту',
  controlClass: 'Клас керування',
  chlorideClass: 'Клас хлоридів',
  typeAndStrength1: 'Тип цементу та клас міцності 1',
  typeAndStrength2: 'Тип цементу та клас міцності 2',
  typeOfAddition: 'Тип додавання',
  typeOfAdmixture: 'Тип домішки',
  orderedTemp: 'Замовлена ​​температура',
  fibers1: 'Тип і вміст волокон 1',
  fibers2: 'Тип і вміст волокон 2',
  calculatedMaturityTime: 'Розрахований час зрілості за часом контакту W/C',
  mixIdentity: 'Змішати ідентичність',
  grade: 'Оцінка',
  mixType: 'Тип суміші',
  maxAggSize: 'Макс. розмір Agg',
  maxAggType: 'Максимальний тип Agg',
  cementType: 'Тип цементу',
  admixtures: 'Домішки',
  addmixDescription: 'Admix Опис',
  slump: 'Спад',
  minCementContent: 'Мінальний вміст цементу',
  maxWatCemRatio: 'Максимальне співвідношення вода/цемент',
  maxWaterToAdd: 'Макс вода для додавання',
  mortarMinWorkingLife: 'мінометний робочий термін',
  code: 'Код',
  dcClass: 'dcClass',
  chloride: 'Хлорид',
  signedByHanson: 'Підписано Хенсоном',
  warmStamp: 'Теплий штамп',
  coldStamp: 'Холодний штамп',
  containsSikatard: 'Містить Sikatard',
  containsDelvo: 'Містить Delvo',
  generalDelivery: 'Загальна інформація про доставку',
  materialInfo: 'Інформація про матеріал',
  water: 'Вода',
  materialService: 'Матеріал / Послуга',
  returnedMaterial: 'Повернений матеріал',
  returnedConcrete: 'Повернений бетон',
  waterAdded: 'Додано воду',
  concreteOnTruck: 'Бетонні вантажівки',
  concreteOnTruckUom: 'Бетон за замовчуванням на вантажівці UOM',
  reason: 'Причина',
  customerAddedWater: 'Клієнт додав воду',
  addWater: 'Додати воду',
  addedAt: 'Додано о',
  addMaterialService: 'Додати матеріал/послугу',
  customerAddedProduct: 'Продукт, доданий клієнтом',
  addReturnedMaterial: 'Додати повернутий матеріал',
  leftover: 'Залишки',
  submitAcceptance: 'Надіслати згоду',
  accepted: 'Прийнято',
  rejected: 'Відхилено',
  customerAcceptance: 'Прийняття',
  finalize: 'Завершення',
  materialSummary: 'Резюме матеріалу',
  mix: 'Змішати',
  ordered: 'Замовлено',
  loaded: 'Навантаження доставки',
  totalOrdered: 'Загальна кількість замовлення',
  loadedOrderQuantity: 'Завантажена кількість замовлення',
  loadedOrder: 'Завантажене замовлення',
  driverComment: 'Коментар водія',
  customerComment: 'Коментар клієнта',
  editComment: 'Редагувати коментар',
  addComment: 'Додати коментар',
  additionalInfo: 'Додаткова інформація',
  onBoard: 'На борту',
  driver: 'Водій',
  commentInstruction: 'Інструкція щодо коментарів',
  signatureInstruction: 'Підпис клієнта',
  customerSignature: 'Підпис клієнта',
  general: 'Загальні',
  status: 'Статус',
  updateStatusTime: 'Час оновлення статусу',
  delete: 'Видалити',
  cancel: 'Скасувати',
  back: 'Назад',
  add: 'Додати',
  edit: 'Редагувати',
  done: 'Готово',
  ok: 'ОК',
  decline: 'Відхилити',
  Submit: 'Надіслати',
  submit: 'Надіслати',
  lastRefresh: 'Останнє оновлення',
  refreshEnabled: 'Увімкнути оновлення',
  id: 'ID',
  name: "Ім'я",
  invoiceable: 'Накладна',
  provideSignature: 'Надати підпис',
  ticket: 'Квиток',
  details: 'Деталі',
  qrCode: 'QR-код',
  driverAcceptanceReason: 'Причина',
  driverAcceptance: 'Прийняття водія',
  'payment-method': 'Способи оплати',
  'add-payment-method': 'Додати спосіб оплати',
  'reason-code': 'Код причини',
  'add-reason-code': 'Додати код причини',
  'qr-code': 'QR-код',
  'unloading-method': 'Методи розвантаження',
  'add-unloading-method': 'Додати метод розвантаження',
  'driver-comment': 'Коментарі водія',
  'add-driver-comment': 'Додати коментар драйвера',
  'driver-sellable': 'Продається водій',
  vehicle: 'Транспортні засоби',
  'add-vehicle': 'Додати транспортний засіб',
  'vehicle-type': 'Типи транспортних засобів',
  vehicleTypes: 'Типи транспортних засобів',
  vehicleType: 'Тип транспортного засобу',
  'add-vehicle-type': 'Додати тип транспортного засобу',
  countries: 'Країни',
  country: 'Країна',
  type: 'Тип',
  uom: 'UOM',
  mandatory: "Обов'язковий",
  qrTemplate: 'Шаблон QR-коду',
  includeMixInfo: 'Включити інформацію про суміш у QR-код',
  includeTicketEvents: 'Включити події з квитками в QR-код',
  quantityField: 'Увімкнути поле кількості',
  concreteOnTruckField: 'Увімкнути бетон на полі вантажівки',
  materialOnTruckField: 'Увімкнути матеріал у полі вантажівки',
  timeField: 'Увімкнути поле часу',
  reasonField: 'Увімкнути поле причини',
  driverInstructions: 'Інструкції для водія',
  waitingForTicket: 'Очікування квитка...',
  createTestTicketForTruck: 'Створити тестовий квиток для вантажівки',
  exceedsLoadedQuantity: 'Значення не може перевищувати завантажену кількість {loadedQuantity}.',
  maxQuantity: 'Максимальна кількість',
  maxQuantityError: 'Кількість не може перевищувати визначену максимальну кількість {maxQty}',
  maxValueError: 'Значення не може перевищувати визначений максимум {maxQty}',
  minValueError: 'Значення не може бути меншим за визначений мінімум {minQty}',
  notAuthorized: 'Не авторизовано',
  enterTruckNumber: 'Введіть номер вантажівки',
  setTruckNumber: 'Установити номер вантажівки',
  commentList: 'Список коментарів',
  list: 'Список',
  gridPreferences: 'Налаштування сітки',
  loadingUnloadingPoints: 'Точки завантаження/розвантаження',
  activity: 'Діяльність',
  crn: 'Crn',
  update: 'Оновити',
  CONCRETE: 'Бетон',
  WATER: 'Вода',
  ADDITIVE: 'Добавка',
  'submit-signature-button': 'Прийняти',
  'cancel-signature-button': 'Скасувати',
  uomCode: 'UOM за замовчуванням',
  'product-type': 'Типи продуктів',
  'add-product-type': 'Додати тип продукту',
  'add-product': 'Додати продукт',
  'setup_driver-comment': 'Коментарі водія',
  setup_product: 'Продукти',
  'setup_product-type': 'Типи продуктів',
  'setup_qr-code': 'QR-код',
  'setup_reason-code': 'Коди причин',
  'setup_unloading-method': 'Методи розвантаження',
  'setup_entity-mt': 'Компанія',
  setup_vehicle: 'Транспортні засоби',
  'setup_vehicle-type': 'Типи транспортних засобів',
  'setup_data-retentions': 'Збереження даних',
  'add-data-retentions': 'Додати збереження даних',
  'data-retentions': 'Збереження даних',
  setup_carriers: 'перевізники',
  'add-carriers': 'Додати перевізників',
  carriers: 'перевізники',
  'setup_concrete-classes': 'Відповідність матеріалу',
  'add-concrete-classes': 'Додати відповідність матеріалу',
  'concrete-classes': 'Відповідність матеріалу',
  'setup_forms-and-waivers': 'Форми та відмови',
  'add-forms-and-waivers': 'Додати форми та відмови',
  'forms-and-waivers': 'Форми та відмови',
  companyFleet: 'Флот компанії',
  category: 'Категорія',
  interval: 'Інтервал',
  orders: 'Замовлення та квитки',
  '30days': '30 днів',
  '60days': '60 днів',
  '90days': '90 днів',
  '1year': '1 рік',
  '3years': '3 роки',
  waterMandatory: 'Вода обов’язкова',
  qrCodeTemplate: 'Шаблон QR-коду',
  waterConfig: "Необов'язкові поля",
  waterProduct: 'Вода',
  waterProductType: 'Тип продукту для води',
  defaultWaterProduct: 'Водний продукт за замовчуванням',
  invalidTemplate: 'Недійсний шаблон',
  siteAddress: 'Адреса сайту',
  siteContactName: 'Ім’я контактної особи сайту',
  siteContactPhoneNumber: 'Контактний номер телефону сайту',
  'clear-signature-button': 'Чіткий підпис',
  customerCommentEnabled: 'Увімкнути коментар клієнта',
  driverCommentEnabled: 'Увімкнути коментар драйвера',
  driverCommentMaxCharacters: 'Максимальна довжина',
  customerCommentMaxCharacters: 'Максимальна довжина',
  timeZone: 'Часовий пояс',
  driverDidNotAdd: 'Драйвер не додано',
  maxLineItems: 'Максимальна кількість рядків-позицій',
  oneLineItem: 'Має бути принаймні 1 рядок-позиція',
  signatureSiteContact: "Ім'я клієнта",
  ticketEvents: 'Події щодо квитків',
  hideEvent: 'Приховати подію',
  editable: 'Редагований',
  ticketDetail: `Квиток #{value}`,
  statusTimes: 'Часи статусу',
  materialAndDeliveryInfo: 'Інформація про матеріали та доставку',
  customerRejection: 'Відмова клієнта',
  'submit-rejection-button': 'Надіслати відхилення',
  accept: 'Прийняти',
  reject: 'Відхилити завантаження',
  signature: 'Підпис',
  'no-signature': 'Без підпису',
  unFinalizePassword: 'Скасувати фіналізацію пароля',
  waterInWorkflow: 'Увімкнути крок води в робочому процесі',
  multicountry: 'Багатокраїна',
  timestampAfterSequence: 'ПОПЕРЕДЖЕННЯ: Час не повинен передувати попередній позначці часу',
  timestampBeforeSequence: 'ПОПЕРЕДЖЕННЯ: час не повинен бути після наступної позначки часу',
  noRestQuantity: 'Немає повернених матеріалів',
  returnedMaterialMandatory: 'Має взаємодіяти з поверненим кроком матеріалу',
  driverCommentMaxChars: 'Довжина не може перевищувати максимум {maxLength} символів.',
  QrCodeEnabled: 'Увімкнути QR-код',
  QrMethod_linkBased: 'На основі посилань',
  QrMethod_contentBased:
    'На основі вмісту, ПРИМІТКА: QR-коди на основі вмісту мають обмеження щодо кількості символів, які можуть спричинити проблеми понад 1024 символи.',
  QrMethod_contentBasedWarning:
    'ПРИМІТКА. Цей QR-код містить {amount} символів, які можуть бути нерозбірливими на деяких телефонах.',
  returnedMaterialInWorkflow: 'Увімкнути етап повернення матеріалу в робочому процесі',
  materialServiceInWorkflow: 'Увімкнути етап матеріалів/послуг у робочому процесі',
  totalPumpedQuantity: 'Загальна кількість, що перекачується',
  concrete: 'Бетон',
  materialComplianceEnabled: 'Увімкнути відповідність матеріалів',
  pumpStatusTimes: 'Час стану насоса',
  pollingInterval: 'Інтервал опитування (секунди)',
  tenSecs: 'Інтервал має бути не менше 10 секунд',
  materialCompliance: 'Відповідність матеріалу',
  materialCompliance_notFound: 'Відповідність матеріалу не знайдено',
  consistenceValue: 'Консистенція - спад',
  consistenceIsCompliant: 'Консистенція відповідає BS8500-2',
  airContentValue: 'Вміст повітря',
  airContentIsCompliant: 'Вміст повітря відповідає BS8500-2',
  consistenceIsCompliantShort: 'Послідовність відповідає',
  airContentIsCompliantShort: 'Вміст повітря відповідає',
  compliance: 'Відповідність матеріалу BS8500-2',
  termsAndConditionsEnabled: 'Увімкнути форму положень та умов',
  termsAndConditions: 'Положення та умови',
  damageWaiverEnabled: 'Увімкнути форму відмови від збитків',
  damageWaiver: 'Відмова від збитків',
  damageWaiverAcknowledged: 'Відмова від збитків підтверджено',
  dynamicWaiver: 'Динамічна відмова',
  dynamicWaiverEnabled: 'Увімкнути форму динамічної відмови',
  ticketListEnabled: 'Увімкнути список заявок',
  clearTicketAfterSubmission: 'Очистити заявку після подання',
  keepTicketOpenAfterSubmission: 'Зберігати заявку відкритою після подання',
  displayTicketList: 'Показати список квитків',
  supportedLanguages: 'Підтримувані мови',
  allow: 'Дозволити',
  default: 'За замовчуванням',
  isCertificationLossTriggerForReason: 'Причина повідомлення про втрату сертифіката',
  isCertificationLossTriggerForProduct: 'Продукт із записом про втрату сертифікації',
  translations: 'Переклади',
  family_name: 'Прізвище',
  given_name: "Ім'я",
  email: 'Електронна пошта',
  role: 'Роль',
  inviteUser: 'Запросити користувача',
  carrier: 'Перевізник',
  emailOrPhoneNumber: 'Електронна пошта',
  usersEditor: 'Редактор користувача',
  verifyPassword: 'Підтвердити пароль',
  verificationCode: 'Код підтвердження',
  register: 'Зареєструватися',
  registerInstructions: 'Будь ласка, заповніть цю форму, щоб завершити реєстрацію.',
  'password-requirements':
    'Ваш пароль має містити принаймні 8 символів, містити принаймні 1 верхню літеру, 1 нижню літеру, 1 цифру та 1 спеціальний символ.',
  passwordMinCharacters: 'Пароль має містити 8 або більше символів.',
  passwordMaxCharacters: 'Пароль не може містити більше 99 символів.',
  passwordLowerCase: 'Пароль повинен містити 1 або більше малих літер.',
  passwordUpperCase: 'Пароль має містити 1 або більше великих літер.',
  passwordNumber: 'Пароль має містити 1 або більше цифр.',
  passwordSpecialCharacter: 'Пароль повинен містити 1 або більше спеціальних символів.',
  passwordsDontMatch: 'Паролі не збігаються!',
  success: 'Успіху!',
  'registration-success': 'Ваша реєстрація пройшла успішно.',
  'post-registration-instructions': 'Ви завершили процес реєстрації. Тепер ви можете увійти.',
  createDate: 'Створити дату',
  customerPo: 'Номер замовлення на замовлення клієнта',
  specification: 'Специфікація',
  description: 'Опис',
  classType: 'Тип класу',
  minimumValue: 'Мінімальне значення',
  maximumValue: 'Максимальне значення',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P Контекст',
  inviteLanguage: 'Мова запрошення',
  truckNumber: 'Номер вантажівки',
  adverseTemperature:
    'Температура навколишнього середовища під час замішування була такою, що ми не можемо гарантувати, що температура бетону буде в межах 5-35°C, як зазначено в EN 206.',
  environmentalNote:
    'Це навантаження містить екологічний агент, який не перевищує 0,03% від маси бетону. Вода, що містить цей матеріал для виробництва бетону, відповідатиме BS EN 1008.',
  unloadingMethodEnabled: 'Увімкнути метод вивантаження',
  warrantyVoided: 'Гарантія на матеріали анульована',
  materialWarrantyVoidedEnabled:
    'Коли використовується продукт із заміткою про втрату сертифікації або код продукту та причини, показуйте замітку про втрату сертифікації під час підпису.',
  'setup_templates-mt': 'Шаблони',
  copyFrom: 'Копіювати з...',
  copy: 'копіювати',
  users: 'Користувачі',
  setup: 'Налаштування',
  driverExperience: 'Досвід водія',
  ticketList: 'Список квитків',
  content: 'Вміст',
  WARNINGS: 'Попередження',
  TERMS: 'Умови',
  DAMAGE: 'Пошкодження',
  ADDITIONALWAIVER: 'Додаткова відмова',
  DYNAMICWAIVER: 'Динамічна відмова',
  source: 'Джерело',
  sensorUnable: 'Датчик не може визначити залишкову кількість.',
  PROBE: 'Зонд',
  DRIVER: 'Водій',
  DRS: 'DRS',

  waterReleaseFormEnabled: 'Увімкнути форму випуску води',
  waterRelease: 'Викид води',
  waterReleaseAcknowledged: 'Викид води підтверджено',
  'assessment-risk': 'Оцінка ризику',
  'setup_assessment-risk': 'Оцінка ризику',
  'add-assessment-risk': 'Додати оцінку ризику',
  enterRecipientsButton: 'Введіть одержувач(ів)',
  recipientsInstructions: 'Введіть адресу електронної пошти, розділивши її комою',
  enter: 'Введіть',
  invalidEmailAddress: 'недійсна адреса електронної пошти',
  personalDataPolicy:
    'Вводячи особисті дані в цю форму, ви підтверджуєте, що маєте необхідні дозволи для надання цих особистих даних',
  subcategory: 'Підкатегорія',
  isRequired: 'Потрібно',
  riskAssessmentEnabled: 'Увімкнути оцінку ризиків',
  riskAssessment: 'Оцінка ризику',
  yes: 'Так',
  no: 'Ні',
  sourceOptionsSensors: 'Параметри джерела (сенсори)',
  sourceDcm: 'DCM',
  sourceProbe: 'Зонд',
  availableCustomValues: 'Доступні спеціальні значення',
  ERR_CODE__DUPLICATE: 'Дублікат',
  ERR_CODE__DEFAULT_VALUE: 'Уже є значення за замовчуванням',
  ERR_CODE__EXISTING_FORM: 'Для цього транспортного засобу та типу вже є активна форма',
  availableValues: 'Доступні значення',
  pump: 'Насос',
  DanskName: 'Данська назва',
  GermanName: 'Німецька назва',
  EnglishName: 'Англійська назва',
  BritainName: 'Назва Великобританії',
  SpanishName: 'Іспанська назва',
  FrenchName: 'Французька назва',
  NederlandsName: 'Нідерландська назва',
  NorskName: 'Норська назва',
  SvenskaName: 'Швенська назва',
  ItalianName: 'Італійська назва',
  PolishName: 'Польська назва',
  DanskNameDefault: 'Данська назва (за замовчуванням)',
  GermanNameDefault: 'Німецька назва (за замовчуванням)',
  EnglishNameDefault: 'Англійська назва (за замовчуванням)',
  BritainNameDefault: 'Назва Великобританії (за замовчуванням)',
  SpanishNameDefault: 'Іспанська назва (за замовчуванням)',
  FrenchNameDefault: 'Французька назва (за замовчуванням)',
  NederlandsNameDefault: 'Нідерландська назва (за замовчуванням)',
  NorskNameDefault: 'Назва Norsk (за замовчуванням)',
  SvenskaNameDefault: 'Швенська назва (за замовчуванням)',
  ItalianNameDefault: 'Італійська назва (за замовчуванням)',
  PolishNameDefault: 'Польська назва (за замовчуванням)',
  DanskDescription: 'Данський опис',
  GermanDescription: 'Опис німецькою мовою',
  EnglishDescription: 'Опис англійською',
  BritainDescription: 'Опис Британії',
  SpanishDescription: 'Опис іспанською',
  FrenchDescription: 'Французький опис',
  NederlandsDescription: 'Нідерландський опис',
  NorskDescription: 'Опис Norsk',
  SvenskaDescription: 'Свенська Опис',
  ItalianDescription: 'Італійський опис',
  PolishDescription: 'Польський опис',
  DanskDescriptionDefault: 'Данський опис (за замовчуванням)',
  GermanDescriptionDefault: 'Опис німецькою (за замовчуванням)',
  EnglishDescriptionDefault: 'Опис англійською (за замовчуванням)',
  BritainDescriptionDefault: 'Опис Великобританії (за замовчуванням)',
  SpanishDescriptionDefault: 'Опис іспанською (за замовчуванням)',
  FrenchDescriptionDefault: 'Опис французькою (за замовчуванням)',
  NederlandsDescriptionDefault: 'Нідерландський опис (за замовчуванням)',
  NorskDescriptionDefault: 'Опис Norsk (за замовчуванням)',
  SvenskaDescriptionDefault: 'Свенська Опис (за замовчуванням)',
  ItalianDescriptionDefault: 'Опис італійською (за замовчуванням)',
  PolishDescriptionDefault: 'Польський опис (за замовчуванням)',
  DanskContent: 'Данський вміст',
  GermanContent: 'Вміст німецькою мовою',
  EnglishContent: 'Вміст англійською мовою',
  BritainContent: 'Вміст Великобританії',
  SpanishContent: 'Вміст іспанською мовою',
  FrenchContent: 'Французький вміст',
  NederlandsContent: 'Нідерландський вміст',
  NorskContent: 'Norsk Вміст',
  SvenskaContent: 'Svenska Вміст',
  ItalianContent: 'Італійський вміст',
  PolishContent: 'Польський вміст',
  DanskContentDefault: 'Данський вміст (за замовчуванням)',
  GermanContentDefault: 'Вміст німецькою мовою (за замовчуванням)',
  EnglishContentDefault: 'Вміст англійською мовою (за замовчуванням)',
  BritainContentDefault: 'Вміст Великобританії (за замовчуванням)',
  SpanishContentDefault: 'Вміст іспанською (за замовчуванням)',
  FrenchContentDefault: 'Французький вміст (за замовчуванням)',
  NederlandsContentDefault: 'Нідерландський вміст (за замовчуванням)',
  NorskContentDefault: 'Вміст Norsk (за замовчуванням)',
  SvenskaContentDefault: 'Svenska Вміст (за замовчуванням)',
  ItalianContentDefault: 'Італійський вміст (за замовчуванням)',
  PolishContentDefault: 'Польський вміст (за замовчуванням)',
  DanskCategory: 'Данська категорія',
  GermanCategory: 'Німецька категорія',
  EnglishCategory: 'Англійська категорія',
  BritainCategory: 'Категорія Великобританії',
  SpanishCategory: 'Іспанська категорія',
  FrenchCategory: 'Французька категорія',
  NederlandsCategory: 'Нідерландська категорія',
  NorskCategory: 'Норська категорія',
  SvenskaCategory: 'Svenska категорія',
  ItalianCategory: 'Італійська категорія',
  PolishCategory: 'Польська категорія',
  DanskCategoryDefault: 'Данська категорія (за замовчуванням)',
  GermanCategoryDefault: 'Німецька категорія (за замовчуванням)',
  EnglishCategoryDefault: 'Англійська категорія (за замовчуванням)',
  BritainCategoryDefault: 'Категорія Великобританії (за замовчуванням)',
  SpanishCategoryDefault: 'Іспанська категорія (за замовчуванням)',
  FrenchCategoryDefault: 'Французька категорія (за замовчуванням)',
  NederlandsCategoryDefault: 'Нідерландська категорія (за замовчуванням)',
  NorskCategoryDefault: 'Категорія Norsk (за замовчуванням)',
  SvenskaCategoryDefault: 'Svenska категорія (за замовчуванням)',
  ItalianCategoryDefault: 'Італійська категорія (за замовчуванням)',
  PolishCategoryDefault: 'Польська категорія (за замовчуванням)',
  DanskSubcategory: 'Данська підкатегорія',
  GermanSubcategory: 'Німецька підкатегорія',
  EnglishSubcategory: 'Англійська підкатегорія',
  BritainSubcategory: 'Підкатегорія Великобританії',
  SpanishSubcategory: 'Іспанська підкатегорія',
  FrenchSubcategory: 'Французька підкатегорія',
  NederlandsSubcategory: 'Нідерландська підкатегорія',
  NorskSubcategory: 'Норська підкатегорія',
  SvenskaSubcategory: 'Швенська підкатегорія',
  ItalianSubcategory: 'Італійська підкатегорія',
  PolishSubcategory: 'Польська підкатегорія',
  DanskSubcategoryDefault: 'Данська підкатегорія (за замовчуванням)',
  GermanSubcategoryDefault: 'Німецька підкатегорія (за замовчуванням)',
  EnglishSubcategoryDefault: 'Англійська підкатегорія (за замовчуванням)',
  BritainSubcategoryDefault: 'Підкатегорія Великобританії (за замовчуванням)',
  SpanishSubcategoryDefault: 'Іспанська підкатегорія (за замовчуванням)',
  FrenchSubcategoryDefault: 'Французька підкатегорія (за замовчуванням)',
  NederlandsSubcategoryDefault: 'Нідерландська підкатегорія (за замовчуванням)',
  NorskSubcategoryDefault: 'Підкатегорія Norsk (за замовчуванням)',
  SvenskaSubcategoryDefault: 'Svenska підкатегорія (за замовчуванням)',
  ItalianSubcategoryDefault: 'Італійська підкатегорія (за замовчуванням)',
  PolishSubcategoryDefault: 'Польська підкатегорія (за замовчуванням)',
  priorSignatures: 'Попередні підписи',
  damageWaiverSignature: 'Підпис відмови від збитків',
  waterReleaseSignature: 'Підпис випуску води',
  errors,
  themes,
  isPDFRecipientListEnable: 'Увімкнути список одержувачів PDF на екрані підпису клієнта',
  isRejectLoadsEnable: 'Увімкнути відхилення завантажень після надходження сайту',
  rejectLoad_returnedMaterial: 'Кнопка робочого циклу поверненого матеріалу',
  rejectLoad_returnedAndFinalize: 'Кнопки поверненого матеріалу та завершення робочого процесу',
  driverRejection: 'Відхилити завантаження',
  submitRejection: 'Надіслати Відхилити',

  costAndPaymentsSummary: 'Підсумок вартості та платежів',
  subtotal: 'Підсумок',
  tax: 'Податок',
  remainingBalance: 'Залишок балансу',
  paymentAmount: 'Сума платежу',
  total: 'Усього',
  paymentMethod: 'Метод оплати',
  paymentProducts: 'Продукти',
  unitPrice: 'Ціна за одиницю',
  amount: 'Сума',
  otherPaymentMethod: 'Інший спосіб оплати',
  method: 'Метод',
  paymentOptions: 'Варіанти оплати',
  typeAdditionalInfo: 'Введіть тут додаткову інформацію',
  COD: 'Готівковий платіж',
  AMEX: 'American Express',
  CASH: 'Готівка',
  CHECK: 'Перевірити',
  'CREDIT CARD': 'Кредитна картка',
  ACCOUNT: 'Відкрити рахунок',
  UNKNOWN: 'Невідомо',
  remainingMaterial: 'Матеріал, що залишився',
  splitLoad: 'Розділене навантаження - кілька',
  isEnablePaymentAndCostWorkflows: 'Увімкнути робочі процеси платежів і витрат',

  // DOT Workflow
  dotWorkflow: 'Деталі партії',
  inspectorSignature: 'Підпис інспектора',
  producerSignature: 'Підпис виробника',
  addedItems: 'Додані елементи',
  producerSignatureContact: 'Назва виробника',
  inspectorSignatureContact: "Ім'я інспектора",

  //
  airTempValue: 'Температура повітря',
  concreteTempValue: 'Температура бетону',
  cylinderNumber: 'Номер циліндра',
  materialOnTruck: 'Матеріал на вантажівці',
  waterLocation: 'Розташування на воді',
  waterAtPlant: 'Вода на заводі (gl)',
  waterAtJobsite: 'Вода на робочому місці (gl)',
  totalActualWater: 'Загальна фактична вода',
  timeDischarged: 'Час розрядки',
  // news
  ingredient: 'Інгредієнт',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Безкоштовний Mst',
  cydsTarg: 'CYDS TARG',
  target: 'Ціль',
  actual: 'Фактичний',
  freWat: 'Безкоштовна вода',
  trim: 'Обрізати',
  batchInformation: 'Інформація про партію',
  numberOfBatches: 'Кількість партій',
  totalWater: 'Загальна кількість води',
  designWater: 'Дизайн води',
  toAdd: 'Додати',
  designWC: 'Дизайн W/C',
  waterCement: 'Вода / Цемент',
  waterInTruck: 'Вода у вантажівці',
  adjustWater: 'Налаштувати воду',
  trimWater: 'Обрізати воду',
  plantWeather: 'Погода для рослин',
  rh: 'RH',

  observationTime: 'Час спостереження',
  address: 'Адреса',
  plant: 'Рослина',
  sp: 'С.П. #',
  beginTime: 'Час початку',
  mixDesc: 'Змішати опис',
  qtyOder: 'QTY замовлення',
  poTag: 'PO #',
  bridge: 'Міст #',
  volume: 'Гучність',
  truckTag: 'Вантажівка №',
  qtyDelivered: 'КІЛЬКІСТЬ доставлено',
  qtyOrder: 'QTY замовлення',
  job: 'Вакансія №',
  batchRecord: 'Пакетний запис',
  mixID: 'Ідентифікатор суміші',
  loads: 'Навантаження',
  isDot: 'Є КРАПКА',
  tolStatus: 'Статус TOL',
  lastUpdate: 'Останнє оновлення',
  driverClickedFinalize: 'Драйвер клацнув Завершити',
  autoSubmit: 'Автоматична подача',
  noTaxInformationIsAvailable: 'Інформація про податки недоступна',
  retryPullTaxInformation: 'Оновити податкові дані',
  var: '% Var',
  designQTY: 'Конструкційна кількість',
  batched: 'Пакетно',
  moisture: 'Вологість',
  actualWater: 'Фактична вода',
  moisturePercent: '% вологи',
  freeWater: 'Безкоштовна вода',
  reset: 'Скинути',
  'at-plant': 'На заводі',
  'at-job': 'На роботі',
};
