import React from 'react';
import { Core, Form, Layout, Localization, Typography } from 'connex-cds';
import styled from 'styled-components';
import { find, isEqual, property } from 'lodash';
import roundedToCents from '../common/util/round-to-cents';
import { useSetPricing } from '../../../../../useSetPricing';
import { useCurrency } from '../../../../../useCurrency';
import style from './style';
import { useTicketContext } from '../../../../../TicketContext';

const { Container } = Layout;
const { Subtitle } = Typography;
const { Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

export const Summary = ({
  show = false,
  products = [],
  orderPricing = [],
  pricing = {},
  paymentAmount = 0,
  paymentMethod,
}) => {
  const { getCurrency } = useCurrency();
  const { setPricing } = useSetPricing();
  const { ticket } = useTicketContext();
  const { setFieldValue } = Form.useFormContext();

  const reduceSubtotal = React.useCallback((acum, product) => {
    if (!product?.priceSummary || !product?.quantity?.value) {
      return roundedToCents(Number(acum));
    }

    const {
      priceSummary: { unitPrice },
      quantity: { value: quantity },
    } = product;

    const value = unitPrice * quantity;

    return roundedToCents(Number(acum) + Number(value));
  }, []);

  const reduceTotal = React.useCallback(
    (acum, product) => {
      if (!product?.priceSummary || !product?.quantity?.value) {
        return roundedToCents(Number(acum));
      }

      let priceSummary = product?.priceSummary;

      const contextProduct = find(ticket?.lineItems, { item: { productRef: product?.item?.productRef } });
      if (contextProduct?.priceSummary) {
        priceSummary = contextProduct?.priceSummary;
      }

      const totalPrice =
        product?.isTaxable || priceSummary?.isTaxable ? priceSummary?.totalPrice : priceSummary?.extendedPrice;
      return roundedToCents(Number(acum) + Number(totalPrice));
    },
    [ticket?.lineItems]
  );

  const isPricingValid = React.useMemo(() => pricing?.taxRate?.valid ?? false, [pricing?.taxRate?.valid]);

  const isTaxExempt = React.useMemo(() => {
    if (Array.isArray(orderPricing?.taxRate)) {
      return orderPricing?.taxRate?.some(tax => tax?.taxRateRef === 'exempt');
    } else {
      return orderPricing?.taxRate?.taxRateRef === 'exempt';
    }
  }, [orderPricing]);

  const productsFiltered = React.useMemo(() => {
    if (isTaxExempt) {
      return [];
    }

    return products?.filter(product => !product?.driverDidNotAdd || !product?.quantity?.value);
  }, [products, pricing?.pricedLineItems, isTaxExempt]);

  const calculations = React.useMemo(() => {
    const subtotal = productsFiltered?.reduce(reduceSubtotal, 0);

    const total = productsFiltered?.reduce(reduceTotal, 0);

    const salesTax = roundedToCents(Math.abs(Number(total) - Number(subtotal)));

    const remainingBalance = roundedToCents(Number(total) - Number(paymentAmount));

    return { subtotal, total, salesTax, remainingBalance };
  }, [productsFiltered, paymentAmount, reduceSubtotal, reduceTotal]);

  React.useEffect(() => {
    setFieldValue('deliveriesPriceSummary', {
      salesTax: calculations?.salesTax ?? '',
      subtotalPrice: calculations?.subtotal ?? '',
      totalPrice: calculations?.total ?? '',
      paymentAmount: paymentAmount ?? '',
    });
  }, [setFieldValue, calculations?.salesTax, calculations?.subtotal, calculations?.total, paymentAmount]);

  return (
    <Container flex={1} style={{ padding: '5px 10px' }}>
      <Styled>
        <Subtitle>
          <Translate stringId="costAndPaymentsSummary" data-testid="costAndPaymentsLabel" />
        </Subtitle>

        <div className="comment-section" style={{ padding: '5px' }}>
          <table width="100%">
            <tr style={{ paddingTop: '10%' }}>
              <th className="left-header">
                <Localization.Translate stringId="subtotal" data-testid="activity-header-text" />
              </th>
              <td>{getCurrency(calculations?.subtotal)}</td>
            </tr>
            <tr>
              <th className="left-header">
                <Localization.Translate stringId="tax" data-testid="activity-header-text" />
                {!isPricingValid && <i className="icon warning-no-tax-icon-end fa-light fa-triangle-exclamation" />}
              </th>
              <td>{isPricingValid ? getCurrency(calculations?.salesTax) : '--'}</td>
            </tr>
            <tr>
              <th className="left-header">
                <Localization.Translate stringId="total" data-testid="activity-header-text" />
              </th>
              <td>{getCurrency(calculations?.total)}</td>
            </tr>

            {show && (
              <>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="paymentAmount" data-testid="activity-header-text" />
                  </th>
                  <td>{getCurrency(paymentAmount)}</td>
                </tr>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="paymentMethod" data-testid="activity-header-text" />
                  </th>
                  <td>{paymentMethod}</td>
                </tr>
                <tr>
                  <th className="left-header">
                    <Localization.Translate stringId="remainingBalance" data-testid="activity-header-text" />
                  </th>
                  <td>{getCurrency(calculations?.remainingBalance)}</td>
                </tr>
              </>
            )}
          </table>
        </div>

        {!isPricingValid && (
          <>
            <div className="warning-no-tax">
              <i className="icon icon-start fa-thin fa-triangle-exclamation" />
              <span>
                <Localization.Translate
                  stringId="noTaxInformationIsAvailable"
                  data-testid="no-tax-information-is-available"
                />
              </span>
            </div>

            <Core.Button
              className="retry-pull-tax-information"
              stringId="retryPullTaxInformation"
              onClick={setPricing}
              type="primary"
              data-testid="retry-pull-tax-information"
            />
          </>
        )}
      </Styled>
    </Container>
  );
};
