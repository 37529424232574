import { Drawer, Form, Localization, Responsive } from 'connex-cds';
import { useTicketContext } from '../../../TicketContext';
import React from 'react';
import { ReturnedMaterialsMobile } from './ReturnedMaterialsMobile';
import { ReturnedMaterialsDesktop } from './ReturnedMaterialsDesktop';
import { find, isNumber, isUndefined } from 'lodash';
import { useCompanySetup, useReasonCodeSetup, useVehicleSetup, useVehicleTypeSetup } from '../../../MasterDataProvider';
import { useViewport } from '../../../../../../../util/useViewport';
import { LoadRejection } from '../../../LoadRejection';

const { Uom } = Localization;

export const sources = [{ id: 'PROBE' }, { id: 'DRIVER' }, { id: 'DRS' }];

export const ReturnedMaterials = props => {
  const { ticket } = useTicketContext();
  const { values, errors, setFieldValue, showErrors } = Form.useFormContext();
  const [concreteOnTruckUom, setConcreteOnTruckUom] = React.useState();
  const { translateObjects } = Localization.useTranslateObjects();
  const { openDrawer, closeDrawer } = Drawer.useDrawerContext();
  const { isLoading } = props;

  const primaryLineItem = React.useMemo(() => find(ticket?.lineItems, { isPrimary: true }), [ticket?.lineItems]);

  const mixInfo = React.useMemo(() => {
    return {
      mix: `${primaryLineItem?.item?.id} / ${primaryLineItem?.item?.description}`,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [primaryLineItem]);

  const vehicleSetup = useVehicleSetup();
  const companySetup = useCompanySetup();
  const reasonCodeSetup = useReasonCodeSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const sourceOptions = React.useMemo(() => {
    return translateObjects(sources, {
      getPath: 'id',
      setPath: 'description',
    });
  }, [translateObjects]);

  const vehicleType = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: ticket?.vehicleId });
    const targetVehicleType = find(vehicleTypeSetup.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });
    return targetVehicleType?.id;
  }, [vehicleSetup.data, vehicleTypeSetup.data, ticket?.vehicleId]);

  const options = React.useMemo(() => {
    if (!reasonCodeSetup?.data) {
      return [];
    }

    const reasons = reasonCodeSetup?.data
      ?.filter?.(d => d.type === 'RETURNED')
      ?.filter?.(reason => reason.status === 'ACTIVE')
      ?.filter?.(reason =>
        companySetup?.data?.isMulticountry === true
          ? reason?.countries?.includes(ticket?.origin?.address?.countryCode) || !reason?.countries?.length
          : reason
      )
      ?.filter?.(reason => reason?.vehicleTypes?.includes(vehicleType) || !reason?.vehicleTypes?.length);

    const reasonCodes = translateObjects(reasons, {
      getStringId: x => `SETUP_REASON-CODE_${x}`.replace(/\s/gi, '_').toUpperCase(),
      getPath: 'id',
      setPath: 'label',
      defaultMessagePath: 'description',
    });

    if (!reasonCodes) {
      return reasons;
    }

    return reasonCodes.map(reason => ({ ...reason, description: reason?.label ?? reason?.description }));
  }, [
    companySetup?.data?.isMulticountry,
    reasonCodeSetup?.data,
    ticket?.origin?.address?.countryCode,
    vehicleType,
    translateObjects,
  ]);

  const disabled = React.useMemo(() => {
    return values?.driverDidNotAdd;
  }, [values?.driverDidNotAdd]);

  const showSource = React.useMemo(
    () => companySetup?.data?.isReturnedMaterialSourceDcm || companySetup?.data?.isReturnedMaterialSourceProbe,
    [companySetup?.data?.isReturnedMaterialSourceDcm, companySetup?.data?.isReturnedMaterialSourceProbe]
  );

  React.useEffect(() => {
    setConcreteOnTruckUom(primaryLineItem?.quantity?.uomCode);
  }, [primaryLineItem?.quantity?.uomCode]);

  React.useEffect(() => {
    if (values?.driverDidNotAdd) {
      setFieldValue('returnReason', undefined);
    }
    if (
      values?.driverDidNotAdd &&
      typeof values?.returnConcreteOnTruck?.value === 'number' &&
      values?.returnConcreteOnTruck?.value !== 0
    ) {
      setFieldValue('returnConcreteOnTruck', { ...values?.returnConcreteOnTruck, value: 0 });
    }
    if (values?.returnConcreteOnTruck?.value === '') {
      setFieldValue('returnConcreteOnTruck', 0);
      setFieldValue('driverDidNotAdd', true);
    }
  }, [setFieldValue, values?.driverDidNotAdd, values?.returnConcreteOnTruck]);

  //if the probe value and form value are different then the driver manually changed the value
  React.useEffect(() => {
    setFieldValue('returnConcreteSource', { id: values?.returnConcreteOnTruck?.source || 'DRIVER' });
  }, [setFieldValue, values?.returnConcreteOnTruck]);

  React.useEffect(() => {
    if (isNumber(values?.returnConcreteOnTruck?.value) && !values?.returnReason && options?.length === 1) {
      const [firstOption] = options;
      const { crn, ...firstOptionProps } = firstOption;
      setFieldValue('returnReason', { ...firstOptionProps, reasonRef: crn });
    }
  }, [setFieldValue, options, values?.returnConcreteOnTruck, values?.returnReason]);

  const defaultReason = React.useMemo(() => {
    if (options.some(opt => opt.isDefault)) {
      return options.filter(opt => opt.isDefault);
    }
    return null;
  }, [options]);

  React.useEffect(() => {
    if (defaultReason && !values?.returnReason && !values?.driverDidNotAdd) {
      const [option] = defaultReason;
      const { crn, ...defaultReasonProps } = option;
      setFieldValue('returnReason', { ...defaultReasonProps, reasonRef: crn });
    }
  }, [defaultReason, setFieldValue, values?.returnReason, values?.driverDidNotAdd]);

  //If a probe message comes in and the value is missing then the sensor was unable to determine value
  //If invalid, missing message, undefined
  const sensorUnable = React.useMemo(() => {
    return (
      values?.returnConcreteSource?.id !== 'DRIVER' &&
      (typeof values?.returnConcreteOnTruck?.value !== 'number' ||
        isUndefined(values?.returnConcreteOnTruck?.value) ||
        isNaN(values?.returnConcreteOnTruck?.value))
    );
  }, [values?.returnConcreteOnTruck?.value, values?.returnConcreteSource?.id]);

  const showRejectLoadButton = React.useMemo(() => {
    return companySetup?.data?.isRejectLoadsEnable;
  }, [companySetup?.data?.isRejectLoadsEnable, companySetup?.data?.rejectLoadStep]);

  React.useEffect(() => {
    if (errors?.returnConcreteOnTruck && showErrors) {
      showErrors(true);
    }
  }, [errors?.returnConcreteOnTruck, showErrors]);

  const handleCancelRejection = React.useCallback(() => {
    setFieldValue('customerRejectReason', undefined);
    setFieldValue('customerStatus', 'PENDING');
    closeDrawer();
  }, [closeDrawer, setFieldValue]);

  const onClickRejection = React.useCallback(() => {
    openDrawer({
      titleStringId: 'driverRejection',
      component: <LoadRejection />,
      width: '70vw',
      onClose: handleCancelRejection,
    });
  }, []);

  return (
    <Responsive>
      <ReturnedMaterialsMobile
        mixInfo={mixInfo}
        concreteOnTruckUom={concreteOnTruckUom}
        disabled={disabled}
        options={options}
        reasonCodeSetup={reasonCodeSetup}
        sensorUnable={sensorUnable}
        sourceOptions={sourceOptions}
        showSource={showSource}
        showRejectLoadButton={showRejectLoadButton}
        onClickRejection={onClickRejection}
        isLoading={isLoading}
      />
      <ReturnedMaterialsDesktop
        mixInfo={mixInfo}
        concreteOnTruckUom={concreteOnTruckUom}
        disabled={disabled}
        options={options}
        reasonCodeSetup={reasonCodeSetup}
        sensorUnable={sensorUnable}
        sourceOptions={sourceOptions}
        showSource={showSource}
        showRejectLoadButton={showRejectLoadButton}
        onClickRejection={onClickRejection}
        isLoading={isLoading}
      />
    </Responsive>
  );
};
