import React from 'react';
import { Form, usePostMessageContext } from 'connex-cds';
import { useTicketContext } from './TicketContext';
import { find, findIndex, isObject, omit } from 'lodash';
import { Logger } from '../../../../util/log/logger';
import { usePostMessageListener } from './datastore/usePostMessageListener';
import { useCompanySetup, useProductSetup, useVehicleSetup, useVehicleTypeSetup } from './MasterDataProvider';

export const useProbeListener = () => {
  const { values, setFieldValue } = Form.useFormContext();
  const { ticket } = useTicketContext();
  const { sendMessage } = usePostMessageContext();

  const companySetup = useCompanySetup();
  const productSetup = useProductSetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const localLogger = React.useCallback(
    message => {
      const freezedMessage = JSON.stringify({
        type: 'debug',
        ack: {
          ticketRef: ticket?.crn,
          ticketId: ticket?.id,
          ...message,
        },
      });
      Logger.log(`Mobile Ticket - useProbeListener: ${freezedMessage}`);
      sendMessage(freezedMessage);
    },
    [sendMessage, ticket]
  );

  const processLeftOverConcrete = React.useCallback(
    message => {
      if (message?.type === 'leftoverConcrete') {
        localLogger(message);

        //It is necessary to set field values here because otherwise polling will overwrite
        //Putting source on returnConcreteOnTruck for use in ReturnedMaterial.js to know when driver changes a value
        //we are not storing the source property on returnedQuantity in the backend processLineItems.js this would cause schema errors
        setFieldValue('returnConcreteOnTruck', {
          value: message.concreteVolume,
          uomCode: message.concreteUom,
          source: message.source,
        });
        setFieldValue('returnConcreteSource', { id: message.source });
        setFieldValue('driverDidNotAdd', typeof message.concreteVolume === 'number' && message.concreteVolume === 0);
      }
    },
    [setFieldValue, localLogger]
  );

  const processWaterAdd = React.useCallback(
    message => {
      if (message?.type === 'waterAdd') {
        localLogger(message);

        //Moved logic from TicketContext.js
        const targetVehicle = find(vehicleSetup?.data, { id: ticket?.vehicleId });
        const vehicleTypeData = find(vehicleTypeSetup?.data, { crn: targetVehicle?.vehicleType?.vehicleTypeRef });

        let nextWaterEventPosition = values?.waterAddedEvents?.length ?? 0;
        let waterEvent = {
          waterProduct: '',
          waterProductSource: {
            id: message?.source,
            description: message?.source,
          },
          quantity: {
            uomCode: message?.waterAddUom,
            value: message?.waterAddQuantity,
          },
          time: new Date().toISOString(),
        };

        const { isWaterMandatory, isWaterInWorkflow, defaultWaterProduct } = companySetup?.data;
        if (isWaterMandatory || isWaterInWorkflow || vehicleTypeData?.isWaterInWorkflow) {
          const defaultWaterRef = defaultWaterProduct?.productRef;
          const defaultWater = {
            ...omit(find(productSetup?.data, { crn: defaultWaterRef }), 'crn'),
            productRef: defaultWaterRef,
          };

          waterEvent.waterProduct = defaultWater;
          nextWaterEventPosition = findIndex(values?.waterAddedEvents, {
            waterProduct: { productRef: defaultWaterRef },
          });
        }

        setFieldValue(`waterAddedEvents.${nextWaterEventPosition}`, waterEvent);
      }
    },
    [
      setFieldValue,
      localLogger,
      values,
      ticket,
      companySetup?.data,
      productSetup?.data,
      vehicleSetup?.data,
      vehicleTypeSetup?.data,
    ]
  );

  const listener = React.useCallback(
    event => {
      let message;

      // TODO: move this into the usePostMessageListener hook.
      if (isObject(event?.data)) {
        message = event?.data;
      } else {
        try {
          message = JSON.parse(event?.data);
        } catch (e) {
          message = event?.data;
        }
      }

      processLeftOverConcrete(message);
      processWaterAdd(message);
    },
    [processLeftOverConcrete, processWaterAdd]
  );

  usePostMessageListener(listener);
};
