import { useCallback } from 'react';
import { Localization } from 'connex-cds';
import { useTicketContext } from './TicketContext';
import { useGetCompanyAdmin } from '../../../../query-hooks/company';

const DEFAULT_CURRENCY = 'USD';
const DEFAULT_LOCALE = 'en-US';

export const useCurrency = () => {
  const { getCurrency: getLocatedCurrency, getCurrencyParts } = Localization.useCurrency();
  const { data: entitySetup } = useGetCompanyAdmin();
  const { ticket } = useTicketContext();

  const getCurrency = useCallback(
    amount => {
      if (entitySetup?.languageSettings?.allowedLanguages?.length > 1) {
        return getLocatedCurrency(amount, ticket?.currencyCode ?? DEFAULT_CURRENCY);
      }

      const { currencySymbol } = getCurrencyParts({ currency: DEFAULT_CURRENCY, locale: DEFAULT_LOCALE });
      return `${currencySymbol}${amount}`;
    },
    [getLocatedCurrency, ticket?.currencyCode, entitySetup?.languageSettings?.allowedLanguages]
  );

  return { getCurrency };
};
