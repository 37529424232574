import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toUtcIso, usePostMessageContext } from 'connex-cds';
import { find, isObject } from 'lodash';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { mobileTicket } from '../../../../api';
import { usePostMessageListener } from './datastore/usePostMessageListener';
import { useCompanySetup, useProductSetup, useVehicleSetup, useVehicleTypeSetup } from './MasterDataProvider';
import { WaitingForTicket } from './WaitingForTicket';
import { useGetPaymentMethod } from '../../../../query-hooks/payment-method';
import { useGetOrder } from '../../../../query-hooks/order';

const TicketContext = React.createContext();

export const useTicketContext = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error(`useTicketContext cannot be rendered outside of the TicketContext context provider`);
  }
  return context;
};

export const useTicket = (ticketRef, enabled) => {
  const { entityRef } = useParams();
  const companySetup = useCompanySetup();

  const query = useQuery({
    queryKey: ['ticket', entityRef, ticketRef],
    queryFn: () => mobileTicket.getTicket({ entityRef, ticketRef }),
    refetchInterval: (_data, query) => {
      if (!companySetup?.data?.pollingInterval) return 0;
      if (query.state.dataUpdateCount < 2) return 1;
      return companySetup.data.pollingInterval * 1000;
    },
    staleTime: Infinity,
    enabled,
  });

  if (!ticketRef) {
    return null;
  }

  return query;
};

export const TicketContextProvider = ({ children, driverExperience = false }) => {
  const { entityRef, truckNumber } = useParams();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = React.useState(false);
  const [fetchedDateTime, setFetchedDateTime] = React.useState(toUtcIso(DateTime.now()));
  const [finalized, setFinalized] = React.useState(false);
  const [currentTicketRef, setCurrentTicketRef] = React.useState();
  const [queryEnabled, setQueryEnabled] = React.useState(false);
  const queryClient = useQueryClient();

  const [submitted, setSubmitted] = React.useState(false);

  const companySetup = useCompanySetup();
  const productSetup = useProductSetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const ticketQuery = useTicket(currentTicketRef, queryEnabled);

  const orderData = useGetOrder(ticketQuery?.data?.orderRef, companySetup);

  const paymentMethodData = useGetPaymentMethod(orderData?.data?.paymentMethod?.paymentMethodRef, companySetup);

  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    setQueryEnabled(!!currentTicketRef);
  }, [currentTicketRef]);

  const reset = React.useCallback(
    (clearTicket = true, callback) => {
      setQueryEnabled(false);
      queryClient.invalidateQueries({ queryKey: ['ticket', entityRef, currentTicketRef] });

      if (clearTicket && callback) {
        setFinalized(false);
        setSubmitted(false);

        callback();
      }

      if (clearTicket && !callback) {
        setFinalized(false);
        setSubmitted(false);
        setCurrentTicketRef(null);
      }
    },
    [currentTicketRef, driverExperience, entityRef, navigate, queryClient, truckNumber]
  );

  const refreshTicket = React.useCallback(
    async entityRef => {
      setRefreshing(true);
      const updatedTicket = await mobileTicket.getTicket({ entityRef, ticketRef: ticketQuery?.data?.crn });

      if (updatedTicket.isVoided) {
        reset();
        return;
      }

      queryClient.setQueryData(['ticket', entityRef, updatedTicket.crn], updatedTicket);

      setFetchedDateTime(toUtcIso(DateTime.now()));
      setRefreshing(false);
    },
    [queryClient, reset, ticketQuery?.data?.crn]
  );

  const listener = React.useCallback(
    event => {
      // alert(JSON.stringify(event?.data || {}));

      let message;

      // TODO: move this into the usePostMessageListener hook.
      if (isObject(event?.data)) {
        message = event?.data;
      } else {
        try {
          message = JSON.parse(event?.data);
        } catch (e) {
          message = event?.data;
        }
      }

      if (message?.type === 'status-event') {
        sendMessage(JSON.stringify({ type: 'debug', ack: message }));
        const newTicket = {
          ...ticketQuery?.data,
          ticketEvents: {
            ...ticketQuery?.data?.ticketEvents,
            [message.name]: {
              eventDateTime: message.timestamp,
            },
          },
        };
        queryClient.setQueryData(['ticket', entityRef, newTicket?.crn], newTicket);
      }

      if (message?.type === 'ticket') {
        sendMessage(JSON.stringify({ type: 'debug', ack: message }));

        if (message?.active === false) {
          reset(!companySetup?.data?.keepTicketOpenAfterSubmission === true);
        }

        if (message?.active === true) {
          // Ignore new ticket if it's the current one.
          if (currentTicketRef === message.crn) return;

          reset(true, () => {
            // we need to wait a beat to allow the navigation in the reset function to occur.
            const ticketRef = message?.crn;
            if (ticketRef) {
              setCurrentTicketRef(ticketRef);

              if (driverExperience) {
                navigate(`driver/${entityRef}/${truckNumber}/ticketDetail`);
              } else {
                navigate(`/${entityRef}/${truckNumber}/mt/ticketDetail`);
              }
            }
          });
        }
      }
    },
    [
      sendMessage,
      ticketQuery?.data,
      ticketQuery?.vehicleId,
      queryClient,
      entityRef,
      reset,
      currentTicketRef,
      driverExperience,
      navigate,
      truckNumber,
    ]
  );

  usePostMessageListener(listener);

  if (!ticketQuery?.data) {
    return <WaitingForTicket busy={ticketQuery?.isLoading} setTicketRef={setCurrentTicketRef} />;
  }

  return (
    <TicketContext.Provider
      value={{
        ticket: ticketQuery?.data,
        order: orderData?.data,
        refreshTicket,
        refreshing,
        fetchedDateTime,
        reset,
        finalized,
        setFinalized,
        submitted,
        setSubmitted,
        paymentMethod: paymentMethodData?.data,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
