import errors from './errors';
import themes from './theme';
import features from './features';
import common from '../common';

export default {
  ...common,
  mobileTicket: 'Mobilna karta',
  ...features,
  save: 'Spremi',
  username: 'Korisničko ime',
  password: 'Lozinka',
  login: 'Prijava',
  required: 'Potrebno',
  light: 'Svjetlo',
  dark: 'Tamno',
  rememberMe: 'Zapamti me',
  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Pravila o privatnosti',
  customer: 'Kupac',
  project: 'Projekt',
  isBillable: 'Naplativo',
  dispatchDateTime: 'Datum',
  orderId: 'Narudžba',
  ticketId: 'Ulaznica',
  location: 'Lokacija',
  vehicleTypeName: 'Vozilo',
  product: 'Proizvod',
  products: 'Proizvod',
  salesTax: 'porez na promet',
  totalPrice: 'Ukupno',
  extendedPrice: 'Ext',
  destination: 'Odredište',
  productId: 'ID proizvoda',
  statusCode: 'Status',
  vehicleTypeId: 'ID vrste vozila',
  locationId: 'ID lokacije',
  customerId: 'ID kupca',
  projectId: 'ID projekta',
  dispatchTime: 'Vrijeme',
  showAll: 'Prikaži sve',
  hideAll: 'Sakrij sve',
  undoChanges: 'Poništi promjene',
  resetColumns: 'Poništi stupce',
  displayRowCount: `Prikaz {count, plural, 
    =0 {0 redaka} 
    one {# redaka} 
    other {# redaka} 
  }`,
  columnFilters: 'Filtri stupaca',
  visible: 'Vidljivo',
  hidden: 'Skriveno',
  itemDetail: 'Pojedinosti artikla',
  quantity: 'Količina',
  quantityMaxWaterToAdd: 'Količina (maks. voda za dodavanje: {maxWaterQuantity})',
  unloadingMethod: 'Metoda istovara',
  time: 'Vrijeme',
  eventStatus: 'Status',
  MTQ_abbr: 'm\u00B3',
  EA_abbr: 'ea',
  LTR_abbr: 'L',
  YDQ_abbr: 'y³',
  GLL_abbr: 'cura',
  MTQ_text: 'Kubični metri',
  M3_text: 'Kubični metri',
  EA_text: 'Svaki',
  LTR_text: 'Litre',
  YDQ_text: 'Kubični jardi',
  GLL_text: 'galoni',
  ACR_text: 'Akri',
  HUR_text: 'Sat',
  INH_text: 'Inči',
  FOT_text: 'stopalo',
  YRD_text: 'Dvorište',
  MMT_text: 'Milimetar',
  CMT_text: 'Centimetar',
  MTR_text: 'Mjerač',
  SMI_text: 'Mile (statutna milja)',
  KMT_text: 'Kilometar',
  INK_text: 'Kvadratni inč',
  FTK_text: 'Kvadratni metar',
  YDK_text: 'Kvadratno dvorište',
  MIK_text: 'Kvadratna milja',
  CMK_text: 'Kvadratni centimetar',
  MTK_text: 'Kvadratni metar',
  KMK_text: 'Kvadratni kilometar',
  INQ_text: 'Kubični inč',
  MMQ_text: 'Kubični milimetar',
  FTQ_text: 'Kubična stopa',
  CMQ_text: 'Kubični centimetar',
  OZA_text: 'tekuća unca',
  PT_text: 'pinta',
  QT_text: 'Kvart',
  MLT_text: 'mililitar',
  ONZ_text: 'unca',
  LBR_text: 'funta',
  STN_text: 'Tona',
  GRM_text: 'gram',
  KGM_text: 'Kilogram',
  TNE_text: 'Tona',
  PS_text: 'Funta po kvadratnom inču',
  MPA_text: 'Megapaskal',
  C56_text: 'Newton po kvadratnom milimetru',
  C62_text: 'Jedan',
  NL_text: 'Učitaj',
  MT_text: 'Otirač',
  P1_text: 'Postotak',
  CEL_text: 'Celzijev stupanj',
  FAH_text: 'Farenhajtov stupanj',
  BLL_text: 'Bačva',
  CLT_text: 'Centilitar',
  DLT_text: 'Decilitar',
  GLI_text: 'Imperijalni galon',
  '2U_text': 'Megagram',
  SA_text: 'vreća',
  '60_text': 'Postotak težine',
  BG_text: 'Torba',
  SEC_text: 'Drugi',
  MIN_text: 'Minuta',
  '64_text': 'Funta po kvadratnom inču - mjera',
  SP_text: 'Paket na polici',
  MMK_text: 'Kvadratni milimetar',
  MTN_text: 'Metrička tona',
  GT_text: 'Gigaton',
  '/NL_text': 'Po učitavanju',
  '/EA_text': 'Po količini',
  '/H/ + h_text': 'Po satu',
  '/YQ/ + YDQ_text': 'Po kubnom jardu',
  '/MQ/ + MTQ_text': 'Po kubnom metru',
  '/MN/ + MIN_text': 'Po minuti',
  '/MT_text': 'Po metru',
  DSG_text: 'Doziranje',
  '/C_text': 'Na 100 težine',
  '64_2_text': 'Specifičan',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  M3: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Litre}
    one {1 Litre}
    other {# Litre}
  }`,
  TO: `{value, plural,
    =0 {0 Tone}
    one {1 Tone}
    other {# Tone}
  }`,
  ITEM: `{value, plural,
    =0 {0 Predmeti}
    one {1 Predmeti}
    other {# Predmeti}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 y³}
    one {1 y³}
    other {# y³}
  }`,
  YDQ: `{value, plural,
    =0 {0 Kubični jardi}
    one {1 Kubični jardi}
    other {# Kubični jardi}
  }`,
  GLL: `{value, plural,
    =0 {0 Galoni}
    one {1 Galoni}
    other {# Galoni}
  }`,
  EA: `{value, plural,
    =0 {0 Svaki}
    one {1 Svaki}
    other {# Svaki}
  }`,
  MIN: `{value, plural,
    =0 {0 minuta}
    one {1 minuta}
    other {# minuta}
  }`,
  ACR: `{value, plural,
    =0 {0 hektara}
    one {1 hektara}
    other {# hektara}
  }`,
  HUR: `{value, plural,
    =0 {0 sati}
    one {1 sati}
    other {# sati}
  }`,
  INH: `{value, plural,
    =0 {0 Inči}
    one {1 Inči}
    other {# Inči}
  }`,
  FOT: `{value, plural,
    =0 {0 Stopala}
    one {1 Noga}
    other {# Stopala}
  }`,
  YRD: `{value, plural,
  =0{0 jardi}
  one {1 jard}
  other {# jardi}
  }`,
  MMT: `{value, plural,
  =0 {0 milimetara}
  one {1 milimetar}
  other {# milimetara}
  }`,
  CMT: `{value, plural,
  =0 {0 centimetara}
  one {1 centimetar}
  other {# centimetra}
  }`,
  MTR: `{value, plural,
  =0 {0 metara}
  one {1 metar}
  other {# metara}
  }`,
  SMI: `{value, plural,
  =0 {0 milja (statutnih milja)}
  one {1 milja (Statutna milja)}
  other {# milja (statutnih milja)}
  }`,
  KMT: `{value, plural,
  =0 {0 kilometara}
  one {1 kilometar}
  other {# kilometara}
  }`,
  INK: `{value, plural,
  =0 {0 kvadratnih inča}
  one {1 kvadratni inč}
  other {# kvadratnih inča}
  }`,
  FTK: `{value, plural,
  =0 {0 kvadratnih stopa}
  one {1 kvadratna stopa}
  other {# kvadratnih stopa}
  }`,
  YDK: `{value, plural,
  =0 {0 kvadratnih jardi}
  one {1 kvadratni jard}
  other {# kvadratnih jardi}
  }`,
  MIK: `{value, plural,
  =0 {0 kvadratnih milja}
  one {1 kvadratna milja}
  other {# kvadratnih milja}
  }`,
  CMK: `{value, plural,
  =0 {0 kvadratnih centimetara}
  one {1 kvadratni centimetar}
  other {# kvadratnih centimetara}
  }`,
  MTK: `{value, plural,
  =0 {0 kvadratnih metara}
  one {1 kvadratni metar}
  other {# kvadratnih metara}
  }`,
  KMK: `{value, plural,
  =0 {0 kvadratnih kilometara}
  one {1 kvadratni kilometar}
  other {# kvadratnih kilometara}
  }`,
  INQ: `{value, plural,
  =0 {0 kubičnih inča}
  one {1 kubični inč}
  other {# kubičnih inča}
  }`,
  MMQ: `{value, plural,
  =0 {0 kubičnih milimetara}
  one {1 kubični milimetar}
  other {# kubičnih milimetara}
  }`,
  FTQ: `{value, plural,
  =0 {0 kubičnih stopa}
  one {1 kubična stopa}
  other {# kubičnih stopa}
  }`,
  CMQ: `{value, plural,
  =0 {0 kubičnih centimetara}
  one {1 kubični centimetar}
  other {# kubičnih centimetara}
  }`,
  OZA: `{value, plural,
  =0 {0 tekućih unci}
  one {1 tekuća unca}
  other {# tekućih unci}
  }`,
  PT: `{value, plural,
  =0 {0 Pinta}
  one {1 Pinta}
  other {# Pinta}
  }`,
  QT: `{value, plural,
  =0 {0 Quarts}
  one {1 kvart}
  other {# kvarta}
  }`,
  MLT: `{value, plural,
  =0 {0 mililitara}
  one {1 mililitar}
  other {# mililitara}
  }`,
  ONZ: `{value, plural,
  =0 {0 unci}
  one {1 unca}
  other {# unce}
  }`,
  LBR: `{value, plural,
  =0 {0 funti}
  one {1 funta}
  other {# funti}
  }`,
  STN: `{value, plural,
  =0 {0 tona}
  one {1 tona}
  other {# tona}
  }`,
  GRM: `{value, plural,
  =0 {0 grama}
  one {1 gram}
  other {# grama}
  }`,
  KGM: `{value, plural,
  =0 {0 kilograma}
  one {1 kilogram}
  other {# kilograma}
  }`,
  TNE: `{value, plural,
  =0 {0 tona}
  one {1 tona}
  other {# tona}
  }`,
  PS: `{value, plural,
  =0 {0 psi}
  one {1 psi}
  other {# psi}
  }`,
  MPA: `{value, plural,
  =0 {0 megapaskala}
  one {1 megapaskala}
  other {# megapaskala}
  }`,
  C56: `{value, plural,
  =0 {0 Newtona po kvadratnom milimetru}
  one {1 Newton po kvadratnom milimetru}
  other {# Newtona po kvadratnom milimetru}
  }`,
  C62: `{value, plural,
  =0 {0 jedan}
  one {1 jedan}
  other {# jedan}
  }`,
  NL: `{value, plural,
  =0 {0 učitavanja}
  one {1 Učitaj}
  other {# učitavanja}
  }`,
  MT: `{value, plural,
  =0 {0 prostirke}
  one {1 prostirka}
  other {# prostirke}
  }`,
  P1: `{value, plural,
  =0 {0 posto}
  one {1 posto}
  other {# posto}
  }`,
  CEL: `{value, plural,
  =0 {0 Celzijevih stupnjeva}
  one {1 stupanj Celzija}
  other {# stupnjeva Celzijusa}
  }`,
  FAH: `{value, plural,
  =0 {0 stupnjeva Fahrenheita}
  one {1 stupanj Fahrenheita}
  other {# stupnjeva Fahrenheita}
  }`,
  BLL: `{value, plural,
  =0 {0 barela}
  one {1 barel}
  other {# barela}
  }`,
  CLT: `{value, plural,
  =0 {0 centilitara}
  one {1 centilitar}
  other {# centilitara}
  }`,
  DLT: `{value, plural,
  =0 {0 decilitara}
  one {1 decilitar}
  other {# decilitar}
  }`,
  GLI: `{value, plural,
  =0 {0 galona}
  one {1 galon}
  other {# galona}
  }`,
  '2U': `{value, plural,
  =0 {0 megagrama}
  one {1 megagram}
  other {# megagrama}
  }`,
  SA: `{value, plural,
  =0 {0 Sacks}
  one {1 vreća}
  other {# vreća}
  }`,
  60: `{value, plural,
  =0 {0 posto težine}
  one {1 posto težine}
  other {# posto težine}
  }`,
  BG: `{value, plural,
  =0 {0 torbi}
  one {1 vrećica}
  other {# vrećica}
  }`,
  SEC: `{value, plural,
  =0 {0 sekundi}
  one {1 sekunda}
  other {# sekundi}
  }`,
  64: `{value, plural,
  =0 {0 funti po kvadratnom inču - mjera}
  one {1 funta po kvadratnom inču - mjera}
  other {# funti po kvadratnom inču - mjera}
  }`,
  SP: `{value, plural,
  =0 {0 paketa za police}
  one {1 paket na polici}
  other {# paketa na polici}
  }`,
  MMK: `{value, plural,
  =0 {0 kvadratnih milimetara}
  one {1 kvadratni milimetar}
  other {# kvadratnih milimetara}
  }`,
  MTN: `{value, plural,
  =0 {0 metričkih tona}
  one {1 metrička tona}
  other {# metričkih tona}
  }`,
  GT: `{value, plural,
  =0 {0 Gigatona}
  one {1 Gigaton}
  other {# Gigatona}
  }`,
  '/NL': `{value, plural,
  =0 {0 po učitavanju}
  one {1 po učitavanju}
  other {# po učitavanju}
  }`,
  '/EA': `{value, plural,
  =0 {0 po količini}
  one {1 po količini}
  other {# po količini}
  }`,
  '/H/ + h': `{value, plural,
  =0 {0 po satu}
  one {1 po satu}
  other {# po satu}
  }`,
  '/YQ/ + YDQ': `{value, plural,
  =0 {0 po kubnom jardu}
  one {1 po kubnom jardu}
  other {# po kubnom jardu}
  }`,
  '/MQ/ + MTQ': `{value, plural,
  =0 {0 po kubnom metru}
  one {1 po kubnom metru}
  other {# po kubnom metru}
  }`,
  '/MN/ + MIN': `{value, plural,
  =0 {0 po minuti}
  one {1 po minuti}
  other {# u minuti}
  }`,
  '/MT': `{value, plural,
  =0 {0 po metru}
  one {1 po metru}
  other {# po metru}
  }`,
  DSG: `{value, plural,
  =0 {0 Doziranje}
  one {1 Doziranje}
  other {# doza}
  }`,
  '/C': `{value, plural,
  =0 {0 na 100 težine}
  one {1 na 100 težine}
  other {# na 100 težine}
  }`,
  '64_2': `{value, plural,
  =0 {0 Specifično}
  one {1 Specifično}
  other {# Specifično}
  }`,
  LTR_short: `{value, plural,
  =0 {0 L}
  one {1 L}
  other {# L}
  }`,
  TO_short: `{value, plural,
  =0 {0 tona}
  one {1 tona}
  other {# tona}
  }`,
  ITEM_short: `{value, plural,
  =0 {0 stavki}
  one {1 stavka}
  other {# stavke}
  }`,
  GLL_short: `{value, plural,
  =0 {0 galona}
  one {1 galon}
  other {# galona}
  }`,
  EA_short: `{value, plural,
  =0 {0 ea}
  one {1 ea}
  other {# ea}
  }`,
  MIN_short: `{value, plural,
  =0 {0 min}
  one {1 min}
  other {# min}
  }`,
  ACR_short: `{value, plural,
  =0 {0 ac}
  one {1 ac}
  other {# ac}
  }`,
  HUR_short: `{value, plural,
  =0 {0 sati}
  one {1 sat}
  other {# h}
  }`,
  INH_short: `{value, plural,
  =0 {0 in}
  one {1 in}
  other {# u}
  }`,
  FOT_short: `{value, plural,
  =0 {0 ft}
  one {1 ft}
  other {# stopa}
  }`,
  YRD_short: `{value, plural,
    =0{0 yd}
    one {1 jar}
    other {# yd}
    }`,
  MMT_short: `{value, plural,
    =0 {0 mm}
    one {1 mm}
    other {# mm}
    }`,
  CMT_short: `{value, plural,
    =0 {0 cm}
    one {1 cm}
    other {# cm}
    }`,
  MTR_short: `{value, plural,
    =0 {0 m}
    one {1 m}
    other {# m}
    }`,
  SMI_short: `{value, plural,
    =0 {0 milja (st mi)}
    one {1 milja ((st mi)}
    other {# milja ((st mi)}
    }`,
  KMT_short: `{value, plural,
    =0 {0 km}
    one {1 km}
    other {# km}
    }`,
  INK_short: `{value, plural,
    =0 {0 in²}
    one {1 in²}
    other {# in²}
    }`,
  FTK_short: `{value, plural,
    =0 {0 ft²}
    one {1 ft²}
    other {# ft²}
    }`,
  YDK_short: `{value, plural,
    =0 {0 yd²}
    one {1 yd²}
    other {# yd²}
    }`,
  MIK_short: `{value, plural,
    =0 {0 mi²}
    one {1 mi²}
    other {# mi²}
    }`,
  CMK_short: `{value, plural,
    =0 {0 cm²}
    one {1 cm²}
    other {# cm²}
    }`,
  MTK_short: `{value, plural,
    =0 {0 m²}
    one {1 m²}
    other {# m²}
    }`,
  KMK_short: `{value, plural,
    =0 {0 km²}
    one {1 km²}
    other {# km²}
    }`,
  INQ_short: `{value, plural,
    =0 {0 in³}
    one {1 in³}
    other {# in³}
    }`,
  MMQ_short: `{value, plural,
    =0 {0 mm³}
    one {1 mm³}
    other {# mm³}
    }`,
  FTQ_short: `{value, plural,
    =0 {0 ft³}
    one {1 ft³}
    other {# ft³}
    }`,
  CMQ_short: `{value, plural,
    =0 {0 cm³}
    one {1 cm³}
    other {# cm³}
    }`,
  OZA_short: `{value, plural,
    =0 {0 tečnih oz}
    one {1 tečne unce}
    other {# fl oz}
    }`,
  PT_short: `{value, plural,
    =0 {0 pt}
    one {1 bod}
    other {# bod}
    }`,
  QT_short: `{value, plural,
    =0 {0 qt}
    one {1 qt}
    other {# qt}
    }`,
  MLT_short: `{value, plural,
    =0 {0 ml}
    one {1 mL}
    other {# mL}
    }`,
  ONZ_short: `{value, plural,
    =0 {0 oz}
    one {1 oz}
    other {# oz}
    }`,
  LBR_short: `{value, plural,
    =0 {0 lb}
    one {1 lb}
    other {# lb}
    }`,
  STN_short: `{value, plural,
    =0 {0 tona}
    one {1 tona}
    other {# tona}
    }`,
  GRM_short: `{value, plural,
    =0 {0 g}
    one {1 g}
    other {# g}
    }`,
  KGM_short: `{value, plural,
    =0 {0 kg}
    one {1 kg}
    other {# kg}
    }`,
  TNE_short: `{value, plural,
    =0 {0 t}
    one {1 t}
    other {# t}
    }`,
  PS_short: `{value, plural,
    =0 {0 psi}
    one {1 psi}
    other {# psi}
    }`,
  MPA_short: `{value, plural,
    =0 {0 mpa}
    one {1 mpa}
    other {# mpa}
    }`,
  C56_short: `{value, plural,
    =0 {0 Newtona po kvadratnom milimetru}
    one {1 Newton po kvadratnom milimetru}
    other {# Newtona po kvadratnom milimetru}
    }`,
  C62_short: `{value, plural,
    =0 {0 jedan}
    one {1 jedan}
    other {# jedan}
    }`,
  NL_short: `{value, plural,
    =0 {0 učitavanja}
    one {1 Učitaj}
    other {# učitavanja}
    }`,
  MT_short: `{value, plural,
    =0 {0 prostirke}
    one {1 prostirka}
    other {# prostirke}
    }`,
  P1_short: `{value, plural,
    =0 {0 %}
    one {1 %}
    other {# %}
    }`,
  CEL_short: `{value, plural,
    =0 {0 c}
    one {1 c}
    other {# c}
    }`,
  FAH_short: `{value, plural,
    =0 {0 f}
    one {1 f}
    other {# f}
    }`,
  BLL_short: `{value, plural,
    =0 {0 bbl}
    one {1 bbl}
    other {# bbl}
    }`,
  CLT_short: `{value, plural,
    =0 {0 cL}
    one {1 cL}
    other {# cL}
    }`,
  DLT_short: `{value, plural,
    =0 {0 dL}
    one {1 dL}
    other {# dL}
    }`,
  GLI_short: `{value, plural,
    =0 {0 galona}
    one {1 galon}
    other {# galona}
    }`,
  '2U_short': `{value, plural,
    =0 {0 megagrama}
    one {1 megagram}
    other {# megagrama}
    }`,
  SA_short: `{value, plural,
    =0 {0 Sacks}
    one {1 vreća}
    other {# vreća}
    }`,
  '60_short': `{value, plural,
    =0 {0% težine}
    one {1 % težine}
    other {# % težine}
    }`,
  BG_short: `{value, plural,
    =0 {0 torbi}
    one {1 vrećica}
    other {# vrećica}
    }`,
  SEC_short: `{value, plural,
    =0 {0 s}
    one {1 s}
    other {# s}
    }`,
  '64_short': `{value, plural,
    =0 {0 funti po kvadratnom inču - mjera}
    one {1 funta po kvadratnom inču - mjera}
    other {# funti po kvadratnom inču - mjera}
    }`,
  SP_short: `{value, plural,
    =0 {0 paketa za police}
    one {1 paket na polici}
    other {# paketa na polici}
    }`,
  MMK_short: `{value, plural,
      =0 {0 kvadratnih milimetara}
      one {1 kvadratni milimetar}
      other {# kvadratnih milimetara}
      }`,
  MTN_short: `{value, plural,
      =0 {0 m}
      one {1 m}
      other {# m}
      }`,
  GT_short: `{value, plural,
      =0 {0 gt}
      one {1 gt}
      other {# gt}
      }`,
  '/NL_short': `{value, plural,
      =0 {0 /Učitaj}
      one {1 /Učitaj}
      other {# /Učitaj}
      }`,
  '/EA_short': `{value, plural,
      =0 {0 /ea}
      one {1/ea}
      other {# /ea}
      }`,
  '/H/ + h_short': `{value, plural,
      =0 {0/sat}
      one {1/sat}
      other {# /sat}
      }`,
  '/YQ/ + YDQ_short': `{value, plural,
      =0 {0 /y³}
      one {1/y³}
      other {# /y³}
      }`,
  '/MQ/ + MTQ_short': `{value, plural,
      =0 {0/m³}
      one {1/m³}
      other {# /m³}
      }`,
  '/MN/ + MIN_short': `{value, plural,
      =0 {0/min}
      one {1/min}
      other {# /min}
      }`,
  '/MT_short': `{value, plural,
      =0 {0/m}
      one {1/m}
      other {# /m}
      }`,
  DSG_short: `{value, plural,
      =0 {0 Doziranje}
      one {1 Doziranje}
      other {# doza}
      }`,
  '/C_short': `{value, plural,
      =0 {0/c}
      one {1/c}
      other {# /c}
      }`,
  '64_2_short': `{value, plural,
      =0 {0 Specifično}
      one {1 Specifično}
      other {# Specifično}
    }`,
  mixIdLabel: 'ID/opis mješavine',
  orderedQtyLabel: 'Naručena količina',
  loadedQtyLabel: 'Utovarena količina',
  addedByCustomer: 'Dodao korisnik',
  PRINTED: 'Ulaznica',
  LOADING_STARTED: 'Početak učitavanja',
  LOADING_COMPLETE: 'Završi učitavanje',
  TO_JOB: 'Lijeva biljka',
  ARRIVE_JOB: 'Mjesto dolaska',
  UNLOADING: 'Započni istovar',
  END_UNLOADING: 'Završi istovar',
  LEAVE_JOB: 'Napusti posao',
  IN_YARD: 'U dvorištu',
  PUMPING_STARTED: 'Pokreni pumpu',
  PUMPING_COMPLETE: 'Završna pumpa',
  READY_TO_PUMP: 'Spreman za pumpanje',
  FIRST_WCCONTACT: 'Prvi kontakt s vodenim cementom',
  plantName: 'Naziv biljke',
  plantAddress: 'Adresa pogona',
  loadingScheduleStart: 'Zakazano vrijeme učitavanja',
  deliveryAddress: 'Adresa za dostavu',
  phoneNumber: 'Telefonski broj',
  unloadingScheduleStart: 'Planirano vrijeme istovara (ETA)',
  agreedUnloading: 'Naručena vremena istovara',
  deliveryInformation: 'Informacije o isporuci',
  next: 'Dalje',
  material: 'Materijal',
  loadingPoint: 'Točka utovara',
  unloadingPoint: 'Točka istovara',
  comment: 'Komentar',
  sapDeliveryNumber: 'SAP broj isporuke (BCC broj)',
  salesOrderNumber: 'Broj prodajnog naloga i red stavke',
  wcDateTime: 'Datum i vrijeme W/C kontakta',
  po: 'PO broj',
  orderedByName: 'Narudžba poslana po imenu',
  orderedByPhone: 'Narudžba putem telefonskog broja',
  resourceId: 'ID resursa',
  totalLoadedQty: 'Ukupna utovarena količina uključujući ovaj teret',
  spacing: 'Prostor za utovar',
  deliveryFlow: 'Tijek isporuke',
  timeOnSite: 'Ukupno vrijeme na stranici/naručeno vrijeme istovara',
  salesText: 'Prodajni tekst (ID materijala)',
  strengthClass: 'Klasa čvrstoće',
  environmentExposure: 'Izloženost okoliša',
  metExposureClasses: 'Met klase izloženosti',
  dmax: 'Dmax',
  standardCert: 'Standard (certifikacija)',
  workabilityTarget: 'Cilj obradivosti korisnika',
  stoneQuality: 'Kvaliteta kamena',
  sandQuality: 'Kvaliteta pijeska',
  targetWC: 'Ciljni W/C omjer iz recepta',
  controlClass: 'Kontrolna klasa',
  chlorideClass: 'Razred klorida',
  typeAndStrength1: 'Vrsta cementa i klasa čvrstoće 1',
  typeAndStrength2: 'Vrsta cementa i klasa čvrstoće 2',
  typeOfAddition: 'Vrsta dodatka',
  typeOfAdmixture: 'Vrsta dodatka',
  orderedTemp: 'Naručena temperatura',
  fibers1: 'Vrsta i sadržaj vlakana 1',
  fibers2: 'Vrsta i sadržaj vlakana 2',
  calculatedMaturityTime: 'Izračunato vrijeme zrelosti iz W/C vremena kontakta',
  mixIdentity: 'Miks identitet',
  grade: 'Ocjena',
  mixType: 'Vrsta mješavine',
  maxAggSize: 'Maksimalna veličina skupa',
  maxAggType: 'Max Agg Type',
  cementType: 'Vrsta cementa',
  admixtures: 'Primjese',
  addmixDescription: 'Admix Description',
  slump: 'Pad',
  minCementContent: 'Minimalni sadržaj cementa',
  maxWatCemRatio: 'Maks. omjer voda/cement',
  maxWaterToAdd: 'Maksimalno dodavanje vode',
  mortarMinWorkingLife: 'Minimalni radni vijek minobacača',
  code: 'Kod',
  dcClass: 'dcClass',
  chloride: 'klorid',
  signedByHanson: 'Potpisao Hanson',
  warmStamp: 'Topli pečat',
  coldStamp: 'Hladni pečat',
  containsSikatard: 'Sadrži Sikatard',
  containsDelvo: 'Sadrži Delvo',
  generalDelivery: 'Opće informacije o isporuci',
  materialInfo: 'Informacije o materijalu',
  water: 'Voda',
  materialService: 'Materijal/Usluga',
  returnedMaterial: 'Vraćeni materijal',
  returnedConcrete: 'Vraćen beton',
  waterAdded: 'Dodana voda',
  concreteOnTruck: 'Beton na kamionu',
  concreteOnTruckUom: 'Zadani beton na kamionu UOM',
  reason: 'Razlog',
  customerAddedWater: 'Klijent je dodao vodu',
  addWater: 'Dodaj vodu',
  addedAt: 'Dodano u',
  addMaterialService: 'Dodaj materijal/uslugu',
  customerAddedProduct: 'Proizvod koji je dodao korisnik',
  addReturnedMaterial: 'Dodaj vraćeni materijal',
  leftover: 'Ostaci',
  submitAcceptance: 'Pošalji prihvaćanje',
  accepted: 'Prihvaćeno',
  rejected: 'Odbijeno',
  customerAcceptance: 'Prihvaćanje',
  finalize: 'Dovrši',
  materialSummary: 'Sažetak materijala',
  mix: 'Miks',
  ordered: 'Naručeno',
  loaded: 'Teret pri isporuci',
  totalOrdered: 'Ukupna količina narudžbe',
  loadedOrderQuantity: 'Učitana količina narudžbe',
  loadedOrder: 'Učitana narudžba',
  driverComment: 'Komentar vozača',
  customerComment: 'Komentar kupca',
  editComment: 'Uredi komentar',
  addComment: 'Dodaj komentar',
  additionalInfo: 'Dodatne informacije',
  onBoard: 'Na brodu',
  driver: 'Vozač',
  commentInstruction: 'Uputa za komentar',
  signatureInstruction: 'Potpis korisnika',
  customerSignature: 'Potpis korisnika',
  general: 'Općenito',
  status: 'Status',
  updateStatusTime: 'Vrijeme statusa ažuriranja',
  delete: 'Izbriši',
  cancel: 'Odustani',
  back: 'Natrag',
  add: 'Dodaj',
  edit: 'Uredi',
  done: 'Gotovo',
  ok: 'OK',
  decline: 'Odbij',
  Submit: 'Pošalji',
  submit: 'Pošalji',
  lastRefresh: 'Posljednje osvježenje',
  refreshEnabled: 'Omogući osvježavanje',
  id: 'ID',
  name: 'Ime',
  invoiceable: 'Može se fakturirati',
  provideSignature: 'Dodaj potpis',
  ticket: 'Ulaznica',
  details: 'Detalji',
  qrCode: 'QR kod',
  driverAcceptanceReason: 'Razlog',
  driverAcceptance: 'Prihvaćanje vozača',
  'payment-method': 'Načini plaćanja',
  'add-payment-method': 'Dodaj način plaćanja',
  'reason-code': 'Šifra razloga',
  'add-reason-code': 'Dodaj šifru razloga',
  'qr-code': 'QR kod',
  'unloading-method': 'Metode istovara',
  'add-unloading-method': 'Dodaj metodu istovara',
  'driver-comment': 'Komentari vozača',
  'add-driver-comment': 'Dodaj komentar vozača',
  'driver-sellable': 'Vozač se može prodati',
  vehicle: 'Vozila',
  'add-vehicle': 'Dodaj vozilo',
  'vehicle-type': 'Vrste vozila',
  vehicleTypes: 'Vrste vozila',
  vehicleType: 'Vrsta vozila',
  'add-vehicle-type': 'Dodaj vrstu vozila',
  countries: 'Države',
  country: 'Država',
  type: 'Vrsta',
  uom: 'UOM',
  mandatory: 'Obavezno',
  qrTemplate: 'Predložak QR koda',
  includeMixInfo: 'Uključi informacije o mješavini u QR kod',
  includeTicketEvents: 'Uključi događaje vezane uz ulaznice u QR kod',
  quantityField: 'Omogući polje količine',
  concreteOnTruckField: 'Omogući beton na polju kamiona',
  materialOnTruckField: 'Omogući materijal u polju kamiona',
  timeField: 'Omogući vremensko polje',
  reasonField: 'Omogući polje razloga',
  driverInstructions: 'Upute za vozača',
  waitingForTicket: 'Čekam kartu...',
  createTestTicketForTruck: 'Stvori probnu kartu za kamion',
  exceedsLoadedQuantity: 'Vrijednost ne može premašiti učitanu količinu od {loadedQuantity}.',
  maxQuantity: 'Maksimalna količina',
  maxQuantityError: 'Količina ne može premašiti definiranu najveću količinu od {maxQty}',
  maxValueError: 'Vrijednost ne može premašiti definirani maksimum od {maxQty}',
  minValueError: 'Vrijednost ne može biti manja od definiranog minimuma od {minQty}',
  notAuthorized: 'Nije ovlašten',
  enterTruckNumber: 'Unesite broj kamiona',
  setTruckNumber: 'Postavi broj kamiona',
  commentList: 'Popis komentara',
  list: 'Popis',
  gridPreferences: 'Postavke mreže',
  loadingUnloadingPoints: 'Točke utovara/istovara',
  activity: 'Aktivnost',
  crn: 'Crn',
  update: 'Ažuriraj',
  CONCRETE: 'Beton',
  WATER: 'Voda',
  ADDITIVE: 'Dodatak',
  'submit-signature-button': 'Prihvati',
  'cancel-signature-button': 'Odustani',
  uomCode: 'Default UOM',
  'product-type': 'Vrste proizvoda',
  'add-product-type': 'Dodaj vrstu proizvoda',
  'add-product': 'Dodaj proizvod',
  'setup_driver-comment': 'Komentari vozača',
  setup_product: 'Proizvodi',
  'setup_product-type': 'Vrste proizvoda',
  'setup_qr-code': 'QR kod',
  'setup_reason-code': 'Šifre razloga',
  'setup_unloading-method': 'Metode istovara',
  'setup_entity-mt': 'Tvrtka',
  setup_vehicle: 'Vozila',
  'setup_vehicle-type': 'Vrste vozila',
  'setup_data-retentions': 'Zadržavanje podataka',
  'add-data-retentions': 'Dodaj zadržavanje podataka',
  'data-retentions': 'Zadržavanje podataka',
  setup_carriers: 'prijevoznici',
  'add-carriers': 'Dodaj prijevoznike',
  carriers: 'prijevoznici',
  'setup_concrete-classes': 'Usklađenost materijala',
  'add-concrete-classes': 'Dodaj materijalnu usklađenost',
  'concrete-classes': 'Usklađenost materijala',
  'setup_forms-and-waivers': 'Obrasci i odricanja',
  'add-forms-and-waivers': 'Dodaj obrasce i odricanja',
  'forms-and-waivers': 'Obrasci i odricanja',
  companyFleet: 'Flota tvrtke',
  category: 'Kategorija',
  interval: 'Interval',
  orders: 'Narudžbe i ulaznice',
  '30days': '30 dana',
  '60days': '60 dana',
  '90days': '90 dana',
  '1year': '1 godina',
  '3years': '3 godine',
  waterMandatory: 'Voda obavezna',
  qrCodeTemplate: 'Predložak QR koda',
  waterConfig: 'Neobavezna polja',
  waterProduct: 'Voda',
  waterProductType: 'Vrsta proizvoda za vodu',
  defaultWaterProduct: 'Zadani proizvod vode',
  invalidTemplate: 'Nevažeći predložak',
  siteAddress: 'Adresa stranice',
  siteContactName: 'Ime kontakta stranice',
  siteContactPhoneNumber: 'Telefonski broj za kontakt stranice',
  'clear-signature-button': 'Jasan potpis',
  customerCommentEnabled: 'Omogući komentar korisnika',
  driverCommentEnabled: 'Omogući komentar vozača',
  driverCommentMaxCharacters: 'Maksimalna duljina',
  customerCommentMaxCharacters: 'Maksimalna duljina',
  timeZone: 'Vremenska zona',
  driverDidNotAdd: 'Upravljački program nije dodan',
  maxLineItems: 'Maksimalni broj stavki retka',
  oneLineItem: 'Mora imati najmanje 1 stavku retka',
  signatureSiteContact: 'Ime kupca',
  ticketEvents: 'Događaji s ulaznicama',
  hideEvent: 'Sakrij događaj',
  editable: 'Može se uređivati',
  ticketDetail: `Ulaznica #{value}`,
  statusTimes: 'Statusna vremena',
  materialAndDeliveryInfo: 'Informacije o materijalu i isporuci',
  customerRejection: 'Odbijanje kupca',
  'submit-rejection-button': 'Pošalji odbijanje',
  accept: 'Prihvati',
  reject: 'Odbaci učitavanje',
  signature: 'Potpis',
  'no-signature': 'Bez potpisa',
  unFinalizePassword: 'Poništi finaliziranje lozinke',
  waterInWorkflow: 'Omogući korak vode u tijeku rada',
  multicountry: 'Više zemalja',
  timestampAfterSequence: 'UPOZORENJE: Vrijeme ne smije biti prije prethodne vremenske oznake',
  timestampBeforeSequence: 'UPOZORENJE: Vrijeme ne smije biti nakon sljedeće vremenske oznake',
  noRestQuantity: 'Nema vraćenog materijala',
  returnedMaterialMandatory: 'Mora komunicirati s korakom vraćenog materijala',
  driverCommentMaxChars: 'Duljina ne može premašiti maksimalno {maxLength} znakova.',
  QrCodeEnabled: 'Omogući QR kod',
  QrMethod_linkBased: 'Na temelju veze',
  QrMethod_contentBased:
    'Na temelju sadržaja, NAPOMENA: QR kodovi temeljeni na sadržaju imaju ograničenja znakova koja mogu uzrokovati probleme preko 1024 znaka.',
  QrMethod_contentBasedWarning:
    'NAPOMENA: Ovaj QR kod ima {amount} znakova koji možda neće biti čitljivi na nekim telefonima.',
  returnedMaterialInWorkflow: 'Omogući korak vraćenog materijala u tijeku rada',
  materialServiceInWorkflow: 'Omogući korak materijala/usluge u tijeku rada',
  totalPumpedQuantity: 'Ukupna ispumpana količina',
  concrete: 'Beton',
  materialComplianceEnabled: 'Omogući materijalnu usklađenost',
  pumpStatusTimes: 'Vremena statusa pumpe',
  pollingInterval: 'Interval prozivanja (sekunde)',
  tenSecs: 'Interval mora biti najmanje 10 sekundi',
  materialCompliance: 'Usklađenost materijala',
  materialCompliance_notFound: 'Sukladnost materijala nije pronađena',
  consistenceValue: 'Konzistencija - pad',
  consistenceIsCompliant: 'Konzistencija je u skladu s BS8500-2',
  airContentValue: 'Sadržaj zraka',
  airContentIsCompliant: 'Sadržaj zraka u skladu je s BS8500-2',
  consistenceIsCompliantShort: 'Dosljednost u skladu',
  airContentIsCompliantShort: 'Sadržaj zraka u skladu',
  compliance: 'Usklađenost materijala s BS8500-2',
  termsAndConditionsEnabled: 'Omogući obrazac uvjeta i odredbi',
  termsAndConditions: 'Uvjeti i odredbe',
  damageWaiverEnabled: 'Omogući obrazac za odricanje od štete',
  damageWaiver: 'Odricanje od štete',
  damageWaiverAcknowledged: 'Odricanje od štete potvrđeno',
  dynamicWaiver: 'Dinamičko odricanje',
  dynamicWaiverEnabled: 'Omogući dinamički obrazac za odricanje',
  ticketListEnabled: 'Omogući popis ulaznica',
  clearTicketAfterSubmission: 'Obriši ulaznicu nakon podnošenja',
  keepTicketOpenAfterSubmission: 'Držite ulaznicu otvorenom nakon podnošenja',
  displayTicketList: 'Prikaži popis ulaznica',
  supportedLanguages: 'Podržani jezici',
  allow: 'Dopusti',
  default: 'Zadano',
  isCertificationLossTriggerForReason: 'Razlog za bilješku o gubitku certifikata',
  isCertificationLossTriggerForProduct: 'Proizvod za bilješku o gubitku certifikata',
  translations: 'Prijevodi',
  family_name: 'Prezime',
  given_name: 'Ime',
  email: 'E-pošta',
  role: 'Uloga',
  inviteUser: 'Pozovi korisnika',
  carrier: 'prijevoznik',
  emailOrPhoneNumber: 'E-pošta',
  usersEditor: 'Uređivač korisnika',
  verifyPassword: 'Potvrdi lozinku',
  verificationCode: 'Kod za provjeru',
  register: 'Registracija',
  registerInstructions: 'Molimo ispunite ovaj obrazac kako biste dovršili svoju registraciju.',
  'password-requirements':
    'Vaša lozinka mora imati najmanje 8 znakova, sadržavati najmanje 1 veliko slovo, 1 malo slovo, 1 broj i 1 poseban znak.',
  passwordMinCharacters: 'Lozinka mora sadržavati 8 ili više znakova.',
  passwordMaxCharacters: 'Lozinka ne može sadržavati više od 99 znakova.',
  passwordLowerCase: 'Lozinka mora sadržavati jedno ili više malih slova.',
  passwordUpperCase: 'Lozinka mora sadržavati jedno ili više velikih slova.',
  passwordNumber: 'Lozinka mora sadržavati 1 ili više numeričkih znakova.',
  passwordSpecialCharacter: 'Lozinka mora sadržavati 1 ili više posebnih znakova.',
  passwordsDontMatch: 'Lozinke se ne slažu!',
  success: 'Uspjeh!',
  'registration-success': 'Vaša registracija je bila uspješna.',
  'post-registration-instructions': 'Završili ste proces registracije. Sada se možete prijaviti.',
  createDate: 'Stvori datum',
  customerPo: 'Broj narudžbenice kupca',
  specification: 'Specifikacija',
  description: 'Opis',
  classType: 'Vrsta klase',
  minimumValue: 'Minimalna vrijednost',
  maximumValue: 'Maksimalna vrijednost',
  TrackIt: 'TrackIt',
  trackIt3PContext: 'TrackIt 3P kontekst',
  inviteLanguage: 'Jezik poziva',
  truckNumber: 'Broj kamiona',
  adverseTemperature:
    'Temperatura okoline u vrijeme miješanja bila je takva da ne možemo jamčiti da će beton biti u temperaturnom rasponu od 5-35oC kako je navedeno u EN 206.',
  environmentalNote:
    'Ovo opterećenje sadrži sredstvo za zaštitu okoliša koje ne prelazi 0,03 % težine betona. Voda koja sadrži ovaj materijal za proizvodnju betona bit će u skladu s BS EN 1008.',
  unloadingMethodEnabled: 'Omogući metodu pražnjenja',
  warrantyVoided: 'Jamstvo za materijal poništeno',
  materialWarrantyVoidedEnabled:
    'Kada se koristi proizvod s napomenom o gubitku certifikata ili šifra proizvoda i razloga, prikažite bilješku o gubitku certifikata u trenutku potpisa.',
  'setup_templates-mt': 'Predlošci',
  copyFrom: 'Kopiraj iz...',
  copy: 'kopija',
  users: 'Korisnici',
  setup: 'Postavljanje',
  driverExperience: 'Iskustvo vozača',
  ticketList: 'Popis ulaznica',
  content: 'Sadržaj',
  WARNINGS: 'Upozorenja',
  TERMS: 'Uvjeti',
  DAMAGE: 'Šteta',
  ADDITIONALWAIVER: 'Dodatno odricanje',
  DYNAMICWAIVER: 'Dinamičko odricanje',
  source: 'Izvor',
  sensorUnable: 'Senzor ne može odrediti preostalu količinu.',
  PROBE: 'Sonda',
  DRIVER: 'Vozač',
  DRS: 'DRS',
  waterReleaseFormEnabled: 'Omogući obrazac za ispuštanje vode',
  waterRelease: 'Otpuštanje vode',
  waterReleaseAcknowledged: 'Ispuštanje vode potvrđeno',
  'assessment-risk': 'Procjena rizika',
  'setup_assessment-risk': 'Procjena rizika',
  'add-assessment-risk': 'Dodaj procjenu rizika',
  enterRecipientsButton: 'Unesite primatelja(e)',
  recipientsInstructions: 'Unesite adresu e-pošte odvojenu zarezom',
  enter: 'Uđi',
  invalidEmailAddress: 'nevažeća adresa e-pošte',
  personalDataPolicy:
    'Unosom osobnih podataka u ovaj obrazac potvrđujete da imate potrebna dopuštenja za dijeljenje tih osobnih podataka',
  subcategory: 'Potkategorija',
  isRequired: 'Potrebno je',
  riskAssessmentEnabled: 'Omogući procjenu rizika',
  riskAssessment: 'Procjena rizika',
  yes: 'Da',
  no: 'Ne',
  sourceOptionsSensors: 'Opcije izvora (senzori)',
  sourceDcm: 'DCM',
  sourceProbe: 'Sonda',
  availableCustomValues: 'Dostupne prilagođene vrijednosti',
  ERR_CODE__DUPLICATE: 'Duplikat',
  ERR_CODE__DEFAULT_VALUE: 'Već postoji zadana vrijednost',
  ERR_CODE__EXISTING_FORM: 'Već postoji aktivan obrazac za ovo vozilo i tip',
  availableValues: 'Dostupne vrijednosti',
  pump: 'Pumpa',
  DanskName: 'Dansko ime',
  GermanName: 'njemačko ime',
  EnglishName: 'Englesko ime',
  BritainName: 'Britansko ime',
  SpanishName: 'španjolsko ime',
  FrenchName: 'francusko ime',
  NederlandsName: 'Nizozemsko ime',
  NorskName: 'Norsk ime',
  SvenskaName: 'Svenska Ime',
  ItalianName: 'Talijansko ime',
  PolishName: 'poljski naziv',
  DanskNameDefault: 'Dansko ime (zadano)',
  GermanNameDefault: 'Njemački naziv (zadano)',
  EnglishNameDefault: 'Englesko ime (zadano)',
  BritainNameDefault: 'Naziv Britanije (zadano)',
  SpanishNameDefault: 'Španjolski naziv (zadano)',
  FrenchNameDefault: 'Francusko ime (zadano)',
  NederlandsNameDefault: 'Nizozemski naziv (zadano)',
  NorskNameDefault: 'Norsk naziv (zadano)',
  SvenskaNameDefault: 'Svenska ime (zadano)',
  ItalianNameDefault: 'Talijanski naziv (zadano)',
  PolishNameDefault: 'Poljski naziv (zadano)',
  DanskDescription: 'danski opis',
  GermanDescription: 'njemački opis',
  EnglishDescription: 'Opis na engleskom',
  BritainDescription: 'Opis Britanije',
  SpanishDescription: 'španjolski opis',
  FrenchDescription: 'francuski opis',
  NederlandsDescription: 'Nizozemski opis',
  NorskDescription: 'norski opis',
  SvenskaDescription: 'Svenska Opis',
  ItalianDescription: 'talijanski opis',
  PolishDescription: 'poljski opis',
  DanskDescriptionDefault: 'Danski opis (zadano)',
  GermanDescriptionDefault: 'Njemački opis (zadano)',
  EnglishDescriptionDefault: 'Opis na engleskom (zadano)',
  BritainDescriptionDefault: 'Britanski opis (zadano)',
  SpanishDescriptionDefault: 'Španjolski opis (zadano)',
  FrenchDescriptionDefault: 'Opis na francuskom (zadano)',
  NederlandsDescriptionDefault: 'Nizozemski opis (zadano)',
  NorskDescriptionDefault: 'Norsk opis (zadano)',
  SvenskaDescriptionDefault: 'Svenski opis (zadano)',
  ItalianDescriptionDefault: 'Talijanski opis (zadano)',
  PolishDescriptionDefault: 'Opis na poljskom (zadano)',
  DanskContent: 'Danski sadržaj',
  GermanContent: 'njemački sadržaj',
  EnglishContent: 'Engleski sadržaj',
  BritainContent: 'Britanski sadržaj',
  SpanishContent: 'španjolski sadržaj',
  FrenchContent: 'Francuski sadržaj',
  NederlandsContent: 'Nizozemski sadržaj',
  NorskContent: 'Norsk sadržaj',
  SvenskaContent: 'Svenska Sadržaj',
  ItalianContent: 'talijanski sadržaj',
  PolishContent: 'Poljski sadržaj',
  DanskContentDefault: 'Danski sadržaj (zadano)',
  GermanContentDefault: 'Njemački sadržaj (zadano)',
  EnglishContentDefault: 'Engleski sadržaj (zadano)',
  BritainContentDefault: 'Britanski sadržaj (zadano)',
  SpanishContentDefault: 'Sadržaj na španjolskom (zadano)',
  FrenchContentDefault: 'Sadržaj na francuskom (zadano)',
  NederlandsContentDefault: 'Nizozemski sadržaj (zadano)',
  NorskContentDefault: 'Norsk sadržaj (zadano)',
  SvenskaContentDefault: 'Svenska Sadržaj (zadano)',
  ItalianContentDefault: 'Talijanski sadržaj (zadano)',
  PolishContentDefault: 'Poljski sadržaj (zadano)',
  DanskCategory: 'Danska kategorija',
  GermanCategory: 'njemačka kategorija',
  EnglishCategory: 'engleska kategorija',
  BritainCategory: 'Britanska kategorija',
  SpanishCategory: 'španjolska kategorija',
  FrenchCategory: 'Francuska kategorija',
  NederlandsCategory: 'Nizozemska kategorija',
  NorskCategory: 'Norsk kategorija',
  SvenskaCategory: 'Svenska kategorija',
  ItalianCategory: 'talijanska kategorija',
  PolishCategory: 'Poljska kategorija',
  DanskCategoryDefault: 'Danska kategorija (zadano)',
  GermanCategoryDefault: 'Njemačka kategorija (zadano)',
  EnglishCategoryDefault: 'Engleska kategorija (zadano)',
  BritainCategoryDefault: 'Britanska kategorija (zadano)',
  SpanishCategoryDefault: 'španjolska kategorija (zadano)',
  FrenchCategoryDefault: 'Francuska kategorija (zadano)',
  NederlandsCategoryDefault: 'Nizozemska kategorija (zadano)',
  NorskCategoryDefault: 'Norsk kategorija (zadano)',
  SvenskaCategoryDefault: 'Svenska kategorija (zadano)',
  ItalianCategoryDefault: 'Talijanska kategorija (zadano)',
  PolishCategoryDefault: 'Poljska kategorija (zadano)',
  DanskSubcategory: 'Danska potkategorija',
  GermanSubcategory: 'njemačka potkategorija',
  EnglishSubcategory: 'engleska potkategorija',
  BritainSubcategory: 'Britanska potkategorija',
  SpanishSubcategory: 'španjolska potkategorija',
  FrenchSubcategory: 'francuska potkategorija',
  NederlandsSubcategory: 'Nizozemska potkategorija',
  NorskSubcategory: 'Norsk Potkategorija',
  SvenskaSubcategory: 'Svenska Potkategorija',
  ItalianSubcategory: 'Talijanska potkategorija',
  PolishSubcategory: 'Poljska potkategorija',
  DanskSubcategoryDefault: 'Danska potkategorija (zadano)',
  GermanSubcategoryDefault: 'Njemačka potkategorija (zadano)',
  EnglishSubcategoryDefault: 'Engleska potkategorija (zadano)',
  BritainSubcategoryDefault: 'Britanska potkategorija (zadano)',
  SpanishSubcategoryDefault: 'Španjolska potkategorija (zadano)',
  FrenchSubcategoryDefault: 'Francuska potkategorija (zadano)',
  NederlandsSubcategoryDefault: 'Nizozemska potkategorija (zadano)',
  NorskSubcategoryDefault: 'Norsk potkategorija (zadano)',
  SvenskaSubcategoryDefault: 'Svenska potkategorija (zadano)',
  ItalianSubcategoryDefault: 'Talijanska potkategorija (zadano)',
  PolishSubcategoryDefault: 'Poljska potkategorija (zadano)',
  priorSignatures: 'Prethodni potpisi',
  damageWaiverSignature: 'Potpis o odricanju od štete',
  waterReleaseSignature: 'Potpis ispuštanja vode',
  errors,
  themes,
  isPDFRecipientListEnable: 'Omogući PDF popis primatelja na zaslonu s potpisom korisnika',
  isRejectLoadsEnable: 'Omogući odbijanje učitavanja nakon dolaska stranice',
  rejectLoad_returnedMaterial: 'Gumb Tijek rada vraćenog materijala',
  rejectLoad_returnedAndFinalize: 'Vraćeni materijal i gumbi za finaliziranje tijeka rada',
  driverRejection: 'Odbaci učitavanje',
  submitRejection: 'Pošalji Odbij',

  costAndPaymentsSummary: 'Sažetak troškova i plaćanja',
  subtotal: 'Međuzbroj',
  tax: 'porez',
  remainingBalance: 'Preostalo stanje',
  paymentAmount: 'Iznos plaćanja',
  total: 'Ukupno',
  paymentMethod: 'Način plaćanja',
  paymentProducts: 'Proizvodi',
  unitPrice: 'Jedinična cijena',
  amount: 'Iznos',
  otherPaymentMethod: 'Drugi način plaćanja',
  method: 'Metoda',
  paymentOptions: 'Opcije plaćanja',
  typeAdditionalInfo: 'Ovdje upišite dodatne informacije',
  COD: 'Plaćanje pouzećem',
  AMEX: 'American Express',
  CASH: 'gotovina',
  CHECK: 'Provjeri',
  'CREDIT CARD': 'Kreditna kartica',
  ACCOUNT: 'Otvori račun',
  UNKNOWN: 'Nepoznato',
  remainingMaterial: 'Preostali materijal',
  splitLoad: 'Podijeljeno opterećenje - više',
  isEnablePaymentAndCostWorkflows: 'Omogući tijek rada plaćanja i troškova',

  // DOT Workflow
  dotWorkflow: 'Detalji serije',
  inspectorSignature: 'Potpis inspektora',
  producerSignature: 'Potpis proizvođača',
  addedItems: 'Dodane stavke',
  producerSignatureContact: 'Ime proizvođača',
  inspectorSignatureContact: 'Ime inspektora',

  //
  airTempValue: 'Temperatura zraka',
  concreteTempValue: 'Temperatura betona',
  cylinderNumber: 'Broj cilindra',
  materialOnTruck: 'Materijal na kamionu',
  waterLocation: 'Vodena lokacija',
  waterAtPlant: 'Voda u biljci (gl)',
  waterAtJobsite: 'Voda na gradilištu (gl)',
  totalActualWater: 'Ukupna stvarna voda',
  timeDischarged: 'Vrijeme ispražnjeno',
  // news
  ingredient: 'sastojak',
  mcFac: 'MCFac',
  absFac: 'ABSFAC',
  od: 'OD',
  abs: 'ABS',
  ssd: 'SSD',
  freeMst: 'Besplatni Mst',
  cydsTarg: 'CYDS TARG',
  target: 'Cilj',
  actual: 'Stvarno',
  freWat: 'Besplatna voda',
  trim: 'Skrati',
  batchInformation: 'Informacije o seriji',
  numberOfBatches: 'Broj serija',
  totalWater: 'Ukupna voda',
  designWater: 'Dizajn vode',
  toAdd: 'Za dodavanje',
  designWC: 'Dizajn W/C',
  waterCement: 'Voda/cement',
  waterInTruck: 'Voda u kamionu',
  adjustWater: 'Podesite vodu',
  trimWater: 'Trim Water',
  plantWeather: 'Vrijeme za biljke',
  rh: 'RH',
  observationTime: 'Vrijeme promatranja',
  address: 'Adresa',
  plant: 'Biljka',
  sp: 'S.P. #',
  beginTime: 'Vrijeme početka',
  mixDesc: 'Mix Desc',
  qtyOder: 'KOLIČINA Narudžbe',
  poTag: 'PO #',
  bridge: 'Most #',
  volume: 'Glasnoća',
  truckTag: 'Kamion #',
  qtyDelivered: 'KOLIČINA Isporučeno',
  qtyOrder: 'KOLIČINA Narudžbe',
  job: 'Posao #',
  batchRecord: 'Skupni zapis',
  mixID: 'ID miksa',
  loads: 'Učitava',
  isDot: 'Je DOT',
  tolStatus: 'TOL status',
  lastUpdate: 'Zadnje ažuriranje',
  driverClickedFinalize: 'Upravljački program kliknuo Finalize',
  autoSubmit: 'Automatsko slanje',
  noTaxInformationIsAvailable: 'Nisu dostupni porezni podaci',
  retryPullTaxInformation: 'Osvježi porezne podatke',
  var: '% Var',
  designQTY: 'Količina dizajna',
  batched: 'Skupno',
  moisture: 'Vlaga',
  actualWater: 'Stvarna voda',
  moisturePercent: '% vlage',
  freeWater: 'Besplatna voda',
  reset: 'Poništi',
  'at-plant': 'U tvornici',
  'at-job': 'Na poslu',
};
