export default {
  languages: {
    da: 'Dansk',
    de: 'Deutsch',
    'en-US': 'English (US)',
    'en-GB': 'English (Great Britain)',
    'es-CO': 'Español (Colombia)',
    'fr-FR': 'Français',
    'nl-NL': 'Nederlands',
    'no-NO': 'Norsk',
    'sv-SE': 'Svenska',
    'it-IT': 'Italiano',
    'pl-PL': 'Polski',
    pt: 'Português',
    'ru-RU': 'Русский',
    'hr-HR': 'Hrvatski',
    uk: 'Yкраїнська',
  },
  mt: 'Deliveries',
};
