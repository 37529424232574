import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 0 20px;
  height: 70vh;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

export const PrivacyPolicyUK = () => {
  return (
    <Styled className={cn('privacy')}>
      <div>
        <h4>Повідомлення про конфіденційність Command Alkon Incorporated</h4>
      </div>
      <div id="overview_content">
        <p>
          <em>Останнє оновлення: 26 вересня 2023 р.</em>
        </p>
        <h2>Огляд</h2>
        <p>
          Command Alkon Incorporated, включаючи її пов’язані компанії ("CAI" або "ми"), є міжнародною комерційною
          компанією організація, яка надає компаніям програмне забезпечення, послуги та веб-рішення у вигляді готової
          суміші, сукупності та індустрії масових перевезень за допомогою локальних, програмного забезпечення як послуги
          ("SaaS") та інших веб-методів. CAI поважає конфіденційність користувачів своїх продуктів і послуг і прагне
          захищати їх конфіденційність і збереження їх довіри. Це Повідомлення про конфіденційність описує наші методи
          збору, використання, зберігання, передача, розголошення та інша обробка персональних даних (інакше називаються
          Персональними Інформація) у зв’язку з використанням вами продуктів і/або послуг CAI, веб-сайтів, програм і
          соціальних мереж сторінки, а також вашу взаємодію зі співробітниками CAI на зустрічах і подіях CAI, включаючи
          офлайн-продажі та маркетингова діяльність. Будь ласка, уважно прочитайте це Повідомлення про конфіденційність,
          щоб зрозуміти нашу політику та практику щодо обробки персональних даних. Переходячи на наш веб-сайт і/або
          використовуючи наші продукти чи послуги, ви згоду на збір, використання та розкриття персональних даних, як
          описано в цьому Повідомленні про конфіденційність. Якщо ви Якщо у вас є питання щодо конфіденційності чи
          проблеми, будь ласка, зв’яжіться з нами за допомогою інформації "Зв’язатися з нами" внизу цього Повідомлення
          про конфіденційність.
        </p>
        <h2></h2>
        <h2>Зміст</h2>
        <ul>
          <li>
            <a href="#summary_of_privacy_notice">Стислий виклад повідомлення про конфіденційність</a>
          </li>
          <li>
            <a href="#controller_processor">Контролер/процесор</a>
          </li>
          <li>
            <a href="#to_whom_does_this_notice_apply">Кого стосується це повідомлення</a>
          </li>
          <li>
            <a href="#how_we_collect_personal_data">Як ми збираємо та використовуємо особисті дані</a>
          </li>
          <li>
            <a href="#automatically_collected_information">Автоматично зібрані дані</a>
          </li>
          <li>
            <a href="#how_we_use_the_information_we_collect">Як ми використовуємо дані, які збираємо</a>
          </li>
          <li>
            <a href="#how_we_disclose_the_information_we_collect">Як ми розкриваємо дані, які збираємо</a>
          </li>
          <li>
            <a href="#third_party_services">Сторонні служби</a>
          </li>
          <li>
            <a href="#security">Безпека</a>
          </li>
          <li>
            <a href="#retention_of_data">Зберігання даних</a>
          </li>
          <li>
            <a href="#your_rights_and_choices">Ваші права та вибір</a>
          </li>
          <li>
            <a href="#international_data_transfers_and_the_data_privacy_framework">
              Міжнародна передача даних і Конфіденційність даних
            </a>
          </li>
          <li>
            <a href="#lawful_basis_for_processing">Правова основа для обробки</a>
          </li>
          <li>
            <a href="#children_policy">Конфіденційність дітей</a>
          </li>
          <li>
            <a href="#changes_to_the_privacy_notice">Зміни до Повідомлення про конфіденційність</a>
          </li>
          <li>
            <a href="#contact_us">Зв'яжіться з нами</a>
          </li>
        </ul>
      </div>
      <div id="summary_of_privacy_notice">
        <h1>Стислий виклад повідомлення про конфіденційність</h1>
        <p>
          <strong>Додаток</strong> &ndash; Наше Повідомлення про конфіденційність стосується обробки наданих
          персональних даних; 1) відвідувачами та користувачами різних сайтів, програм, продуктів та/або послуг CAI; 2)
          відвідувачі CAI і галузеві події; 3) замовники та потенційні замовники та їхні представники; 4) передплатники
          нашого повідомлення та/або інформаційні бюлетені; 5) постачальники та ділові партнери та їх представники; 6)
          відвідувачі нашого офіси; 7) будь-хто інший, хто надає персональні дані CAI для будь-яких інших цілей.
          Докладніше див нижче.
        </p>
        <p>
          <strong>Типи оброблених даних</strong> &ndash; CAI збирає персональні дані, які ви надаєте. Наприклад, коли ви
          реєструєте обліковий запис, ми можемо збирати таку інформацію, як імена, адреси електронної пошти, фізична
          адреса, телефон номери тощо. Ви також можете надати особисті дані, щоб, наприклад, дізнатися більше про наші
          продукти або зареєструватися наші сповіщення. Ми можемо обробляти такі особисті дані (залежно від обставин)
          збирати під час відвідування веб-сайту, маркетингової взаємодії, використання програми та використання наших
          продуктів і/або послуг у хід домовленостей з вами та/або вашим роботодавцем: ваше ім'я, контактна інформація
          (адреса електронної пошти, телефон номер тощо), відомості про посаду (роботодавець, посада, керівник тощо),
          певні ідентифікаційні номери (водія). ліцензія, працівник тощо), інформація про результати роботи та
          сертифікати, платіжна інформація, IP-адреса, геолокація, підпис, зображення з камери, ім'я користувача та
          пароль, профспілкова інформація, етнічна приналежність, деякі пов'язані з роботою медичну інформацію,
          повідомлення та поведінкові дані та інформацію про вас, отриману через розміщення файли cookie та пікселі
          відстеження під час використання платформи. Додаткову інформацію дивіться нижче.
        </p>
        <p>
          <strong>Мета обробки</strong> &ndash; Ми обробляємо ваші персональні дані для таких цілей: 1) виконання
          договорів з вами та/або вашим роботодавцем; 2) моніторинг, розвиток, підтримка та адміністрування програм,
          веб-сайтів і платформ; 3) безпека та запобігання шахрайству; 4) наші маркетингові цілі (ми не продаємо ваші
          персональні дані); 5) наше управління бізнесом; 6) пов'язаний аналіз поведінки з використанням платформи.
          Додаткову інформацію дивіться нижче.
        </p>
        <p>
          <strong>Законна основа для обробки</strong> &ndash; Наскільки це стосується вас, деякі юрисдикції вимагати,
          щоб будь-яка обробка персональних даних була підкріплена правовою підставою. Загалом ми спираємося на наступне
          правове обґрунтування нашої діяльності з обробки: 1) виконання угоди з вами та/або вашою роботодавець; 2)
          переслідування наших законних інтересів, якщо вони не переважають ваші інтереси, права та свободи; 3) ваша
          згода; 4) дотримання правового зобов'язання. Додаткову інформацію дивіться нижче.
        </p>
        <p>
          <strong>Передача даних</strong> &ndash; Ми можемо передавати ваші особисті дані іншим афілійованим особам CAI
          та/або треті сторони (тобто ділові партнери, торгові посередники тощо), суб’єкти-покупці або придбані
          організації, постачальники послуг, і, відповідно до чинного законодавства, державні органи, суди, зовнішні
          консультанти та подібні треті особи партії. Деякі передачі даних можуть перетинати національні кордони. Ми
          вживатимемо всіх розумних заходів для цього Ваші особисті дані залишаються конфіденційними під час передачі.
          Ми не продаємо ваші особисті дані третім особам партії. Крім того, CAI не передаватиме ваші дані жодній третій
          стороні без повного розкриття, за винятком випадків інакше викладено в цьому Повідомленні про
          конфіденційність. Додаткову інформацію дивіться нижче.
        </p>
        <p>
          <strong>Зберігання та видалення даних</strong> &ndash; Ваші особисті дані буде видалено, коли вони перестануть
          діяти необхідні для цілей початкової обробки, законного інтересу або відповідно до чинного законодавства. для
          докладніше дивіться нижче.
        </p>
        <p>
          <strong>Ваш вибір і права</strong> &ndash; Залежно від вашої юрисдикції у вас може бути декілька права щодо
          ваших персональних даних, які можуть включати право доступу до ваших персональних даних, право отримати копію
          ваших персональних даних, право на передачу ваших персональних даних третій стороні, право виправити ваші
          персональні дані, право обмежити певну обробку та/або право мати ваші персональні дані стерто. Щоб
          скористатися своїми правами щодо ваших персональних даних, скористайтеся &ldquo;Контактом нас&rdquo;
          інформацію в кінці цього Повідомлення про конфіденційність. Додаткову інформацію дивіться нижче.
        </p>
        <p>
          <strong>Зміни до цього Повідомлення про конфіденційність</strong> &ndash; Ми залишаємо за собою право
          змінювати умови цього Повідомлення про конфіденційність за бажанням і в будь-який час відповідно до змін
          практики або законодавства про конфіденційність. Поточний версія цього Повідомлення про конфіденційність
          завжди буде доступна за посиланням на наших веб-сайтах, платформах або через &ldquo;Зв'язатися з нами&rdquo;
          інформацію в кінці цього Повідомлення про конфіденційність. Ви повинні переглянути цю Політику
          конфіденційності Періодично сповіщайте, щоб ви були в курсі наших найновіших правил і практик.
        </p>
      </div>
      <div id="controller_processor">
        <h1>Докладне повідомлення про конфіденційність</h1>
        <p>
          <strong>Контролер/Обробник &mdash;</strong> CAI може бути Контролером даних або Обробником даних для цілі
          обробки персональних даних залежно від обставин. Де CAI обробляє дані від імені наших клієнтів та/або їхніх
          афілійованих осіб, CAI найчастіше діє як Обробник даних. Де CAI обробляє персональні дані даних для власних
          цілей і поза вказівками своїх клієнтів та/або їхніх філій, CAI може бути Контролер даних. Якщо у вас є
          будь-які запитання щодо ролі CAI щодо ваших особистих даних, будь ласка використовуйте &ldquo;Зв'язатися з
          нами&rdquo; інформацію в кінці цього Повідомлення про конфіденційність.
        </p>
      </div>
      <div id="to_whom_does_this_notice_apply">
        <p>
          <strong>Кого стосується це повідомлення &mdash;</strong> Наше повідомлення про конфіденційність стосується
          всіх осіб, які не пов’язані з роботою обробка персональних даних CAI незалежно від джерела збору. Це
          Повідомлення про конфіденційність стосується обробка персональних даних, наданих: 1) відвідувачами та
          користувачами різних сайтів, програм, продуктів CAI, та/або послуги; 2) учасники CAI та галузевих заходів; 3)
          клієнти та потенційні клієнти та їх представники; 4) підписки на наші повідомлення, електронні листи та/або
          інформаційні бюлетені; 5) постачальники та бізнес партнери та їх представники; 6) відвідувачі наших офісів; 7)
          будь-хто інший, хто надає персональні дані CAI для будь-яких інших цілей.
        </p>
      </div>
      <div id="how_we_collect_personal_data">
        <h2>Як ми збираємо особисті дані &mdash;</h2>
        <h3>Особисті дані, які ви надаєте:</h3>
        <p>
          CAI збирає особисті дані, які надаєте ви або ваш представник. Це може статися на заході CAI під час захід
          підтримки, через маркетинг, під час особистої взаємодії тощо. Приклади типів особистих дані, які може збирати
          CAI, включають, але не обмежуються цим, ваше ім’я, адресу, номер телефону, електронну адресу адреса,
          роботодавець, посада, дата найму, керівник, стаж, номер водійського посвідчення, номер працівника, номер
          соціального страхування, інший податковий номер, інформація про результати роботи та сертифікати, оплата
          інформація, IP-адреса та/або ідентифікатор пристрою, геолокація, підпис, фотографія та/або відеокамера, ім’я
          користувача та пароль, профспілкова інформація, етнічна приналежність, певна медична інформація, пов’язана з
          роботою, повідомлення та дані про поведінку та інформацію про вас, отриману через розміщення файлів cookie та
          пікселів відстеження під час використання платформи. Якщо у вас є запитання чи сумніви щодо будь-якого з цих
          типів персональних даних, скористайтеся &ldquo;Зв'язатися з нами&rdquo; інформацію в кінці цього Повідомлення
          про конфіденційність.
        </p>
        <h3>Дані, зібрані за допомогою наших продуктів і послуг:</h3>
        <p>CAI отримує персональні дані у зв’язку з наданням своїх продуктів і послуг, зокрема:</p>
        <p>(i) продукти або послуги SaaS, розміщені для клієнтів CAI;</p>
        <p>
          (ii) Веб-продукти або послуги для спільних комерційних рішень, що охоплюють усе замовлення та процес
          закупівель у ринковому секторі бізнес-бізнес;
        </p>
        <p>
          (iii) Локальні програмні продукти, ліцензовані клієнту для використання у його власних приміщеннях шляхом
          надання професійні послуги;
        </p>
        <p>(iv) Локальні апаратні продукти, продані клієнту для використання в його власному приміщенні; і</p>
        <p>
          (v) Послуги підтримки клієнтів, пов’язані з продуктами SaaS, програмним і апаратним забезпеченням, розміщеним
          на веб-сайті та в локальній мережі.
        </p>
        <p>
          Наприклад, коли наші клієнти користуються продуктами чи послугами, наведеними вище, вони можуть надавати
          інформацію про свої співробітників, включаючи їхні імена, посади, адреси електронної пошти, облікові дані для
          входу, номери телефонів, адреси, дати народження, номери водійських прав та інша інформація. Деякі з наших
          веб-сайтів Рішення дозволяють клієнтам, наприклад, надсилати особисті дані для створення користувачів рішення
          для зберігання документи транзакції, які можуть містити деякі особисті дані підписантів або ділових контактів,
          для використання геолокації та для зберігання контактної інформації, пов’язаної з торговими партнерами. Ми
          часто обробляємо особисті дані від імені наших клієнтів за умови письмового договору. Ми не контролюємо
          обробку даних або практики захисту наших клієнтів (які можуть бути вашим роботодавцем, постачальником послуг
          або діловим партнером), тому їхні умови можуть відрізнятися від умов, викладених у цьому Повідомленні про
          конфіденційність. Зверніть увагу, що там, де CAI збирає особисті дані через продукти або послуги, які
          контролюються нашими клієнтами, наші клієнти є Контролером даних для чого дані збираються, як вони
          використовуються та розкриваються. У таких випадках CAI діє лише як Обробник даних. Будь-який запитання,
          пов’язані з тим, як наші клієнти обробляють, використовують або передають особисті дані, які вони збирають
          через наш продукти або послуги повинні бути спрямовані на відповідного клієнта.
        </p>
      </div>
      <div id="automatically_collected_information">
        <h3>Автоматично зібрана інформація:</h3>
        <p>
          Під час навігації та взаємодії з нашими веб-сайтами та/або продуктами та програмами SaaS, які ми можемо
          використовувати технології автоматичного збору даних для збору певної інформації про ваше обладнання, дії та
          шаблони ("Інформація про дії користувача"), включаючи: (i) деталі вашого використання, включаючи дані про
          трафік, місцезнаходження дані через технологію геолокації, журнали та інші комунікаційні дані та ресурси, до
          яких ви маєте доступ, і використання; та (ii) інформацію про ваш пристрій, включаючи вашу операційну систему,
          IP-адресу та інші мобільні пристрої дані для входу. Частина цієї інформації може вважатися персональними
          даними у вашій юрисдикції.
        </p>
        <p>
          Інформація про дії користувачів, яку ми збираємо, допомагає нам покращувати наші веб-сайти та продукти, а
          також надавати a кращі та більш персоналізовані послуги, дозволяючи нам оцінювати моделі використання,
          відображати вам інформацію запросити у відповідному часовому поясі та впізнати вас, коли ви повернетеся на наш
          веб-сайт або продукт.
        </p>
        <p>
          Технології, які ми використовуємо для автоматичного збору даних, можуть включати веб-маяки, піксельні теги,
          сценарії або файли cookie. Файл cookie — це невеликий файл, який розміщується на жорсткому диску вашого
          пристрою. Ми використовуємо файли cookie для аналізу використання, налаштовувати наші послуги, вимірювати
          ефективність і сприяти довірі та безпеці. Печиво обговорюється далі нижче.
        </p>
      </div>
      <div id="how_we_use_the_information_we_collect">
        <h2>Як ми використовуємо зібрану інформацію &ndash;</h2>
        <p>
          CAI використовує зібрану інформацію з метою ефективної роботи, надання своїх продуктів і послуги клієнтам,
          сприяння бізнесу між сторонами в галузі, а також адміністрування та управління його відносини з клієнтами. Ми
          також використовуємо зібрану інформацію для обробки, оцінки та відповіді ваші запити; відповідати на запити та
          заяви; створювати, адмініструвати та спілкуватися з вами про ваші рахунок (включаючи будь-які покупки та
          платежі); управляти, оцінювати та вдосконалювати бізнес CAI (включаючи розробка нових продуктів і послуг,
          управління комунікаціями, проведення маркетингових досліджень, аналіз CAI продукти/послуги/веб-сайти, а також
          проведення бухгалтерського обліку, аудиту, виставлення рахунків, звірки та збору діяльності); забезпечити
          безпеку мережевих сервісів і ресурсів CAI; і дотримуватись чинного законодавства вимоги.
        </p>
      </div>
      <div id="how_we_close_the_information_we_collect">
        <h2>Як ми розкриваємо зібрану інформацію </h2>
        <p>Ми можемо розкривати деякі персональні дані, які ми збираємо, таким чином:</p>
        <ul>
          <li>Нашим афілійованим особам та/або діловим партнерам, з якими ми маємо договірні відносини;</li>
          <li>
            Третім особам, таким як підрядники, постачальники послуг, консультанти та інші агенти ("Сервіс
            Постачальники"), які надають допомогу нашому бізнесу. У деяких випадках CAI співпрацює з Постачальниками
            послуг для різні причини, включаючи, наприклад, обробку платежів кредитною карткою, відстеження годин
            роботи, дані хостинг, бухгалтерія. Постачальники послуг, з якими ми надаємо такі особисті дані, як правило,
            зобов’язані Зобов'язання щодо конфіденційності та конфіденційності, а також список таких Постачальників
            послуг можна знайти на нашому веб-сайті (<b>www.commandalkon.com</b>) на вкладці "Право" (Список
            субпроцесорів);
          </li>
          <li>Для виконання цілей, для яких ви або ваш роботодавець надали такі особисті дані;</li>
          <li>
            Покупцю або іншому правонаступнику CAI або будь-якої з наших філій у разі придбання, злиття, відчуження,
            реструктуризація, реорганізація, розпуск або інший продаж або передача деяких або всіх CAI або будь-який
            капітал або активи наших афілійованих компаній, як безперервне підприємство, так і в рамках банкрутства,
            ліквідації або аналогічні провадження, в яких персональні дані є серед переданих активів; і
          </li>
          <li>Для будь-якої іншої мети, розкритої нами під час надання особистих даних.</li>
        </ul>
        <p>
          Незважаючи на будь-які інші положення цього Повідомлення про конфіденційність, ми також залишаємо за собою
          право доступу, збереження та розкривати будь-яку інформацію, яку ми збираємо, оскільки ми обґрунтовано
          вважаємо це необхідним: (i) для відповіді на юридичні вимоги, включно з дотриманням будь-якого судового
          наказу, закону чи судового процесу та відповіддю будь-якому уряду або нормативний запит, включно з відповіддю
          правоохоронним органам або іншим державним службовцям у відповідь на a перевірений запит, що стосується
          кримінального розслідування або передбачуваної незаконної діяльності; (ii) для виконання або застосування наші
          політики та угоди; (iii) для виявлення, запобігання чи іншого усунення шахрайства, забезпечення безпеки,
          довіри та безпеки або технічні проблеми; (iv) відповідати на запити підтримки користувачів; або (v) для
          захисту прав, власності, здоров'я або безпеки CAI, наших користувачів, будь-яких третіх сторін або
          громадськості в цілому.
        </p>
        <h2>Додаткова інформація для жителів Каліфорнії та інших штатів США </h2>
        <p>
          Особиста інформація, яку ми збираємо, включає інформацію в межах категорій даних у таблиці нижче. Ці категорії
          також представляють категорії особистої інформації, яку ми зібрали протягом останні 12 місяців. Зауважте, що
          наведені нижче категорії визначені законодавством штату Каліфорнія. Включення a категорія у списку нижче
          вказує лише на те, що залежно від послуг і продуктів, які ми вам надаємо, та/або вашого роботодавця та
          ділового партнера, ми можемо збирати або розпоряджатися певною інформацією в цій категорії. Це не обов’язково
          означає, що ми збираємо або розкриваємо всю інформацію, перераховану в певній категорії, і не робимо цього ми
          збираємо всі категорії інформації для всіх осіб. Крім того, поки ми включаємо дані, зібрані в контексті
          "бізнес-бізнес", який вважається персональними даними відповідно до певних законів про конфіденційність,
          наприклад ЄС і GDPR Великобританії та Закон Каліфорнії про права на конфіденційність, вони не можуть вважатися
          персональними даними в інших юрисдикціях, в тому числі в інших штатах США. Ми розкрили інформацію в кожній із
          наведених нижче категорій разом із нашими філії та постачальники послуг для наших бізнес-цілей протягом
          останніх 12 місяців.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Категорія</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Джерела</strong>
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  <strong>Мета обробки</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Такі ідентифікатори, як справжнє ім’я, псевдонім, поштова адреса, унікальний персональний
                  ідентифікатор, онлайн-ідентифікатор, Адреса Інтернет-протоколу, електронна адреса, ім’я облікового
                  запису, номер соціального страхування, водійські права номер або інші подібні ідентифікатори.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані, а також інформація про ваші купівельні тенденції, отримані від нашого ділових
                  партнерів, також використовується в маркетингових цілях, включаючи пропозицію вам продуктів і послуг
                  які можуть вас зацікавити через пряму та партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Інформація, яка ідентифікує, стосується, описує або може бути пов’язана з a конкретної особи,
                  включаючи, але не обмежуючись цим, ваше ім’я, підпис, номер соціального страхування, фізичні
                  характеристики або опис, адреса, номер телефону, водійське посвідчення або штат номер ідентифікаційної
                  картки, медична довідка.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані також використовуються в маркетингових цілях, зокрема для пропозиції вам продуктів
                  і послуг, які можуть вас зацікавити як через пряму, так і через партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Комерційна інформація, включно з записами про придбану особисту власність, продукти чи послуги,
                  отримані, або розглянуті, або інші покупки чи споживання історії чи тенденції.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами або записи, які ми маємо
                  про вас хід надання послуг або продуктів.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані, а також інформація про ваші купівельні тенденції, отримані від нашого ділових
                  партнерів, також використовується в маркетингових цілях, включаючи пропозицію вам продуктів і послуг
                  які можуть вас зацікавити через пряму та партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Інформація про діяльність в Інтернеті чи іншій електронній мережі, включаючи, але не обмежуючись,
                  перегляд історію, історію пошуку та інформацію про взаємодію споживача з веб-сайтом в Інтернеті,
                  додаток або рекламу.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами або через використання
                  файлів cookie та подібні технології.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані також використовуються в маркетингових цілях, зокрема для пропозиції вам продуктів
                  і послуг, які можуть вас зацікавити як через пряму, так і через партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Дані геолокації.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані, а також інформація про ваші купівельні тенденції, отримані від нашого ділових
                  партнерів, також використовується в маркетингових цілях, включаючи пропозицію вам і надання вам
                  продукти та послуги, які можуть вас зацікавити через пряму та партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Аудіо, електронна, візуальна, теплова, нюхова або подібна інформація.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
                <p>
                  Для відвідувачів ця інформація збирається за допомогою наших камер відеоспостереження, а для абонентів
                  – через аудіозаписи.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продукти та
                  послуги, включаючи запис дзвінків про продаж та інших дзвінків. Для відвідувачів візуальні дані можуть
                  оброблятися з метою забезпечення безпеки та підзвітності, а також для дотримання місцевих законів, у
                  тому числі ті, що стосуються здоров'я та безпеки.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>Професійна або пов'язана з роботою інформація.</p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами або записи, які ми маємо
                  про вас хід надання послуг або продуктів.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інцидентів безпеки, захисту від зловмисної, оманливої, шахрайської чи незаконної діяльності
                  та для управління відповідністю.
                </p>
                <p>
                  Крім того, ці дані також використовуються в маркетингових цілях, зокрема для пропозиції вам продуктів
                  і послуг, які можуть вас зацікавити як через пряму, так і через партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Висновки, зроблені з будь-якої з наведених вище категорій інформації для створення профілю споживача
                  відображення вподобань споживача, характеристик, психологічних тенденцій, схильностей, поведінка,
                  ставлення, інтелект, здібності та здібності.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
                <p>
                  Крім того, ці дані також використовуються в маркетингових цілях, зокрема для пропозиції вам продуктів
                  і послуг, які можуть вас зацікавити як через пряму, так і через партнерську рекламу.
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline;">
                <p>
                  Конфіденційна особиста інформація, у тому числі: будь-яка особиста інформація, яка розкриває особисту
                  інформацію номер соціального страхування або інший державний ідентифікаційний номер; вхід в обліковий
                  запис, геолокація; етнічне походження, членство в спілці; вміст пошти, електронної пошти чи текстових
                  повідомлень, окрім випадків, коли це стосується бізнесу одержувач повідомлення; і генетичні дані.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ця інформація збирається безпосередньо від вас та/або вашого роботодавця, наших ділових партнерів,
                  наших постачальників послуг, вашу взаємодію з нашими послугами та продуктами, записи, які ми маємо про
                  вас у під час надання послуг або продуктів або за допомогою файлів cookie та подібних технологій.
                </p>
              </td>
              <td class="vertical-align: baseline;">
                <p>
                  Ці дані обробляються у зв’язку з рядом наших операційних функцій, які ми надаємо вам продуктів і
                  послуг, у тому числі сприяння веденню бізнесу між сторонами в галузі та управління відносинами з
                  клієнтами.
                </p>
                <p>
                  Він також обробляється, щоб допомогти керувати та адмініструвати ваш обліковий запис, а також для
                  виявлення інциденти безпеки, захист від зловмисної, оманливої, шахрайської чи незаконної діяльності,
                  для управління відповідністю, аналіз даних і технологічний розвиток наших систем.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          CAI не продає вашу інформацію іншим особам, зокрема для цілей Каліфорнійського закону про конфіденційність
          споживачів 2018 р. ("CCPA") і Закон Каліфорнії про права на конфіденційність 2020 р. ("CPRA").
        </p>
      </div>
      <div id="third_party_services">
        <h2>Послуги третіх сторін &ndash;</h2>
        <p>
          Вам може бути надана можливість вибрати доступ і використання сторонніх служб або програм (&ldquo;Послуги
          третіх сторін&rdquo;) через деякі наші веб-сайти та/або продукти. Це Повідомлення про конфіденційність
          стосується лише збору, використання та розкриття інформації, зібраної CAI. Це не стосується до практики третіх
          сторін, якими ми не володіємо, не контролюємо, не використовуємо чи не керуємо, включаючи, але не обмежуючись
          до будь-яких сторонніх служб. Шляхом доступу або використання сторонніх служб або програм через наші веб-сайти
          та/або продукти, ви наказуєте нам розкрити вашу інформацію сторонній службі від вашого імені на вимогу цієї
          третьої сторони, і ви погоджуєтеся, що третя сторона використовує або розкриває ваші інформація
          регулюватиметься політикою конфіденційності третьої сторони та може відрізнятися від того, як ми її
          використовуємо і розкрити вашу інформацію.
        </p>
      </div>
      <div id="security">
        <h2>Безпека &ndash;</h2>
        <p>
          CAI використовує розумні та відповідні адміністративні, технічні та фізичні заходи безпеки з типами даних, які
          він обробляє для захисту персональних даних від втрати, неправильного використання та несанкціонованого
          доступу, розкриття, зміна та/або знищення. Незважаючи на те, що ми докладаємо всіх зусиль, щоб захистити вашу
          конфіденційність, жодних заходів безпеки немає є ефективними на 100%, і ми не можемо гарантувати безпеку
          інформації або конфіденційність ваших особистих даних спілкування завжди залишатиметься приватним.
          Несанкціоноване проникнення або використання, збій апаратного чи програмного забезпечення та інше фактори
          можуть поставити під загрозу безпеку інформації користувача.
        </p>
        <p>
          Безпека та захист вашої інформації також залежить від вас. Де ми дали вам (або де ви вибрані) облікові дані
          користувача (тобто ідентифікатор користувача та пароль) для доступу до певних частин наших послуг або
          програми, ви несете відповідальність за збереження конфіденційності цих облікових даних користувача. Просимо
          не ділитися ваші облікові дані з будь-ким. Ви несете повну відповідальність за захист облікових даних
          користувача та для будь-якого використання облікових даних вашого облікового запису, яке знаходиться під вашим
          контролем.
        </p>
      </div>
      <div id="retention_of_data">
        <h2>Зберігання інформації &ndash;</h2>
        <p>
          Як правило, ми зберігатимемо персональні дані стільки часу, скільки потрібно для досягнення цілей, викладених
          у цьому документі Повідомлення про конфіденційність. Це утримання, як правило, протягом усього періоду
          стосунків плюс короткий період часу викладено в нашому графіку зберігання даних. Однак ми можемо зберігати
          певну інформацію довше, якщо цього вимагає застосовне законодавство (наприклад, податкові, бухгалтерські чи
          інші юридичні вимоги) або для законних бізнес-цілей. Один раз нам більше не потрібно використовувати ваші
          персональні дані для виконання наших зобов’язань, ми видалимо їх із наших систем і записи та/або вжити
          заходів, щоб належним чином анонімізувати його, щоб вас більше не можна було ідентифікувати з нього. Кожен
          використання, викладене в цьому Повідомленні про конфіденційність, є законним інтересом CAI обробляти або
          використовувати персональні дані, зібрані або необхідні для виконання договору. Якщо ви не згодні з таким
          підходом, можете заперечувати проти обробки чи використання CAI ваших особистих даних, звернувшись до CAI
          письмово за допомогою методів перелічені нижче в розділі &ldquo;Зв’язатися з нами&rdquo; у нижній частині
          цього Повідомлення про конфіденційність.
        </p>
      </div>
      <div id="your_rights_and_choices">
        <h2>Ваші права та вибір </h2>
        <h3>Інформація про обліковий запис &amp; Запити:</h3>
        <p>
          Відповідно до чинного законодавства залежно від юрисдикції (наприклад, ЄЕЗ від 25 травня 2018 р. або штату
          Каліфорнія з 1 січня 2020 року), ви можете мати перелічені нижче права нижче щодо ваших персональних даних:
        </p>
        <p>
          1) право на доступ (це означає, що ви можете вимагати, щоб ми надали вам копію ваших персональних даних, які
          ми зібрали про вас, категорії джерел, з яких було зібрано інформацію, цілі збирання даних, категорії третіх
          сторін, яким ми надали дані, і категорії особисті дані, які були передані третім сторонам з комерційною
          метою);
        </p>
        <p>
          2) право на виправлення (це означає, що ви можете повідомити нас через розділ "Зв’язатися з нами" внизу цього
          Повідомлення про конфіденційність, щоб виправити будь-які помилки у ваших особистих даних або оновити ваші
          налаштування; ми також можемо не бути можемо задовольнити ваш запит, якщо ми вважаємо, що це порушить
          будь-який закон чи юридичну вимогу або призведе до інформація не відповідає дійсності; дані, що зберігаються
          виключно для цілей резервного копіювання даних, як правило, виключаються);
        </p>
        <p>
          3) право на передачу (це означає, що ви можете вимагати, щоб ми надали копію ваших персональних даних третім
          особам вечірка на ваш вибір);
        </p>
        <p>
          4) право на обмеження (якщо наша обробка ваших персональних даних необхідна для наших законних інтересів,
          залежно від застосовного закону про захист даних/конфіденційності, який застосовується, ви можете заперечити
          проти цього обробка в будь-який час, з урахуванням деяких обмежень і винятків; ви також можете обмежити певні
          види обробки ваших персональних даних за певних обставин; зокрема, ви можете запросити нас обмежити наше
          використання ваших персональних даних, якщо ви оскаржуєте їх точність, якщо обробка ваших персональних даних є
          визнано незаконним, або якщо нам більше не потрібні ваші персональні дані для обробки, але ми їх зберегли як
          це дозволено законом);
        </p>
        <p>
          5) право відкликати згоду (це означає, що в тій мірі, якою є наша обробка ваших персональних даних на основі
          вашої згоди ви можете відкликати свою згоду в будь-який час; однак відкликання вашої згоди не вплине на
          законність обробки на основі вашої згоди до її відкликання та не вплине на законність нашої подальшої обробки,
          яка базується на будь-якій іншій законній підставі для обробки вашої особистої інформації дані);
        </p>
        <p>
          6) право на видалення (це означає, що ви можете попросити нас видалити ваші особисті дані з наших систем, і ми
          це зробимо отже, якщо ми не зобов’язані зберігати таку інформацію, щоб надавати вам послуги, або нам це
          потрібно особисті дані для дотримання наших юридичних або ділових зобов’язань відповідно до чинного
          законодавства); або
        </p>
        <p>7) право сказати "ні" продажу ваших персональних даних (Примітка: CAI не продає персональні дані).</p>
        <p>
          Ви можете скористатися деякими або всіма цими правами, увійшовши у свій обліковий запис у продуктах, які ви
          використовуєте. В іншому випадку, щоб запросити таку інформацію безпосередньо, надішліть письмовий запит,
          вказавши реквізити надані в &#8220;Зв’язатися з нами&#8221; у нижній частині цього Повідомлення про
          конфіденційність.
        </p>
        <p>
          Зверніть увагу, що згідно із законодавством Каліфорнії ми зобов’язані реагувати лише на доступ до особистої
          інформації запити від одного споживача до двох разів протягом 12 місяців. Відповідно до законодавства ЄС і
          Каліфорнії, якщо ан особа звертається з необґрунтованими, повторюваними або надмірними запитами (на наш
          розсуд) отримати доступ до Персональних даних, ми можемо стягувати плату відповідно до максимальної суми,
          встановленої законом.
        </p>
        <p>
          Пам’ятайте, що в деяких регіонах ви також маєте право поскаржитися в орган із захисту даних ("DPA"). про наш
          збір і використання ваших персональних даних. Для отримання додаткової інформації зверніться до місцевого DPA.
        </p>
        <p>
          Якщо CAI обробляє ваші персональні дані від імені клієнта, зверніть увагу на свою конфіденційність запити та
          запити щодо доступу, виправлення або видалення персональних даних такому клієнту.
        </p>
        <p>
          Перш ніж надати інформацію, яку ви запитуєте відповідно до певних із цих прав, ми повинні мати можливість її
          перевірити ваша особистість. Щоб підтвердити свою особу, вам може знадобитися надати інформацію про себе,
          зокрема, у відповідній мірі, надаючи відповіді на таємні запитання, ваше ім’я, державну ідентифікацію номер,
          дата народження, контактна інформація чи інша особиста інформація. Ми зіставимо це інформацію проти
          інформації, яку ми раніше зібрали про вас, щоб підтвердити вашу особу та вашу запит. Якщо ми не зможемо
          підтвердити вашу особу в рамках вашого запиту, ми не зможемо задовольнити вашу запит. Ми не зобов’язані
          збирати додаткову інформацію, щоб ви могли перевірити ваші ідентичність. Для запитів на видалення вам потрібно
          буде надіслати перевірений запит на видалення.
        </p>
        <p>
          Якщо ви хочете призначити уповноваженого агента для надсилання запиту від вашого імені, ви повинні надати
          агента з письмовим підписаним дозволом подавати запити щодо прав конфіденційності від вашого імені або надати
          лист від вашого адвокат. Агент або повірений повинен надати цей дозвіл під час запиту. Ми можемо вимагати від
          вас підтвердити свою особу безпосередньо в нас, перш ніж ми надамо будь-яку запитувану інформацію вашому
          затвердженому агенту.
        </p>
        <p>Інформація, зібрана з метою перевірки вашого запиту, використовуватиметься лише для перевірки.</p>
        <p>
          Якщо ви вирішите скористатися будь-яким із цих прав, у тій мірі, в якій вони застосовуються, закон штату США
          забороняє нам дискримінація щодо вас на підставі вибору використання ваших прав на конфіденційність. Однак ми
          можемо стягувати плату за a іншу ставку або надавати інший рівень послуг у межах, дозволених законом.
        </p>
        <h3>Не відстежувати сигнали:</h3>
        <p>
          Деякі веб-браузери дозволяють транслювати сигнал веб-сайтам і онлайн-сервісам із зазначенням уподобань що вони
          "не відстежують" вашу онлайн-діяльність. Наразі ми не вшановуємо такі сигнали та не враховуємо змінювати
          інформацію, яку ми збираємо або як ми використовуємо цю інформацію, залежно від наявності такого сигналу
          транслювати або отримати нами.
        </p>
        <h3>Рекламні повідомлення:</h3>
        <p>
          Час від часу ми можемо надсилати вам маркетингові повідомлення про наші продукти відповідно до ваших
          уподобання. Ви можете будь-коли відмовитися від отримання рекламних повідомлень від нас, дотримуючись
          інструкцій інструкції в цих повідомленнях (часто це буде сповіщення або посилання внизу повідомлення). Якщо ви
          виберете ми все ще можемо надсилати вам нерекламні повідомлення, як-от повідомлення про ваш обліковий запис
          або наші поточні ділові відносини. Запити на відмову від рекламних повідомлень також можна надіслати CAI у
          письмовій формі через способи, перелічені нижче в розділі "Зв’язатися з нами" внизу цього Повідомлення про
          конфіденційність.
        </p>
        <h3>Файли cookie:</h3>
        <p>
          Ви можете керувати нашим використанням файлів cookie за допомогою банера файлів cookie, який з’являється під
          час доступу до наших сайтів. Якщо ви Зазвичай ви можете налаштувати свій веб-переглядач на видалення чи
          відхилення файлів cookie браузера або очищення локальних файлів зберігання. Ви також можете надсилати будь-які
          запити на керування файлами cookie, звернувшись до нас у письмовій формі за допомогою перелічених методів
          нижче в розділі "Зв’язатися з нами" внизу цього Повідомлення про конфіденційність. Зверніть увагу, що якщо ви
          вирішите видалити або відхилити деякі файли cookie, це може вплинути на функціональність нашого веб-сайту та
          послуг. У нас є незалежну Політику щодо файлів cookie, доступну на нашому веб-сайті (
          <b>www.commandalkon.com</b>) на вкладці "Юридична інформація", яка надає більше деталей.
        </p>
        <h3>Інформація про місцезнаходження:</h3>
        <p>
          Ви можете вмикати та вимикати служби визначення місцезнаходження, змінюючи налаштування свого мобільного
          пристрою. Зверніть увагу що якщо ви вирішите вимкнути служби на основі визначення місцезнаходження, це може
          вплинути на повну функціональність наших продукти та послуги.
        </p>
        <h3>Права щодо конфіденційності штату Каліфорнія:</h3>
        <p>
          Закон Каліфорнії дозволяє користувачам, які є жителями Каліфорнії, робити запити та отримувати від нас раз на
          рік безкоштовно звинувачення, список третіх сторін, яким ми розкрили їхні персональні дані (якщо такі є) для
          їх безпосереднього використання маркетингові цілі в попередньому календарному році, а також тип персональних
          даних, які їм розкриваються партії. За винятком випадків, передбачених цим Повідомленням про конфіденційність
          або угодою із суб’єктом даних, CAI робить це не продавати особисті дані третім особам для власних
          маркетингових цілей.
        </p>
      </div>
      <div id="international_data_transfers_and_the_data_privacy_framework">
        <h2>Міжнародна передача даних і система конфіденційності даних </h2>
        <p>
          CAI базується в Сполучених Штатах і має офіси по всьому світу. Щоб полегшити наші операції та допомогти в
          доставці наші продукти та послуги, ми можемо передавати особисті дані до США та будь-якої іншої країни, де ми
          діють філії, постачальники, консультанти та постачальники послуг. Такі країни можуть мати такі закони
          відрізняються і, можливо, не настільки захищають, як закони вашої країни проживання. Якщо ви Data Контролер,
          який використовує CAI як процесор даних, і якщо вміст або дані, які ви зберігаєте в програмному забезпеченні
          CAI або через нього, програми або веб-сайти містять особисті дані осіб з ЄЕЗ, ви погоджуєтеся, що у вас є
          юридичні повноваження передавати персональні дані до CAI, включаючи передачу до таких країн, як США, де захист
          конфіденційності та права органів влади на доступ до особистих даних можуть бути нееквівалентними тим, що в
          ЄЕЗ.
        </p>
        <p>
          Коли ми передаємо особисті дані за кордон, ми вживатимемо відповідних заходів для захисту інформації, що
          знаходиться в ній відповідно до цього Повідомлення про конфіденційність і прагнемо гарантувати, що ми разом із
          будь-якими закордонними одержувачами дотримуємося із відповідними законами про конфіденційність. CAI
          покладається на стандартні договірні положення ЄС ("SCC") для законної передачі персональні дані між своїми
          організаціями з юрисдикцій, на які поширюється Загальний регламент ЄС щодо захисту даних ("GDPR") для законної
          передачі персональних даних CAI покладається на Угоду про передачу даних Великобританії. між своїми
          організаціями з юрисдикцій, на які поширюється Загальний регламент захисту даних Великобританії ("GDPR
          Великобританії") до США
        </p>
        <p>
          На додаток до SCC, якщо CAI стане відомо, що будь-який державний орган (включно з правоохоронними) бажає
          отримати доступ або копію деяких чи всіх персональних даних, які обробляє CAI, будь то на a на добровільній
          або обов’язковій основі для цілей, пов’язаних із розвідкою національної безпеки, якщо це не передбачено
          законом заборонено або згідно з обов’язковим юридичним примусом, який вимагає іншого, CAI: 1) негайно
          повідомить сторона, до якої стосуються персональні дані (тобто клієнт або постачальник); 2) повідомити
          відповідний державний орган що він не має права розкривати особисті дані та, якщо це не заборонено законом,
          повинен буде негайно повідомити сторону, якої стосуються персональні дані; 3) повідомити державний орган про
          це має направляти всі запити чи вимоги безпосередньо стороні, до якої стосуються персональні дані; і 4) ні
          надавати доступ до персональних даних до отримання письмового дозволу від сторони, якої стосуються персональні
          дані або до тих пір, поки ви не будете змушені це зробити за законом. У разі законного зобов’язання CAI
          вживатиме розумних і законних заходів оскаржити таку заборону чи примус. Якщо CAI буде змушений надати
          персональні дані, CAI це зробить лише розкривати персональні дані в обсязі, передбаченому законодавством
          відповідно до чинного законного процесу.
        </p>
        <p>
          CAI використовує Доповнення про захист даних ("DPA") із суб’єктами обробки, які обробляють персональні дані
          від імені CAI якщо цього вимагає чинне законодавство про конфіденційність. Коли ми передаємо особисті дані з
          Європейської економічної зони ("ЄЕЗ"), Сполученого Королівства або Швейцарії до Сполучених Штатів, CAI
          відповідає ЄС-США. Конфіденційність даних Рамкова програма (ЄС-США DPF), розширення Великобританії до ЄС-США.
          DPF, а швейцарський &#8211; Конфіденційність даних США Framework (Швейцарсько-США DPF), як визначено
          Міністерством торгівлі США. CAI має сертифікат США Міністерство торгівлі, що воно дотримується ЄС-США.
          Принципи конфіденційності даних (ЄС-США DPF Принципи) щодо обробки персональних даних, отриманих від
          Європейського Союзу, покладаючись на ЄС-США DPF та зі Сполученого Королівства (і Гібралтару) в залежності від
          розширення Великобританії до ЄС-США. DPF. CAI сертифікував Міністерству торгівлі США, що він дотримується
          Swiss-U.S. Конфіденційність даних Принципи (Швейцарсько-американські принципи DPF) щодо обробки персональних
          даних, отриманих від Швейцарія, спираючись на Швейцарсько-США. DPF. Якщо існує будь-який конфлікт між умовами
          цієї Конфіденційності Зауважте, що ЄС-США Принципи DPF та/або Швейцарсько-США. Принципи DPF, принципи мають
          керуватись. до дізнатися більше про програму Data Privacy Framework (DPF) і переглянути нашу сертифікацію,
          будь ласка, відвідайте
          <b>https://www.dataprivacyframework.gov/</b>.
        </p>
        <p>
          Відповідно до ЄС-США. DPF, розширення Великобританії до ЄС-США. DPF і швейцарсько-американський. DPF, CAI
          фіксує для вирішення скарг, пов’язаних із принципами DPF, щодо збору та використання вашої особистої
          інформації. ЄС, Особи з Великобританії та Швейцарії, які мають запити або скарги щодо нашої обробки
          персональних даних, отриманих у опора на ЄС-США. DPF, розширення Великобританії до ЄС-США. DPF та/або
          Швейцарсько-США. DPF слід спочатку зв’язатися з CAI, як зазначено в розділі "Зв’язатися з нами" внизу цього
          Повідомлення про конфіденційність.
        </p>
        <p>
          Особа має можливість, за певних умов, звернутися до обов’язкового арбітражу для скарг щодо відповідності DPF,
          не вирішеної жодним із інших механізмів DPF. Додаткову інформацію див
          <b>https://www.dataprivacyframework.gov/s/article/ANNEX-I-introduction-dpf?tabset-35584=2</b>. У відповідності
          з ЄС-США. DPF, розширення Великобританії до ЄС-США. DPF і швейцарсько-американський. DPF, CAI зобов'язується
          передати нерозглянуті скарги щодо нашої обробки персональних даних, отриманих у зв’язку з ЄС-США. DPF,
          Великобританія Розширення до ЄС-США. DPF та/або Швейцарсько-США. DPF до JAMS, альтернативного постачальника
          послуг вирішення суперечок базується в Сполучених Штатах. Якщо ви не отримаєте своєчасне підтвердження своїх
          принципів DPF скаргу від нас, або якщо ми не розглянули вашу скаргу, пов’язану з Принципами DPF, на ваше
          задоволення, відвідайте <b>https://www.jamsadr.com/file-a-dpf-claim</b>
          щоб отримати додаткову інформацію або подати скаргу. Послуги JAMS надаються вам безкоштовно.
        </p>
        <p>
          Федеральна торгова комісія Сполучених Штатів має юрисдикцію щодо відповідності CAI вимогам ЄС-США. DPF,
          Розширення Великобританії до ЄС-США. DPF і швейцарсько-американський. DPF. Якщо CAI надає особисті дані,
          зібрані в ЄЕЗ, Сполучене Королівство або Швейцарія зі стороннім постачальником послуг, який обробляє дані
          виключно на CAI від імені, тоді CAI нестиме відповідальність за обробку таких даних третьою стороною з
          порушенням Принципи, якщо тільки CAI не зможе довести, що не несе відповідальності за подію, що спричинила
          збиток.
        </p>
      </div>
      <div id="lawful_basis_for_processing">
        <h2>Правова основа для обробки персональних даних фізичних осіб (ЄЕЗ/Великобританія) &ndash;</h2>
        <p>
          Якщо ви фізична особа з ЄЕЗ або Великобританії, наші законні підстави для збору та використання персональних
          даних будуть залежать від відповідних персональних даних і конкретного контексту, в якому ми їх збираємо. Ми
          будемо використовувати тільки ваші Персональні дані для цілей, для яких ми збираємо такі Персональні дані, як
          зазначено нижче, якщо нам це не потрібно використовувати його пізніше для іншої мети, яка сумісна з початковою
          метою. Якщо нам потрібно далі обробляти ваші Персональні дані з метою, яка не сумісна з початковою метою
          збору, ми повідомить вас і надасть пояснення правової основи, яка дозволяє нам це зробити. Проте ми загалом
          покладатися на наступні законні обґрунтування нашої діяльності з обробки: 1) виконання угоди з вами та/або
          вашим роботодавцем; 2) переслідування наших законних інтересів, якщо вони не переважають ваші інтереси, права
          і свободи; 3) ваша згода; 4) дотримання правового зобов'язання. У деяких випадках ми може також покладатися на
          відступи від статті 49 GDPR, мати юридичне зобов’язання збирати персональні дані або може інакше потрібні
          особисті дані для захисту ваших життєво важливих інтересів або інтересів іншої особи. Детальніше щодо законної
          основи наведено нижче.
        </p>
        <table class="margin-top: 30px;" border="1">
          <tbody>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Мета(и) обробки </strong>
                </p>
              </td>
              <td class="vertical-align: baseline">
                <p>
                  <strong>Правова основа для обробки</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Для обробки заявок та інших угод щодо наших продуктів і послуг.</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка ваших персональних даних необхідна для виконання договору або укладення договору з вами
                      та/або вашим роботодавцем
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Для керування та адміністрування контрактів, включаючи угоди про надання послуг, з вами та вашим
                  роботодавцем.
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка ваших особистих даних необхідна нам для дотримання правових та нормативних вимог
                      зобов'язання
                    </p>
                  </li>
                  <li>
                    <p>
                      Обробка необхідна для підтримки наших законних інтересів щодо управління нашим бізнесом (або тими
                      третьої сторони) за умови, що такі інтереси не переважають вашими інтересами, правами та свобод
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>
                  Щоб покращити наші продукти та послуги, провести дослідження ринку, виконати аналітику даних, для для
                  моделювання загального ризику та для статистичного аналізу
                </p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка необхідна для підтримки наших законних інтересів щодо управління нашим бізнесом (або тими
                      третьої сторони) за умови, що такі інтереси не переважають вашими інтересами, правами та свобод
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Маркетинг</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Ми просимо вашої згоди на обробку ваших персональних даних для маркетингу &ndash; який ти може
                      відкликати в будь-який час
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Для запобігання та виявлення шахрайства, відмивання грошей чи інших злочинів</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка ваших особистих даних необхідна нам для дотримання правових та нормативних вимог
                      зобов’язань або відповідно до чинного законодавства
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Для управління нашими відносинами з вами та між вами та вашим роботодавцем</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка ваших персональних даних необхідна для виконання договору або укладення договору з вами
                      та/або вашим роботодавцем
                    </p>
                  </li>
                  <li>
                    <p>
                      Обробка необхідна для підтримки наших законних інтересів щодо управління нашим бізнесом (або тими
                      третьої сторони) за умови, що такі інтереси не переважають вашими інтересами, правами та свобод
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="vertical-align: baseline">
                <p>Щоб забезпечити переваги наших продуктів і послуг</p>
              </td>
              <td class="vertical-align: baseline">
                <ul>
                  <li>
                    <p>
                      Обробка ваших персональних даних необхідна для виконання договору або укладення договору з вами
                      та/або вашим роботодавцем
                    </p>
                  </li>
                  <li>
                    <p>
                      Обробка необхідна для підтримки наших законних інтересів щодо управління нашим бізнесом (або тими
                      третьої сторони) за умови, що такі інтереси не переважають вашими інтересами, правами та свобод
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="children_policy">
        <h2>Конфіденційність дітей &ndash;</h2>
        <p>
          Діяльність CAI не спрямована на дітей, і CAI свідомо не збирає та не вимагає інформація від дітей або осіб
          віком до 13 років. CAI свідомо не дозволяє таким особам використовувати наших продуктів або послуг. У випадку,
          якщо ми дізнаємося, що ми випадково зібрали персональні дані від a дитини чи особи віком до 13 років, ми
          видалимо цю інформацію якомога швидше. Якщо ви вірите якщо ми можемо мати будь-яку інформацію від або про
          дитину, будь ласка, зв’яжіться з нами за допомогою методів, наведених нижче в &#8220;Зв'язатися з нами&#8221;
          у нижній частині цього Повідомлення про конфіденційність.
        </p>
      </div>
      <div id="changes_to_the_privacy_notice">
        <h2>Зміни до Повідомлення про конфіденційність &ndash;</h2>
        <p>
          Ми можемо змінити це Повідомлення про конфіденційність у будь-який час, опублікувавши виправлене Повідомлення
          про конфіденційність на наших веб-сайтах. Дата Повідомлення про конфіденційність було останнє переглянуте
          вказано у верхній частині цього Повідомлення про конфіденційність. Усі змінені умови автоматично набувають
          чинності після їх початкової публікації на нашому веб-сайті, якщо не внесено жодних змін Закон потребує
          негайного внесення змін. Ви несете відповідальність за періодичну перевірку цього Повідомлення про
          конфіденційність зміни.
        </p>
      </div>
      <div>
        <h2>Скарги &ndash;</h2>
        <p>
          Без шкоди для будь-яких інших адміністративних або судових засобів правового захисту, які у вас можуть бути,
          ви можете мати на це право подати скаргу до місцевих державних регуляторів/органів захисту даних, якщо ви
          вважаєте, що ми це зробили порушили чинні вимоги конфіденційності або захисту даних під час обробки
          персональних даних про вас.
        </p>
        <h3>У Великобританії ви можете звернутися до:</h3>
        <p>Офіс уповноваженого з питань інформації</p>
        <p>
          Водний провулок, будинок Вікліфа
          <br />
          Вілмслоу &#8211; Чешир SK9 5AF
        </p>
        <p>Тел. +44 1625 545 700</p>
        <p>
          Електронна адреса: <b>casework@ico.org.uk</b>
        </p>
        <p>
          Веб-сайт: <b>https://ico.org.uk</b>
        </p>
        <h3>Про ЄС див.:</h3>
        <p>
          <b>https://edpb.europa.eu/about-edpb/about-edpb/members_en</b>
        </p>
        <h2>Зміни до Повідомлення про конфіденційність &ndash;</h2>
        <p>
          Ми можемо змінити це Повідомлення про конфіденційність у будь-який час, опублікувавши виправлене Повідомлення
          про конфіденційність на наших веб-сайтах. Дата Повідомлення про конфіденційність було останнє переглянуте
          вказано у верхній частині цього Повідомлення про конфіденційність. Усі змінені умови автоматично набувають
          чинності після їх початкової публікації на нашому веб-сайті, якщо не внесено жодних змін Закон потребує
          негайного внесення змін. Ви несете відповідальність за періодичну перевірку цього Повідомлення про
          конфіденційність зміни.
        </p>
      </div>
      <div id="contact_us">
        <h2>Зв'яжіться з нами </h2>
        <p>
          Щоб зв’язатися з CAI із запитаннями чи сумнівами щодо цього Повідомлення про конфіденційність або практики CAI
          щодо особистого даних, будь ласка, використовуйте контактну інформацію нижче. Якщо ви є резидентом ЄЕЗ,
          зверніть увагу, що де CAI виступає в якості контролера даних, відповідального за ваші персональні дані,
          юридичною особою є Command Alkon Incorporated в США. CAI розглядатиме всі запитання та скарги, пов’язані з цим
          Повідомленням про конфіденційність, протягом a розумний строк.
        </p>
        <p>
          <strong>Електронна пошта:</strong>
        </p>
        <p>
          <b>privacy@commandalkon.com</b>
        </p>
        <p>
          <strong>Або напишіть на адресу:</strong>
        </p>
        <p>Command Alkon Incorporated</p>
        <p>Головний спеціаліст з питань конфіденційності в юридичному відділі</p>
        <p>
          6750 Crosby Court
          <br />
          Дублін, Огайо 43016
        </p>
        <p>
          <strong>Або телефонуйте:</strong>
        </p>
        <p>1-800-624-1872 (безкоштовно в США)</p>
        <p>0-800-022-9682 (безкоштовний міжнародний номер)</p>
        <p>
          <strong>Веб-сайт:</strong>
        </p>
        <p>
          <b>www.commandalkon.com</b>
        </p>
      </div>
    </Styled>
  );
};
