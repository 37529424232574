import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { find } from 'lodash';
import { useTicketContext } from '../../../TicketContext';
import style from '../style';
import { Localization } from 'connex-cds';

const { Uom, Translate } = Localization;

const Styled = styled.div`
  ${style}
`;

const { useTranslateMessage } = Localization;

const Material = () => {
  const { ticket } = useTicketContext();
  const translateMessage = useTranslateMessage();

  const mixInfo = React.useMemo(() => {
    const primaryLineItem = find(ticket?.lineItems, { isPrimary: true });
    const splitLoad = ticket?.lineItems.some(lineItem => lineItem.isSplitLoad === true);

    return {
      splitLoad,
      mix: splitLoad ? translateMessage('splitLoad') : primaryLineItem?.item?.id || primaryLineItem?.item?.description,
      loadedQty: <Uom uom={primaryLineItem?.quantity} />,
    };
  }, [ticket?.lineItems, translateMessage]);

  return (
    <Styled className={cn('material-info')}>
      <div className="table-section">
        <table>
          <thead>
            <tr>
              <th>
                <Translate stringId="productId" data-testid="mixIdDescriptionLabel" />
              </th>
              <th>
                <Translate stringId="loaded" data-testid="loadedQtyLabel" />
              </th>
              {ticket?.dispatchOrder?.totalTicketed?.value >= 0 && (
                <th>
                  <Translate stringId="loadedOrder" data-testid="loadedOrderLabel" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-testid="mixDescriptionValue"> {mixInfo.mix}</td>
              {!mixInfo.splitLoad && <td data-testid="loadedQtyValue">{mixInfo.loadedQty}</td>}
              {!mixInfo.splitLoad && (
                <td data-testid="loadedOrderQtyValue">
                  <Uom uom={ticket?.dispatchOrder?.totalTicketed} />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </Styled>
  );
};

export default Material;
